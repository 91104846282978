import React from "react"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities/dist" 
import Cabinet from "./cabinet" 
import "../assets/css/style.css" 
import _fetch from "../api"
import { AppToaster, Loading } from "react-pe-useful/dist" 
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import { Intent } from "@blueprintjs/core"

class CabinetView extends BasicState
{ 
    stateDidMount()
    {
        //if(document.getElementById("wpfest-admin-styles")) return
        const style = ` 
            .layout {
                background-color: #293742; 
            };
        `
        const css = document.createElement('style')
        document.getElementsByTagName("head")[0].appendChild(css);
        css.innerHTML = style 
        css.setAttribute("id", "wpfest-admin-styles");
        css.setAttribute("rel", "stylesheet");
        css.setAttribute("type", "text/css");
        this.get_basic_data()
    }
    componentWillUnmount()
    { 
        const div = document.getElementById("wpfest-admin-styles");
        if( div ) 
        {
           div.remove()
        }
    }
    get_basic_data()
    {
        CabinetView.serverURL = this.props.uri ? this.props.uri : DEFAULT_WPFEST_ADMIN_URI 
        CabinetView.WPFESTAdminRoute = this.props.route
        _fetch( "get_global_options" )
            .then(data => 
                {                    
                    if(data.isError)
                    { 
                        IndexDB.find("peFestAdmin_options", "global_options", "global_options", "global_options")
                            .then(res =>
                            { 
                                CabinetView.global_options = res.global_options 
                                this.setState({loading:false})
                            })
                        this.setState({loading:false})
                        return
                    }
                    console.log( data )
                    CabinetView.global_options = data.global_options 
                    IndexDB.save( 
                        data.global_options, 
                        "global_options", 
                        "global_options", 
                        "global_options",
                        "peFestAdmin_options",  
                        1 
                    )
                    this.setState({loading:false})
                })	
    }
    basic_state_data() 
    { 
        
        return { loading:true }
    } 
    alternateRender()
    {  
        if(this.state.loading)  return <Loading />
        return  <Cabinet 
            {...this.props} 
            {...this.state}
            route={this.props.route}
        /> 
        
    }
}
export default CabinetView 

export const DEFAULT_WPFEST_ADMIN_URI = "https://wpfest.ru"
export const TOKEN_COOKIE_NAME = "wp-fest-admin-coockie"
export const TOKEN_RAFRESH_COOKIE_NAME = "wp-fest-admin-refresh-coockie"
export const WPFEST_ADMIN_ROLE_ADMINISTRATOR = "administrator"
export const WPFEST_ADMIN_ROLE_EXPERT = "expert"
export const WPFEST_ADMIN_MY_ROUTE = "my"
export const WPFEST_ADMIN_STATUSES = ["Before starting Festival", "Project preparation phase", "Project submission phase", "Festival was closed"]


import React from "react" 
import _fetch from "."
import { __ } from "react-pe-utilities" 
import FestMemberSetting from "./FestMemberSetting" 
import actions, { UPDATE_MEMBER_ACTION } from "modules/pe-fest-module/data/actions"
import { isStatusFinished } from "./utilities/isStatusOpened"
import WPFestSettings from "./utilities/WPFestSettings"
import PhaseLabel from "./PhaseLabel"

const MemberAdmin = props =>
{ 
    const { is_owner, is_member } = props
    const on = (data) =>
    {
      let member = {}
      member[data.field] = data.value
      console.log( member )
      actions(UPDATE_MEMBER_ACTION, {id: data.id, member})
    } 
    return is_member
      ?
      isStatusFinished()
        ?
        <FestMemberSetting 
          {...props}
          user={props.user}
          is_owner={is_owner}
          is_member={is_member} 
          on={on}
        />
        :
        <div className="alert alert-secondary p-4 lead flex-centered">
          { __(PhaseLabel.labels[WPFestSettings.status || 0]) }
        </div> 
      : 
      <div className="alert alert-danger p-4 lead flex-centered">
        {__("You are not member project")}
      </div> 
}
export default MemberAdmin

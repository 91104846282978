import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import { compose } from "recompose"
import { withRouter, } from "react-router"
import _fetch from "."
import FestMemberCard from "./core/cards/FestMemberCard" 
import PhaseLabel from "./PhaseLabel" 
import chroma from 'chroma-js'

import { initArea, __ } from "react-pe-utilities"
import {Pagi} from "react-pe-useful"
import {Loading} from 'react-pe-useful' 
import WPFestSettings from "./utilities/WPFestSettings" 
import {  Callout, Icon } from "@blueprintjs/core" 
import FestComponent from "./FestComponent"
import AddProjectCard from "./core/AddProjectCard" 

import icon1 from "../../assets/img/test.svg"
import Moment from "react-moment"
import LoginForm from "./core/login/LoginForm"
import FestTrackCard from "./core/cards/FestTrackCard"
import { withFestStore } from "modules/pe-fest-module/data/store"
import HelpForm from "modules/pe-fest-module/widgets/header/HelpForm"
import User from "./utilities/User"
import TrackLabel from "../track/TrackLabel"

class FestAbout extends FestComponent {
  fetchCommand  = "about"
  header_type   = "about"
  state = {
    fest_members: [],
    mtype: "box",
    ganres: [],
    ganreFilterIDs: [],
    loading: true,
  }
  trimMonth = (date) =>
  {
    const d = date.split(" ")
    return d[1]
  }
  setFirstUpdate = () =>
  {    
    _fetch(
      "get_fest_command",
      { command: "getHonnorHall", festId: WPFestSettings.festId  },
      WPFestSettings.serviceURL,
      WPFestSettings.getToken( WPFestSettings.url ),
      "wpfa",
  )
      .then((fetchData) => {
          const data = fetchData.data  
          if(data)
          {
            //console.log(data.laureates)
            this.setState({laureates: data.laureates})
          } 
          else
          {
            console.error(fetchData.error)
          }
      })
  }   
  getContactsFrm = () =>
  {
    const {options} = this.props.store 
    let contacts, vk, email, phone, telegramm, links
    
    if( options.link1 || options.link2 )
    {
      links = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-link fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          options.link1
          ?
          <a href={ options.link1} target="_blank"  className="text-light mt-2 mx-2 hover">{options.link1}</a>
          :
          null
        }
        {
          options.link2
          ?
          <a href={ options.link2} target="_blank"  className="text-light mt-2 mx-2 hover">{options.link2}</a>
          :
          null
        }
      </div>
    }
    if( options.vk || options.vk2 )
    {
      vk = <div className="d-flex px-2">
        <Icon icon={<i className="fab fa-vk fa-2x mt-1 mx-2 "/>} color="#FFF"/>
        {
          options.vk
          ?
          <a href={options.vk} target="_blank" className="text-light mt-2 mx-2 hover">{options.vk}</a>
          :
          null
        }
        {
          options.vk2
          ?
          <a href={options.vk2} target="_blank" className="text-light mt-2 mx-2 hover">{options.vk2}</a>
          :
          null
        }
      </div>
    }
    if( options.telegramm || options.telegramm2 )
    {
      telegramm = <div className="d-flex px-2">
        <Icon icon={<i className="fab fa-telegram-plane fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          options.telegramm
          ?
          <a href={ options.telegramm} target="_blank"  className="text-light mt-2 mx-2 hover">{options.telegramm}</a>
          :
          null
        }
        {
          options.telegramm2
          ?
          <a href={ options.telegramm2} target="_blank"  className="text-light mt-2 mx-2 hover">{options.telegramm2}</a>
          :
          null
        }
      </div>
    }
    if( options.email || options.email2 )
    {
      email = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-envelope fa-2x mt-1 mx-2"/>} color="#FFF"/>
        {
          options.email
          ?
          <span className="text-light mt-2 mx-2 hover">{options.email}</span>
          :
          null
        }
        {
          options.email2
          ?
          <span className="text-light mt-2 mx-2 hover">{options.email2}</span>
          :
          null
        }
      </div>
    }
    if( options.phone || options.phone2 )
    {
      phone = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-phone fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          options.phone
          ?
          <span className="text-light mt-2 mx-2 hover">{options.phone}</span>
          :
          null
        }
        {
          options.phone2
          ?
          <span className="text-light mt-2 mx-2 hover">{options.phone2}</span>
          :
          null
        }
      </div>
    }
    return <> {links} {vk} {telegramm} {email} {phone} </>
  }
  byEnabled = () =>
  {
    if (this.state.loading) 
    {
			return <div className="layout-state p-0 m-0">
					<Loading />
				</div> 
		}
    if (this.state.error) 
    {
      return <div className="layout-state flex-centered p-0 m-0">
          <Callout className="short-container p-5 text-center">
            {__(this.state.error)}
          </Callout>
      </div> 
    } 
    // console.log( this.props.store )
    const { options, tracks, myMembers, ganres } = this.props.store 
    const { mtype, fest_members } = this.state 
    const __ganres = Array.isArray(ganres) 
      ? 
      ganres.map((e, i) => (
        <span className="role_descr m-1" style={{ }} key={i}>
          {e.name}
        </span>
      ))
      :
      []

    const statistics = (
      <div className="row justify-content-center ">
        <div className="col-md-12 d-flex justify-content-center pt-4 colored">
          <div className="role_descr">
            { __( "Statistics" ) }
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center py-4 colored">
          <div className="row w-100">

            <div className="test-descr-title col-md-6">
              { __( "Count of projects" ) }
            </div>
            <div className="test-descr-cont title col-md-6">
              { options.memberCount }
            </div>
            <div className="test-descr-title col-md-6">
              { __( "Count of assessments" ) }
            </div>
            <div className="test-descr-cont title col-md-6">
              { options.countOfResults }
            </div>
            {
              options.is_ganres
                ?
                <>
                  <div className="test-descr-title col-md-6 my-auto">
                    { __( "Ganres in Festival" ) }
                  </div>
                  <div className="test-descr-cont col-md-6 d-flex flex-wrap">
                    { __ganres }
                  </div>
                </>
                :
                null
            }            
            <div className="test-descr-title col-md-6 my-auto">
              { __( "Current status" ) }
            </div>
            <div className="test-descr-cont title col-md-6">
              <PhaseLabel phase={options.status} />
            </div>
            {
              initArea("pe-fest-statistic", { ...this.props })
            }
            {
              User.isRole(['administrator', "Track_moderator"])
                ?
                <div className="flex-centered col-12 mt-3">
                  <NavLink 
                    className=" btn btn-outline-warning btn-sm text-center px-4" 
                    to={`${this.props.route}/statistic`}
                  >
                    { __( "Details" ) }
                  </NavLink>  
                </div> 
                :
                null
            }
            
          </div>
        </div> 
      </div>
    )
    const trackCards = Array.isArray( tracks)
      ?
      <div className="container">
        <div className="row justify-content-center py-4 px-md-5 px-2">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="role_descr">
              {__("Tracks on Festival")}
            </div>
          </div>
        </div>
        <div className="row justify-content-center px-2">
        {
          tracks.map(trackData =>
            { 
              return <FestTrackCard 
                data={trackData} 
                key={trackData.ID} 
                route={this.props.route}
                festid={this.props.match.params.festid}                
                festData={ { ...this.state } }
              />
            })
          }
        </div>
      </div>
      :
      null
    const my_projects = Array.isArray(myMembers) 
      ?
      <div className=" light-colored ">
        <div className="container ">
          <div className="row justify-content-center mt-0 py-4 px-md-5 px-2">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="role_descr">
                {__("My projects")}
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-0 pb-3  px-2">
            {
              this.state.my_members.map((e, i) => (
                <FestMemberCard
                  key={e.id}
                  route={this.props.route}
                  festid={this.props.match.params.festid}                
                  festData={ { ...this.state } }
                  isMine
                  {...e}
                />
              ))
            }
            <AddProjectCard 
              route={`${this.props.route}/insert-project`}
            />
          </div>
        </div>
      </div>
      : 
      null

    const shifter = this.state.pagi && this.state.pagi[1]
      ? 
      <div className="py-1 d-flex flex-wrap justify-content-center">
        <Pagi
          all={this.state.pagi[1] - 1}
          current={this.state.pagi[0]}
          onChoose={this.onPagi}
        />
      </div>      
      : 
      null

    const posts = this.state.is_diaries
      ? (
        <div className="row">
          <div className="container colored">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
                <div className="role_descr">
                  {__("Last Diaries from Projects")}
                </div>
              </div>
              <div className="col-12">
                {shifter}
              </div>
            </div>
            {
              this.state.posts?.map((elem) => (
                <div className="row" key={elem.id}>
                  <div className="col-12">
                    <div className="diary_post mt-2">
                      <div className="diary_thumbnail">
                        <img src={elem.thumbnail !== "false" ? elem.thumbnail : ""} alt="" />
                      </div>
                      <div className="diary_title">
                        {elem.post_title}
                      </div>
                      <div
                        className="diary_body"
                        dangerouslySetInnerHTML={{ __html: elem.post_content }}
                      />
                      <div className="diary_footer">
                        <span>
                          <i className="fas fa-clock" style={{ opacity: 0.5 }} />
                          {elem.post_date}
                        </span>
                        <span>
                          <i className="fas fa-user" style={{ opacity: 0.5 }} />
                          {elem.post_author}
                        </span>
                        <NavLink
                          to={`/${this.props.blog_id}/member/${elem.prid}/diary`}
                          className="diary-link light"
                        >
                          <i className="fas fa-folder" style={{ opacity: 0.5 }} />
                          {" "}
                          {elem.diary}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="row py-2">
              <div className="col-12">
                {shifter}
              </div>
            </div>
          </div>
        </div>
      )
      : null
    
    // console.log( this.state )
    const bg = this.state.default_image
      ?
      {
        backgroundImage: `url(${ this.state.default_image })`, 
      } 
      :
      {
        position:"relative",
        width:"100%", 
        height:360,
        background: "linear-gradient(rgb(166 166 166) 0%, rgb(230 230 230) 100%)",
      }      
    // console.log( this.props.route ) 
    // console.log( this.props.match.params.festid ) 
    const content = this.state.content ? this.state.content.replace(/<\/?span[^>]*>/ig,"") : ""
    // const content = this.state.content.replace(/(<[^>]+\s+)(?:style\s*=\s*"(?!(?:|[^"]*[;\s])color\s*:[^";]*)(?!(?:|[^"]*[;\s])background-color\s*:[^";]*)[^"]*"|(style\s*=\s*")(?=(?:|[^"]*[;\s])(color\s*:[^";]*))?(?=(?:|[^"]*)(;))?(?=(?:|[^"]*[;\s])(background-color\s*:[^";]*))?[^"]*("))/ig, "")
    
    const laureates = Array.isArray(this.state.laureates)
      ?
      <div className=" colored ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
              <div className="role_descr">
                {__("Honnor Hall. Our laureates")}
              </div>
            </div>
            {
              this.state.laureates
                // .filter( l => !!l.prize )
                  .sort( (a,b) => {
                    const at = a.prize.toString().toUpperCase()
                    const bt = b.prize.toString().toUpperCase()
                    return at > bt ? 1 : -1
                  })
                  .sort( (a,b) => {
                    const at = Number(a.track.order)
                    const bt = Number(b.track.order)
                    return at > bt ? 1 : -1
                  })
                    .map(l => {
                      return <FestMemberCard
                        key={l.id}
                        route={this.props.route}
                        festid={this.props.match.params.festid}                
                        festData={ { ...this.state } }
                        //isMine
                        {...l}
                      />
                    })
            }   
          </div>
        </div>
      </div>
      :
      null
    return (
      <div className=" container-float ">
        <div style={ bg } className="main-fest-jumbotron-container">
          <div className="short-container main-fest-jumbotron"> 
            <div className="main-fest-contacts">
                { this.getContactsFrm() }
              </div>
            <div className="w-100 text-light d-flex justify-content-between flex-sm-row flex-column align-items-center align-items-md-end">
              
              <div className="">
                <div className="main-fest-logo-cont">
                  <div 
                    className="thumbnail main-fest-logo" 
                    style={{
                      backgroundImage: `url(${this.state.logo})`,
                      height: 60,
                      width: 60
                    }} 
                  />
                </div>                
                <div className="display-7 d-flex justify-content-md-start justify-content-center">
                  <span className=" main-fest-title ">
                    { this.state.festival_title }
                  </span>
                </div>
                <div className="">
                  <span className="main-fest-description mb-4 d-flex justify-content-md-start justify-content-center">
                    { this.state.festival_description }
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">  
                <div className="d-flex justify-content-center align-items-end">
                  <HelpForm 
                     youtube={[
                      { 
                        url:    "NkAKotQVPCA",
                        title:  "Общий вид программы", 
                        roles:  [ ]
                      },
                      { 
                        url:    "10H_W_eH6e4",
                        title:  "Коротко о регистрации", 
                        roles:  [ ]
                      },
                      { 
                        url:    "P2ikGDy0Yzo",
                        title:  "Закрыть регистрацию на трек", 
                        roles:  [ "Track_moderator", "administrator" ]
                      }
                    ]}  
                  />
                  <Link
                    className="indicator classic"
                    to={`${this.props.route}/members`}
                  >  
                    <div className="n1 text-light">
                      {__("To list")}
                    </div>
                    <div className="iconnn">
                      <img src={icon1} alt="" />
                    </div>
                  </Link> 
                  <LoginForm
                    url={ WPFestSettings.url }
                    route={this.props.route}
                    festid={ this.props.match.params.festid }
                  />
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="short-container"> 
          <div className="row w-100 btn-group my-3 mx-auto hidden ">
            {
              options.is_show_reglament
                ?
                <NavLink 
                  className=" btn btn-secondary col-md-6 col-12 text-uppercase border-0" 
                  to={`${this.props.route}/reglament`}
                >
                  {
                    __("Rules and Reglament")
                  }
                </NavLink>
                :
                null
            }
            
            {
              options.is_register_project
                ?
                <NavLink 
                  className=" btn btn-primary col-md-6 col-12 text-uppercase border-0" 
                  to={`${this.props.route}/insert-project`}
                >
                  {
                    __("Insert new Project")
                  }
                </NavLink> 
                :
                null
            }
                      
          </div>
        </div>
        <div className="short-container pb-5 px-md-0 px-3 ">
          <div className="row">
            <div className=" col-md-4  px-xl-3 px-0 mt-3 mt-xl-0 ">
              <div className="d-flex text-light">
                {
                  options.start_date
                    ?
                    <div className=" mb-3 w-50">
                      <div className="p-4 d-flex flex-column flex-centered bg-dark-secondary hover">
                        <span className="small">{__("Start date")}</span> 
                        <div className="display-5 title mt-3" style={{ lineHeight: .7 }}>
                          <Moment locale="ru" format="D">
                            {options.start_date}
                          </Moment>
                        </div>
                        <div className="title mb-1">
                          <Moment locale="ru" format="D MMMM"  filter={ this.trimMonth }>
                            {options.start_date}
                          </Moment>
                        </div>
                        <div className="small">
                          <Moment locale="ru" format="YYYY года">
                            {options.start_date}
                          </Moment>
                        </div>
                      </div>
                    </div>
                    :
                    null
                }
                {
                  options.finish_date
                    ?
                    <div className=" mb-3 w-50">
                      <div className="bg-dark p-4 d-flex flex-column flex-centered hover">                        
                        <span className="small">{__("Finish date")}</span>
                        <div className="display-5 title mt-3" style={{ lineHeight: .7 }}>
                          <Moment locale="ru" format="D">
                            {options.finish_date}
                          </Moment>
                        </div>
                        <div className="title mb-1">
                          <Moment locale="ru" format="D MMMM" filter={ this.trimMonth }>
                            {options.finish_date}
                          </Moment>
                        </div>
                        <div className="small">
                          <Moment locale="ru" format="YYYY года">
                            {options.finish_date}
                          </Moment>
                        </div>
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              {
                options.is_register_project
                  ?
                  <NavLink 
                    className=" btn btn-primary btn-block text-xl-right text-center text-uppercase border-0" 
                    to={`${this.props.route}/insert-project`}
                  >
                    {
                      __("Insert new Project")
                    }
                  </NavLink> 
                  :
                  null
              }
              {
                options.is_show_reglament
                  ?
                  <NavLink 
                    className=" btn btn-link btn-block a-dark text-xl-right text-center text-uppercase border-0" 
                    to={`${this.props.route}/reglament`}
                  >
                    {
                      __("Rules and Reglament")
                    }
                  </NavLink>
                  :
                  null
              }
            </div>
            <div className="col-md-8 mt-4">
              <div 
                className=""
                dangerouslySetInnerHTML={{__html : content}}
              />
            </div>
          </div>
        </div>
        {laureates}
        {my_projects}
        {posts}
        {trackCards}
        {statistics}
      </div>
    )
  } 
  onPagi = (page) => {
    _fetch(
      "get_diaries",
      {
        offset: page,
      },
      WPFestSettings.url,
      WPFestSettings.token,
      "get_main",
    )
      .then((data) => {
        console.log(data)
        this.setState({
          posts: data.posts,
          pagi: data.pagi,
          colm: data.colm,
        })
      })

    const pagi = [...this.state.pagi]
    pagi[0] = page
    this.setState({ pagi })
  }
}
export default compose(
  withRouter,
  withFestStore
)(FestAbout)

import React, {Component, Fragment} from "react";  

import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist"; 
import FieldInput from "react-pe-scalars/dist";
 
export default class CriteryForm extends Component
{
	state = {
		post_title 		: this.props.post_title,
		order 			: this.props.order,
		color 			: this.props.color,
		fmru_category	: this.props.meta.fmru_category && this.props.meta.fmru_category.length 
			? this.props.meta.fmru_category.map(elem => elem.id) 
			: [],
		fmru_milestone	: this.props.fmru_milestone,
		max_rating		: this.props.max_rating 
	}
	title = txt =>
	{ 
		this.props.onChange("post_title", txt, this.props.ID);
		this.setState({post_title : txt});
	}
	onOrder = txt =>
	{ 
		this.props.onChange("order", txt, this.props.ID);
		this.setState({order : txt});
	}
	onCategory = n =>
	{
		console.log(n);
		this.setState({fmru_category : n});
	}
	onMilestone = n =>
	{
		//console.log( this.props.meta.fmru_milestones.filter(ms => ms.ID == n ) )
		this.setState({
			fmru_milestone : this.props.meta.fmru_milestones.filter(ms => ms.ID == n ).map(ms => {return {...ms, id: ms.ID}})
		});
	}
	onMaxRaiting = max_rating =>
	{ 
		this.setState({ max_rating });
	}
	onGanre = n =>
	{
		this.setState({fmru_ganre : n});
	}
	onSave = () =>
	{
		this.props.onSave( this.state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	}
	render()
	{		
		console.log(this.props); 
		const del_btn = this.props.isNew ? null : <Popover
					position={Position.TOP_LEFT}
					content={
						<div className="square p-3">
							<div>
								<p>
									{__("Are you realy want delete?")}
								</p>
								<ButtonGroup className="p-2 tex-right">
									<Button
										intent={Intent.DANGER}
										text={__("Yes")}
										icon="cross"
										onClick={this.onDelete}
									/>
								</ButtonGroup>
							</div>
						</div>
						
					}
				>
					<Button
						text={__("Delete")}
						icon="cross"
					/>
				</Popover>
				
		return <Fragment>
			<FieldInput
				type="string"
				commentary={ __( "insert title by any symbols" ) }
				title={ __( "Title" )}
				field={ 'post_title' }
				value={ this.state.post_title }
				onChange={ this.title }
			/> 
			<FieldInput
				type="number"
				commentary={ __( "Position this Criteries's in list" ) }
				title={ __( "Order" )}
				field={ 'order' }
				value={ this.state.order }
				onChange={ this.onOrder }
			/> 
			{
				this.props.meta.fmru_categories && this.props.meta.fmru_categories.length > 0
					?
					<FieldInput
						visualization="festFormsFields" 
						type="tags" 
						editable
						title={ __( "Category" )}
						field={ 'fmru_category' } 
						value={ this.state.fmru_category }
						values={ this.props.meta.fmru_categories }
						onChange={ this.onCategory }
					/> 
					:
					null
			}
			  
			{
				this.props.meta.fmru_milestones && this.props.meta.fmru_milestones.length > 0
					?
					<FieldInput
						visualization="festFormsFields" 
						type="tags" 
						editable
						title={ __( "Milestone" )}
						field={ 'fmru_milestone' } 
						value={ this.state.fmru_milestone ? this.state.fmru_milestone[0]?.id : -1 }
						values={ this.props.meta.fmru_milestones.map(ms => {
							return {
								_id:ms.ID,
								title:ms.post_title
							}
						})}
						onChange={ this.onMilestone }
					/> 
					:
					null  
			}
			{
				this.props.options.is_independent_grade_criteries
					?
					<FieldInput
						type="slider" 
						editable
						title={ __( "Max rating" )}
						field={ 'fmru_category' } 
						value={ this.state.max_rating }
						step_size={1}
						label_step_size={1}
						min={0}
						max={this.props.options.max_raiting}
						onChange={ this.onMaxRaiting }
					/>   
					:
					null
			}
			{/* <FormGroup
				label={__("Parent Category")}
				helperText="Choose category"
				className="p-2"
			>
				<Dropdown
					options={this.props.meta.fmru_categories}
					current={this.state.fmru_category}
					multiple={false}
					className="btn-outline-secondary"
					onClick={this.onCategory}
				/>
			</FormGroup>
			<FormGroup
				label={__("Parent Milestone")}
				helperText="Choose Milestone"
				className="p-2"
			>
				<Dropdown
					options={this.props.meta.fmru_milestones}
					current={this.state.fmru_milestone}
					multiple={false}
					className="btn-outline-secondary"
					onClick={this.onMilestone}
				/>
			</FormGroup> */}
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}
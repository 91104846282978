import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldInput from 'react-pe-scalars/dist'
import { Button, ButtonGroup, Dialog, Icon, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import { useState } from 'react'
import MemberRatingDisplay from './MemberRatingDisplay'
import CriteryCommentaryDialog, { SEND_DESCRIPTION_EXTEND_LABEL, SEND_DESCRIPTION_LABEL } from './rating/CriteryCommentaryDialog'
import Style from 'style-it'

const CorrectRaiting = props => {
    const [rating, setRating] = useState( 
        props.correct_member_rate 
            ? 
            props.correct_member_rate.rate 
            : 
            0 
    )
    const [description, setDescription] = useState( 
        props.correct_member_rate 
            ? 
            props.correct_member_rate.description 
            : 
            "" 
    )
    const [isTrackEditForms, setTrackEditForms] = useState(false)
    useEffect(() =>
    {
        setRating(
            props.correct_member_rate 
                ? 
                props.correct_member_rate.rate 
                : 
                0
        )  
        setDescription(
            props.correct_member_rate 
                ? 
                props.correct_member_rate.description 
                : 
                ""
        )   
    }, [ props.correct_member_rate ])
    const isTrakOwner = () =>
    {
        return props.user?.roles.filter( role => role === "Track_moderator").length > 0
    }
    const onChange = value =>
    {
        console.log(value)
        setRating( value )
    }
    const correctRate = ( isReset, description) => {
        console.log(description)
        props.correctRate( isReset ? false : rating, description )
        setRating( isReset ? "" : rating)
        setTrackEditForms(false)
    }
    const onChangeDescription = description =>
    {
        console.log(description)
        setDescription( description )
    } 
    return isTrakOwner()
        ?
        <div className='d-flex justify-content-between'> 
            <div className={`lead py-2 d-flex `}>
                <span className={`px-2 title ${ rating && props.countedRating - rating ? "no-no-no" : "" } h-40px flex-centered`}> 
                    { props.countedRating }
                </span> 
            </div>
            <FieldInput 
                visualization="festFormsFields"           
                vertical
                not_clear
                editable={ isTrackEditForms }
                type={ "number" }
                min={ 0 }
                step_size={ 0.01 }
                label_step_size={ 1 }
                value={ rating || !isTrackEditForms ? rating : props.countedRating } 
                className="d-flex align-items-center super-lead p-2  h-100 w-100 typa-correct border-bottom border-transparent "
                inputStyle={{fontSize:"1.25rem!important", textAlign:"left"}}
                onChange={ onChange }
            />
            <Menu 
                {...props} 
                description={description}
                isUpdate={ rating !== props.rating }
                isTrackEditForms={isTrackEditForms}
                setTrackEditForms={setTrackEditForms}
                correctRate={correctRate}
                onChangeDescription={onChangeDescription}
            />
        </div>
        :
        <MemberRatingDisplay
            rating={rating}
            description={description}
            correct_member_rate={props.correct_member_rate}
            countedRating={props.countedRating}
            className={"lead py-0 title"}
        />
}

CorrectRaiting.propTypes = {}

export default CorrectRaiting

const Menu = props =>
{
    const [isUpdate, setUpdate] = useState( props.isUpdate )
    const [isEditOpen, setEditOpen] = useState( false )
    const [sendLabel, setSendLabel] = useState( SEND_DESCRIPTION_LABEL )
    useEffect(() => {
        setUpdate(props.isUpdate)
    }, [props.isUpdate])
    
    if(props.user?.roles.filter( role => role === "Track_moderator" ).length == 0) 
        return null
    const doUpdate = ( description ) =>
    {
        setUpdate( )
        props.correctRate( false, description ) 
    }
    const doReset = () =>
    {
        setUpdate(true)
        props.correctRate( true, "" )
    }
    const onEditOpen = () =>
    {
        setSendLabel( SEND_DESCRIPTION_LABEL )
        setEditOpen(!isEditOpen)
    }
    const onUpdateOpen = () =>
    {
        setSendLabel( SEND_DESCRIPTION_EXTEND_LABEL )
        setEditOpen(!isEditOpen)
    }
    return props.isTrackEditForms
        ?
        <>
            <ButtonGroup  className='pr-3'>
                {/* <Button 
                    small 
                    minimal 
                    disabled={ !isUpdate} 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Edit") }
                    onClick={onEditOpen}
                    intent={ Intent.NONE }
                >
                    <Icon icon="edit" />                    
                </Button> */}
                <Button 
                    small 
                    minimal 
                    disabled={ !isUpdate } 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Update") }
                    onClick={ onUpdateOpen }
                    intent={ Intent.SUCCESS } 
                >
                    <Icon icon="tick" />
                    
                </Button>
                <Button 
                    small 
                    minimal 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Reset") }
                    onClick={ doReset } 
                    intent={ Intent.DANGER }
                >
                    <Icon icon="trash" />                
                </Button>
                <Button 
                    small 
                    minimal 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Cancel") }
                    onClick={ () => props.setTrackEditForms(false) }
                    intent={ Intent.NONE }
                >
                    <Icon icon="cross" />                    
                </Button>
            </ButtonGroup>
            <CriteryCommentaryDialog
				isOpen={isEditOpen}
				description={props.description}
				lockWithoutComment={false}
				onOpen={ onEditOpen }
                sendLabel={ __( sendLabel ) }
                isHideChangeDescr
				onSend={ description => doUpdate( description )}
			/>
        </>
        :
        <>
            <Button 
                minimal 
                fill
                onClick={ () => props.setTrackEditForms(!props.isTrackEditForms) }
                className="text-nowrap px-4"
            >
                {__("Adjust rating")}
            </Button> 
        </>
}
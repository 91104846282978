export const cardTypes = () =>
{
	return [
		{
			type: 'box',
			icon: 'fas fa-expand',
			description: 'You need maximum anonymity of projects so that experts and viewers have minimal attachment'
		},
		{
			type: 'siple_stroke',
			icon: 'fas fa-align-justify',
			description: 'Only title stroke'
		},
		{
			type: 'stroke',
			icon: 'fas fa-list',
			description: 'Compact and informational design'
		},
		{
			type:'card',
			icon: 'fas fa-th-large',
			description: 'Medium duty design. You want projects to be smartly presented but compact enough'
		},
		{
			type: 'twosidecard',
			icon: 'fas fa-clone',
			description: 'Choose this design if Projects have image icons and many meaningful additional fields'
		},
	]
}
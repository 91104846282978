import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import { __ } from 'react-pe-utilities/dist'
import { Button, Collapse, Icon, Intent, Tag } from '@blueprintjs/core'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import $ from "jquery"
import FieldInput from 'react-pe-scalars/dist'
import TrackLabel from 'modules/pe-fest-module/views/track/TrackLabel'
import MemberLabel from '../MemberLabel'
import Moment from 'react-moment'

const ORDER_SORT = "order"
const ALPHABET_SORT = "alphabet"
const SORT_ORDER_ASC = "asc"
const SORT_ORDER_DESC = "desc"

const FullExpertStatistics = props => {
    const [ isRolesOpen,  setRolesOpen  ]   = useState( false )
    const [rolesFilter, setRolesFilter] = useState([...useFestStore.getState().options.fmru_evlbl_roles, "administrator"])
    const [sotrField, setSotrField]         = useState( ALPHABET_SORT )
    const [sortOrder, setSortOrder]         = useState( SORT_ORDER_ASC )

    const onRolesFilter = value =>
    { 
        setRolesFilter( value || [] )
    }
    const onSortOrder = () =>
    {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )

    }     
    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }
    
    const sortMembers = (a, b ) =>
    {
        switch(sotrField)
        {
            case ALPHABET_SORT: 
                const nameA = a.display_name.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.display_name.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.id ) - Number( b.id )
                    :
                    Number( b.id ) - Number( a.id ) 
        }
        return 1
    }

    let usersData = useFestStore.getState().statistics.users
        .filter(
            user => {
                if(user.roles.length === 0 )
                {
                    return true
                }
                return rolesFilter.filter(rf => {
                    return user.roles.filter(uf => {
                        return uf === rf
                    }).length > 0
                }).length > 0
            }
        )
    usersData.sort( sortMembers )
    const users = usersData.map((user, i) => {
            const myTracks = user.roles.filter(r => r === "Track_moderator")
                ?
                useFestStore.getState().tracks?.filter(track => {
                    const isModerator1 = track.moderator?.id === user.id
                    const isModerator2 = track.moderator2?.id === user.id
                    return isModerator1 || isModerator2
                })
                .map(track => <div className='row dat ' key={track.ID}>
                    <div className='col-md-2 layout-label'>{__("Track_moderator")}:</div>
                    <TrackLabel track={ track } className="title d-inline text-nowrap my-2 col-md-7 layout-data"/> 
                </div>)
                :
                null
            const myMembers = user.roles.filter(r => r === "Tutor") 
                ?
                useFestStore.getState().statistics.members   
                    .filter(member => member.tutor?.id === user.id)
                    .map(member => <div className='row dat ' key={member.id}>
                        <div className='col-md-2 layout-label'>{__("Tutor of project")}:</div>
                        <MemberLabel className="col-md-7 layout-data" member={member} />
                    </div>)
                :
                null
            const ratings = useFestStore.getState().statistics.full_ratings
                .filter(fr => {
                    return Number( fr.expert_id ) === user.id
                })
                .map((fr, i) => {
                    return <Fragment key={fr.ID}>
                    {
                        i == 0
                            ?
                            <tr className="bg-ight font-weight-light font-italic small">
                                <th scope="col" width="55" >
                                    {__("#")}
                                </th>
                                <th scope="user" >
                                    {__("time")}
                                </th>
                                <th scope="project" >
                                    {__("Project")}
                                </th>
                                <th scope="critery" >
                                    {__("Critery")}
                                </th>
                                <th scope="rates">
                                    {__("Rates")}
                                </th> 
                                <th scope="commentary">
                                    {__("Commentary")}
                                </th> 
                            </tr> 
                            :
                            null
                    }
                        <tr key={fr.ID}>
                            <td>

                            </td>
                            <td>
                                <Moment locale="ru" format="D.MM.YYYY HH:mm">
                                    {fr.unixtime * 1000}
                                </Moment>
                            </td>
                            <td>
                                {
                                    useFestStore.getState().statistics.members
                                        .filter( member => {
                                            return member.id === Number(fr.member_id)
                                        })
                                        .map(member => <div key={member.id} className="title" >{member.title}</div>)
                                }
                            </td>
                            <td>
                                {
                                    useFestStore.getState().statistics.criteries
                                        .filter( critery => {
                                            return critery.id === Number(fr.critery_id )
                                        })
                                        .map(critery => <div key={critery.id} className="title" >{critery.title}</div>)
                                }
                            </td>
                            <td>
                                <div className='lead title'>{fr.raiting}</div>
                            </td>
                            <td>
                                <div className=' description  font-italic'>{fr.description}</div>
                            </td>
                        </tr>
                    </Fragment>
                })
            return <Fragment key={ user.id }>
                <tr className='bg-light-strobe  border-top border-secondary border-2'>
                    <td>{i+1}</td>
                    <td colSpan={12}>
                        <div className='lead title py-2 text-md-left text-center max-width-100'>{ user.display_name }</div>
                        <div className=' '>{ user.user_email }</div>
                        <div className=' '>
                            { user.roles.map(role => <Tag round className='px-4 m-1' minimal key={role}>{__(role)}</Tag>) }
                        </div>
                        <div className=' '> { myTracks } </div>
                        <div className=''> { myMembers } </div>
                    </td>
                </tr>
                {ratings}
            </Fragment>
        })
    return (
        <div className='w-100'>
            <div className='w-100'>
                <div className='mt-1 mb-3 w-100'>
                    <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                        <div className='flex-centered'>
                            <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                                {__("Filters")}:
                            </div>
                            <Button intent={Intent.SUCCESS} minimal={ !isRolesOpen } onClick={() => setRolesOpen( !isRolesOpen ) }>
                                {__("Roles")} | { rolesFilter.length }
                            </Button> 
                        </div>
                        <div className='d-flex flex-md-row flex-column'>
                            <div className=' flex-centered ml-4 mr-2 descr text-uppercase text-secondary '>
                                {__("Do sort")}:
                            </div> 
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal={sotrField !== ORDER_SORT}
                                onClick={() => setSotrField(ORDER_SORT)}
                            >
                                {__("By Member's order")}
                            </Button>
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal={sotrField !== ALPHABET_SORT}
                                onClick={() => setSotrField(ALPHABET_SORT)}
                            >
                                {__("By alphabet")}
                            </Button> 
                            <Button 
                                intent={Intent.SUCCESS} 
                                minimal  
                                onClick={ onSortOrder }
                            >
                                <Icon 
                                    icon={ sortOrder === SORT_ORDER_ASC 
                                            ?
                                            "sort-asc"
                                            :
                                            "sort-desc"
                                    } 
                                    iconSize={20} 
                                />
                            </Button>
                        </div> 

                        <div className='flex-centered'>
                            <div className=' display-6 '>{users.length}</div>
                            <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                                <Icon icon="print" iconSize={21} className="hint hint--top" data-hint={__("Print")} />
                            </Button> 
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-light bg-transparent border-0 px-3 py-2 ml-1"
                                table="members-to-xls"
                                filename="full_member_list"
                                sheet="full_member_list"
                                buttonText={<Icon icon="th" iconSize={21} color="#6c757d" className="hint hint--top" data-hint={__("Download XLS")}/>}
                            />
                        </div>
                        
                    </div>
                </div>
                <Collapse isOpen={ isRolesOpen} className=" light-colored ">
                    <FieldInput
                        type="tags"
                        visualization="festFormsFields" 
                        multiple
                        vertical
                        editable
                        values={
                            [...useFestStore.getState().options.fmru_evlbl_roles, "administrator"].map(role => {
                                return { _id: role, title: __(role), intent: "success" }
                            })
                        }
                        value={rolesFilter}
                        onChange={onRolesFilter}
                        on={onRolesFilter}
                    />
                </Collapse> 
            </div>
            <table className='table table-striped table-bordered table-hover1 pe-fest-table-statistics w-100' id="members-to-xls">
                
                <tbody>
                    {users}
                </tbody>
            </table>
        </div>
    )
}

FullExpertStatistics.propTypes = {}

export default FullExpertStatistics
import React, { Component } from "react"
import { compose } from "recompose"
import { Mutation, withApollo } from "react-apollo"
import { withRouter } from "react-router"
import { Button, Card, Intent } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import { __ } from "react-pe-utilities"
import { getAllRoutes, mutationToken, queryUserInfo } from "react-pe-layouts" 
import { AppToaster } from 'react-pe-useful'
import {UserContext} from "react-pe-layout-app"
import VKLogin from "../core/VKLogin"
import config from "settings/config"

class LoginForm extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            login:"", 
            password:"", 
            showPass:false,
            isFlip:false
        }
    }

    login = e => this.setState({ login: e.currentTarget.value }) 
    passWord = e =>  this.setState({ password: e.currentTarget.value }) 
    showPass = e => this.setState({showPass:true})
    hidePass = e => this.setState({showPass:false})
    onVK = () =>
    {

    }
    responseVk = () =>
    {
        
    }
    onYandex = () =>
    {

    } 
    render()
    { 
        return this.loginForm( ) 
    }
    loginForm = ( ) =>
    {
        return <Mutation mutation={mutationToken()}>
            {(token, { data }) => (
                <UserContext.Consumer>
                {(
                    context => {                        
                        return <div className="d-flex h-100 flex-column">
                            <div className="flex-centered pt-4">
                                <VKLogin
                                    apiId={ config?.vk_api_key }
                                    value={"VK"}
                                    fields="name,email,picture"
                                    onClick={ this.onVK }
                                    callback={ this.responseVk} 
                                    className="wpfest-admin-cs mr-1"
                                />
                                <div className=" wpfest-admin-cs -hidden mr-1"> 
                                    <div className="soc yandex wpfest-admin-cs " onClick={ this.onYandex }>
                                        <i className="fab fa-yandex" />
                                    </div> 
                                </div>
                                <div className=" wpfest-admin-cs -hidden mr-1"> 
                                    <div className="soc telegram wpfest-admin-cs " onClick={ this.onYandex }>
                                        <i className="fab fa-telegram-plane" />
                                    </div> 
                                </div>
                                <div className=" wpfest-admin-cs -hidden mr-1"> 
                                    <div className="soc odnoklassniki wpfest-admin-cs " onClick={ this.onYandex }>
                                        <i className="fab fa-odnoklassniki" />
                                    </div> 
                                </div>
                            </div>
                            <form 
                                onSubmit={(evt) => this.onLogin(evt, token, context)}
                                className=" flex-centered flex-column p-md-5 px-3 py-4 mt-auto"
                            >
                                <div className="form-group w-100">   
                                    <input
                                        type="text"
                                        className="form-control input dark"
                                        placeholder={__("Email")}
                                        onChange={this.login}
                                    />
                                </div>
                                <div className="form-group w-100 position-relative">
                                    <input
                                        type={ this.state.showPass ? "text" : "password" }
                                        className="form-control input dark"
                                        placeholder={__("Password")}
                                        onChange={this.passWord}
                                    />
                                    <Button 
                                        minimal 
                                        style={{
                                            position: "absolute",
                                            top: 4,
                                            right: 3,
                                        }}
                                        icon={ this.state.showPass ? "eye-open" : "eye-off" }
                                        onMouseDown={this.showPass}
                                        onMouseUp={this.hidePass}
                                    />
                                </div>
                                <div className="form-group form-check hidden">
                                    <label
                                        className="form-check-label text-left"
                                        htmlFor="exampleCheck1"
                                    >
                                        {__("If you do not have a profile, then you can: ")}
                                        <NavLink
                                        to="/register"
                                        className="text-center"
                                        >
                                        {__("Register")}
                                        </NavLink>
                                    </label>
                                </div>
                                <div className="form-group form-check">
                                    <label
                                        className="form-check-label text-left"
                                        htmlFor="exampleCheck1"
                                    >
                                        {__("If you have forgotten your password: ")}
                                        <NavLink
                                        to="/remember"
                                        className="text-center"
                                        >
                                            {__("Remind password")}
                                        </NavLink>
                                    </label>
                                </div>
                                <input 
                                    type="submit" 
                                    className="btn btn-primary py-2 px-5 rounded-pill" 
                                    value={__("Sign in")} 
                                />
                            </form>
                        </div>
                    }
                )}
                </UserContext.Consumer>
            )}
        </Mutation>
    }
    onLogin = (evt, token, context) => 
    {
        evt.preventDefault()
        token({
            variables:
            {
                input: {
                    grant_type: "client_credentials",
                    login: this.state.login || "",
                    password: this.state.password || "",
                },
            },
            update: (store, { data }) => {
                //console.log(data)
                if (data.token) 
                {
                    //console.log(data)
                    AppToaster.show({
                        intent: Intent.SUCCESS,
                        icon: "tick",
                        message: __("You enter by User"),
                    })
                    localStorage.setItem(
                        "token",
                        data.token.access_token,
                    )
                    // context.setUser( queryUserInfo() );
                    // this.props.history.replace("/")
                    this.props.client.query({ query: queryUserInfo(), fetchPolicy: 'network-only' })
                        .then(
                            result => {
                                //console.log(result)
                                context.setUser(result.data.userInfo);
                                if(this.props.onResult)
                                {
                                    this.props.onResult( this.props.onResultArgs )
                                }
                                else
                                {
                                    // при условии - переходим в Кабинет Редактора
                                    const routes = getAllRoutes().filter( route => route.component === "EditorCabinet" )
                                    const usl = typeof routes[0] !== "undefined" && 
                                        result.data.userInfo.roles
                                            .filter( role => role === "administrator" || role === "editor" )
                                                .length > 0
                                    
                                    this.props.history.replace( usl ? routes[0].route : '/' )
                                }                        
                            },
                        );

                } 
                else 
                {
                    AppToaster.show({
                        intent: Intent.DANGER,
                        icon: "tick",
                        message: __("You not enter by User"),
                    })
                }
            },
            refetchQueries: [{ query: queryUserInfo(), variables: {} }],
        })
    }
}

export default compose(
    withApollo,
    withRouter,
  )( LoginForm )
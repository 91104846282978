export default () =>
{
    return [
        {
            id: "nacs",
            type:"By order", 
            icon: "sort-numerical",

        },
        {
            id: "ndecs",
            type:"By order reverse", 
            icon: "sort-numerical-desc",

        },
        {
            id: "acs",
            type:"By alphabet", 
            icon: "sort-alphabetical",

        },
        {
            id: "decs",
            type:"By alphabet reverse", 
            icon: "sort-alphabetical-desc",

        },
        {
            id: "racs",
            type:"By rating", 
            icon: "sort-desc",

        },
        {
            id: "rdecs",
            type:"By raiting reverse", 
            icon: "sort-asc",

        },

    ]
}
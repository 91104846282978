import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import User from '../utilities/User'
import { Button, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import _fetch from '..'
import WPFestSettings from '../utilities/WPFestSettings'

const CorrectNomination = props => {
    const[isEdit, setEdit] = useState(false)
    const [nomination, setNomination] = useState(props.member.nomination)
    useEffect(() =>
    {
        setNomination(setNomination)
    }, [props.member.nomination])
    //console.log( props )
    const onNomination = () =>
    {
        const args =  {nomination, member_id: props.member.id, festId: WPFestSettings.festId, command: "correct_member_nomination" }
        _fetch(
            "get_fest_command", 
            {...args},
            WPFestSettings.serviceURL,
            WPFestSettings.getToken( WPFestSettings.url ),
            "wpfa",
          )
            .then((_data) => {
                  const data = _data.data 
                  setNomination( data.correct_member_nomination ) 
                  setEdit(false)			 
            })
        
    }
    const edit = () =>
    {
        return <div className="d-flex align-items-center ">
            <input 
                type="string"
                className='form-control0 input dark w_350'
                value={nomination}
                onChange={ (evt) => setNomination( evt.currentTarget.value )}
            />
            <Button
                intent={Intent.SUCCESS}
                icon="tick"
                minimal
                className='hint hint--top'
                data-hint={__("Save")}
                onClick={onNomination}
            />
            <Button
                intent={Intent.DANGER}
                icon="cross"
                minimal
                className='hint hint--top'
                data-hint={__("Cancel")}
                onClick={ () => setEdit( false ) }
            />
        </div>
    }
    const noEdit = () =>
    {
        return <div className="row align-items-center ">
            <div className="text-nowrap mx-3">
                { props.member.nomination }
            </div>
            <div className=" ">
                <Button
                    minimal
                    fill
                    icon="edit"
                    onClick={ () => setEdit( true ) }
                >
                    
                </Button>
            </div>
        </div>
    }
    return User.isRole([ "administrator", "track_moderator" ])
        ?
        isEdit ? edit() : noEdit()
        :
        props.member.nomination
}

CorrectNomination.propTypes = {}

export default CorrectNomination
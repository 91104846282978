import { __ } from "react-pe-utilities/dist" 

export default  (count, cases) =>
{ 
    if(typeof count !== "number") return __( `${cases}_case_2` ) 
    const ost = count % 10
    if( ost > 1 && ost < 5 && (count < 10 || count > 20) )
    {
        return __( `${cases}_case_1` ) 
    }
    else if( ost === 1  && (count < 10 || count > 20) )
    {
        return __( `${cases}_case_0` ) 
    }
    else if( ost >= 5 || ost === 0  || (count > 10 || count < 20) )
    {
        return __( `${cases}_case_2` ) 
    }
    return ""
}
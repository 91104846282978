import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, Tag } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'

const Tags = props => {
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            if(props.multiple)
            {
                let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
                return v
            }
            else
            {
                return props.value
            } 
        })
    }, [props.value] )
    const onClick = id =>
    {
        // console.log( "onClick", id )
        setValue(id)
        props.onChange(id,  props.field)
    }
    const onMultipleClick = id =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter(v => v === id ).length > 0)
        {
            vv = value.filter(v => v !== id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        setValue( vv )
        props.onChange(vv,  props.field)
    }
    const onAll = () =>
    {
        let vv = value.length === props.values.length 
            ? 
            [] 
            : 
            [ ...props.values.map( v => v._id ? v._id : v ) ]
        setValue( vv )
        props.onChange(vv,  props.field)
    }
    const values = Array.isArray([...props.values ])
        ?
        props.values.map( (v, i) =>
        {
            return !props.multiple
                ?
                single({ v, value, i, onClick })
                :
                miltiple({ v, value, i, onMultipleClick })
        })
        :
        [ ]
    return (
        <div className='w-100 d-flex flex-wrap py-2 '>
            { values } 
            {
                props.multiple
                    ?
                    <Tag 
                        intent={"primary"} 
                        className="m-1 px-3 py-1 pointer" 
                        minimal 
                        round
                        onClick={ onAll }
                    >
                        { __( "All" ) }
                    </Tag>
                    :
                    null
            }
            
        </div>
    )
}

Tags.propTypes = {}

export default Tags

const miltiple = props =>
{
    const {v, value, i} = props
    // console.log( v, value, i )
    return  v?._id 
        ?
        <Tag 
            key={ v._id + "_" + i } 
            intent={ v.intent }
            className="m-1 px-3 py-1 pointer" 
            round
            minimal={ value.filter( e => e === v._id ).length === 0 }
            onClick={() => props.onMultipleClick( v._id )}
        >
            <div className='pl-3' dangerouslySetInnerHTML={{__html: v.title}} />
        </Tag>
        :
        typeof v === "string"
            ? 
            <Tag 
                key={v + "_" + i} 
                value={ v }  
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value.filter( e => e !== v ).length > 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className='pl-3' dangerouslySetInnerHTML={{__html: v }} />
            </Tag> 
            :
            null
}
const single = props =>
{
    const {v, value, i} = props
    //console.log( value )
    return  v?._id 
        ?
        <Tag 
            key={ v._id + "_" + i } 
            intent={v.intent}
            data-hint={__(v.hint)}
            className={`m-1 pr-3 py-1 ${v.disabled ? "opacity_75 hint hint--top " : ""} pointer`} 
            round  
            minimal={ value !== v._id }
            onClick={() => !v.disabled ? props.onClick(v._id) : null }
        >
            {
                v.disabled 
                    ? 
                    <div className='d-flex'>
                        <Icon icon="delete" intent="danger" className='pr-1'/>
                        <div className='opacity_5' dangerouslySetInnerHTML={{__html: v.title}}/>
                    </div> 
                    : 
                    <div className='pl-3' dangerouslySetInnerHTML={{__html: v.title}} />
                }
        </Tag>
        :
        typeof v === "string"
            ? 
            <Tag 
                key={v + "_" + i} 
                value={ v }  
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value !== v }
                onClick={() => props.onClick( v )}
            >
                { v }
            </Tag> 
            :
            null
}
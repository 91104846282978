import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Icon, Intent } from '@blueprintjs/core' 
import { __ } from 'react-pe-utilities/dist' 
import cardSortType from 'modules/pe-fest-module/data/cardSortType'

const CardFilterEngine = props => {
    return <ButtonGroup minimal vertical>
    {
        cardSortType().map(type =>
            {
                return <Button
                    key={type.id}
                    intent={type.id !== props.type ? Intent.NONE : Intent.DANGER}
                    data-hint={ __(type.type) }
                    className={`hint hint--right flex-centered `}
                    onClick={() => props.onClick(type.id) }
                >
                    <Icon icon={type.icon} className={ type.id === props.type ? "" : " opacity_5" } iconSize={12} />
                </Button>
            })
    }
    </ButtonGroup>
}

CardFilterEngine.propTypes = {}

export default CardFilterEngine
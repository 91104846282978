import React, { Suspense } from "react" 
import { Loading } from "react-pe-useful" 
import LayoutBody from "./LayoutBody"
import UserContext from "./layoutConfig/userContext"
import LayoutProvider from "./layoutConfig/LayoutProvider"
import LayoutContent from "./LayoutContent"
import LayoutFooter from "./LayoutFooter"
import LayoutHeader from "./LayotHeader" 

const CSSinks = () => [
  {
    id :    "normalize",
    href :  "https://unpkg.com/normalize.css@^7.0.0"
  },
  {
    id :    "fontawesome",
    href :  "https://use.fontawesome.com/releases/v5.15.4/css/all.css"
  },
  {
    id :    "googleFonts",
    href :  "https://fonts.googleapis.com/css?family=Amatic+SC|Ubuntu+Condensed|Yanone+Kaffeesatz|Open+Sans+Condensed:300|Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap|Yanone+Kaffeesatz:200&display=swap"
  },
  {
    id :    "googleFonts2",
    href :  "https://fonts.googleapis.com/css?family=PT+Sans:regular,italic,bold,bolditalic"
  },
  {
    id :    "socialLikes",
    href :  "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes_birman.css"
  },
  {
    id :    "bootstrap",
    href :  "/assets/css/bootstrap.min.css"
  },
  {
    id :    "blueprint",
    href :  "/assets/css/blueprint.css"
  },
  {
    id :    "blueprintIcons",
    href :  "/assets/css/blueprint-icons.css"
  },
  {
    id :    "phone",
    href :  "/assets/css/phone.css"
  },
]
const CSSScripts = () => [
  {
    id: "ckeditor-jssdk",
    href: "/assets/js/ckeditor/ckeditor.js"
  },
  {
    id: "classnames-jssdk",
    href: "https://unpkg.com/classnames@^2.2"
  },
  {
    id: "youtube-jssdk",
    href: "https://www.youtube.com/iframe_api"
  },
  {
    id: "jitsi-jssdk",
    href: "https://jitsi.protopia-home.ru/external_api.js"
  },
  {
    id: "dom4-jssdk",
    href: "https://unpkg.com/dom4@^1.8"
  },
  {
    id: "react-jssdk",
    href: "https://unpkg.com/react@^16.2.0/umd/react.production.min.js"
  },
  {
    id: "react-dom-jssdk",
    href: "https://unpkg.com/react-dom@^16.2.0/umd/react-dom.production.min.js"
  },
  {
    id: "react-transition-group-jssdk",
    href: "https://unpkg.com/react-transition-group@^2.2.1/dist/react-transition-group.min.js"
  },
  {
    id: "blueprintjs-core-jssdk",
    href: "https://unpkg.com/@blueprintjs/core@^2.0.0"
  },
  {
    id: "blueprintjs-icons-jssdk",
    href: "https://unpkg.com/@blueprintjs/icons@^2.0.0"
  },
  {
    id: "jquery",
    href: "https://code.jquery.com/jquery-3.2.1.slim.min.js"
  },
  {
    id: "social-likes-jssdk",
    href: "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes.min.js"
  },
  {
    id: "popper",
    href: "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
  },
  {
    id: "bootstrap",
    href: "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
  },
]
class LayoutApp extends React.Component 
{  
  componentDidMount()
  {
    CSSinks().forEach(cssLink => {
      const el = document.createElement("link")
      el.rel = "stylesheet"
      el.type = "text/css"
      el.href = cssLink.href
      el.id = cssLink.id
      document.getElementsByTagName("head")[0].appendChild(el)
    })
    CSSScripts().forEach(cssScript => {
      const el = document.createElement("script") 
      el.type = "text/javascript"
      el.src = cssScript.href
      el.async = true
      el.id = cssScript.id
      //document.getElementsByTagName("head")[0].appendChild(el)
      document.getElementsByTagName("body")[0].appendChild(el)
    })
  }
  render() {  
    return <>
      <Suspense fallback={<Loading />}>
          <LayoutBody />
      </Suspense>
    </> 
  }
}

export default LayoutApp
export {
  UserContext,
  LayoutProvider,
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutBody,
  CSSinks,
  CSSScripts
}
import React, {Fragment,Component} from "react"; 
import { Icon, Tag, Intent, Tooltip, Card, Popover, Position, Dialog, Button } from "@blueprintjs/core";
import Categories from "./Categories";
import UserForm from "./single/UserForm";
import UserBatchForm from "./single/UserBatchForm";
import _fetch from "modules/pe-fest-admin-module/api";
import { __ } from "react-pe-utilities/dist";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import { AppToaster } from "react-pe-useful/dist";

export default class Users extends Categories
{
	
	type = "user"
	componentWillMount()
	{
		IndexDB.find( 
			"peFestAdmin_mySites",
			"sites",
			"sites",
			this.props.blog_id
		)
			.then( sites =>
			{  
				// console.log( sites.data.roles );
				let roles =  Array.isArray(sites?.data.roles) ? sites.data.roles : []
				roles.unshift("administrator");
				this.init({
					form_component	: UserForm,
					theadColor		: "#ab2da1",
					meta			: { 
						role 		: [], 
						roles 		: roles.map( e=> {return {post_title:__(e), id:e} } ),
						festivalOptions	: this.props.options,
						display_name: "",
						user_login	: "",
						user_email 	: ""
					}
				})
			})


		_fetch( "lead_fest_roles" , { id:this.props.blog_id } )
			.then( data =>
			{		
				if(!data.isError)
				{
					data.lead.post.unshift("administrator");
					this.init({
						form_component	: UserForm,
						theadColor		: "#ab2da1",
						meta			: { 
							role 		: [], 
							roles 		: data.lead.post.map( e=> {return {post_title:__(e), id:e} } ),
							festivalOptions	: this.props.options,
							display_name: "",
							user_login	: "",
							user_email 	: ""
						}
					})
				}
				
			});
	}
	changeRoleFilter = role =>
	{
		//console.log(role)
		let fer = [...this.state.fmru_evlbl_roles]
		if(fer.filter(r => r === role).length > 0)
		{
			fer = fer.filter(r => r !== role)	
		}
		else
		{
			fer = [ ...fer, role ]
		}
		this.setState({fmru_evlbl_roles : fer})
	}
	preTable = () =>
	{
		const {post} = this.state 
		return <div className="d-flex align-items-center ">
			<div className=" lead mr-3 mb-2">
				<span className="title">{ post.length }</span> | { this.projects.length }
			</div>
			{
				[...this.props.options.fmru_evlbl_roles, "administrator"].map(fer => {
					const count = post?.filter( user => user.role.filter(role => fer === role).length > 0 ).length
					// console.log( this.state.fmru_evlbl_roles )
					return <label className="_check_small_  mr-3" key={fer}>
						<input 
							type="checkbox" 
							value="1" 
							checked={this.state.fmru_evlbl_roles.filter(r => r === fer).length > 0}
							onChange={() => this.changeRoleFilter( fer ) }
						/>
						{__(fer)} ( {count} )
					</label>
				})
			}
		</div>
	}
	filterMe = ( element, i ) =>
	{ 
		if(this.state.searchString)
		{ 
			return element.display_name.toLowerCase().indexOf( this.state.searchString.toLowerCase() ) >= 0
		}
		return this.state.fmru_evlbl_roles.filter( role => {
			return element.role.filter(r => r === role).length > 0 || element.role.length === 0
		}).length > 0
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color' && elem[0] != 'title' && elem[0] != 'order');
		rw.splice(1, 0, [
			'user',
			<div className="d-flex" key={"user"}>
				<span className="mx-1">{__("User")}</span>  
			</div>,
			"100%"
		]);
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "user":
				// console.log( col, elem )
				const roles = elem.role?.map((e, i) => <Tag
							intent={Intent.NONE}
							interactive={true}
							className="mr-1"
							key={i}
						>
							{ __(e) }
						</Tag>);
				const requestBages = elem.requests?.length > 0 
					?
					<Popover
						position={Position.TOP}
						interactionKind="hover"
						content={
							<div className="p-4"> 
								<div className="lead mb-4">
									{ __( "Request capabilities from " ) + elem.display_name }
								</div>
								{
									elem.requests.map((req, i) =>
									{
										return  <div className="d-flex align-items-center mb-1" key={i}>
											<div className="flex-grow-100 mr-3" style={{width: "calc(100%  - 170px)"}}>
												{__(req)}
											</div>
											<div className="btn-group">
												<div 
													className="btn btn-success btn-sm px-3 pb-1 pt-0" 
													style={{width: 90}}
													onClick={ () => this.onAccess( elem.id, req ) }
												>
													<span className="small">{__("Access")}</span>
												</div>
												<div 
													className="btn btn-danger btn-sm px-3 pb-1 pt-0" 
													style={{width: 80}}
													onClick={ () => this.onReject( elem.id, req ) }
												>
													<span className="small">{__("Reject")}</span>
												</div>
											</div>
										</div>
									}) 
								}
							</div>
						}
					>
						<div className="ml-1">
							<span className="badge badge-pill badge-primary pb-1 pointer">{elem.requests.length}</span>
						</div>
					</Popover>
					:
					null
				txt = <Fragment key="user">
					<div className={`d-flex ${ elem.is_blocked ? "opacity_5" : "" }`}>
						{
							elem.is_blocked
								?
								<Icon icon="blocked-person" iconSize={ 22 } className="mr-2 mt-1 hint hint--top" data-hint={__("Bunned")}/>
								:
								null

						}
						<div className="lead mb-1">
							{elem.display_name}
						</div>
						
						{ requestBages }
					</div>
					 
					<div>
						{roles}
					</div>
					<Dialog
						isOpen={this.state.isForceDeleteOpen}
						onClose={() => this.setState({isForceDeleteOpen:false})}
						className="little"
					>
						<div className="p-5 d-flex flex-column flex-centered">
							<div className="lead text-center mb-5">
								{__("Delete this user from all festivals?")}
							</div>
							<div>
								<Button intent={Intent.DANGER} onClick={this.onForceDeleteDo}>
									{__("Yes")}
								</Button>
							</div>
						</div>
					</Dialog>
				</Fragment>
				break;
		}
		return txt;
	}
	
	row_edit()
	{
		return <span className="ml-2" key="batch">
			<Tooltip intent={Intent.DANGER} content={__(!this.state.is_batch_creation ? "batch creation" : "close")}>
				<Icon icon={!this.state.is_batch_creation ? "list" : "undo" } onClick={this.onBitchCreation}/>
			</Tooltip>
		</span>
	}
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation});
	}
	
	addThead()
	{
		return this.state.is_batch_creation ?
		<tr>
			<td colSpan={13}>
				<UserBatchForm 
					roles = {this.meta.roles}
					goClose = {this.onBitchCreation}
					onCreate = {this.onCreate}
					blog_id = {this.props.blog_id}
				/>
			</td>
		</tr> : null;
	}
	onForceDelete = user_id =>
	{
		this.setState({ isForceDeleteOpen:true, forceDeleteUserID: user_id })
	}
	onForceDeleteDo = () =>
	{
		_fetch( "force_delete_user" , { id: this.props.blog_id, user_id: this.state.forceDeleteUserID } )
			.then( data =>
			{		
				console.log(data); 
				this.setState({ post: data.post, isForceDeleteOpen: false });
				if(data.is_delete)	
				{
					AppToaster.show({
						message: "Full delete User from all Frstivals",
						duration:2000,
						className: "p-5 lead"
					})
				}	
			});
	} 
	onCreate = data =>
	{
		this.setState({post:data});
	}
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation, isNew:false});
	}
	onAccess = (user_id, role) =>
	{
		_fetch( "lead_acces_user_roles" , { id:this.props.blog_id, user_id, role } )
			.then( data =>
			{		
				console.log(data);	
				if(data.access)	
				{					
					this.setState({post : data.post})
				}		
			});
	}
	onReject = (user_id, role) =>
	{
		_fetch( "lead_reject_user_roles" , { id:this.props.blog_id, user_id, role } )
			.then( data =>
			{		
				console.log(data);
				if(data.access)	
				{					
					this.setState({post : data.post})
				}					
			});
	}
}
/* obsolete */

export default props => {

    const average_rating = props.criteries.reduce((prev, current) => {
        return prev + current.average_critery_rating
    }, 0)

    return average_rating
}

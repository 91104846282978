import React, { Component } from "react"
import { compose } from "recompose" 
import { withRouter } from "react-router"
import { Button, Card, Intent } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm"

class LoginRegisterForm extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            login:"", 
            password:"", 
            showPass:false,
            isFlip:false
        }
    }
    flip = () =>
    {
        this.setState({ isFlip: !this.state.isFlip })
    }
    render()
    {
        const orders = this.props.extend_params?.flap?.position === "right"
            ?
            [ 2, 1, 0 ]
            :
            [ 0, 1, 2 ]
        return <div className="col-12">
            <div className="p-0 w-100">
                <Card 
                    elevation={4}
                    className="m-auto d-flex p-0" 
                    style={{
                        maxWidth: 750,
                        backdropFilter: "blur(6px)",
                        backgroundColor:"#EEEEEE88"
                    }}
                > 
                    <div 
                        className={` flap pb-md-5 pb-4 ${ this.state.isFlip ? "flip" : "" }`}
                        style={{
                            backgroundImage:this.props.extend_params.flap.thumbnail?.indexOf("http") === 0
                                ?
                                `url(${this.props.extend_params.flap.thumbnail})`
                                :
                                this.props.extend_params.flap.thumbnail,
                            order: orders[0],
                            width: "50%"
                        }} 
                    >
                        <div className="flap-overlay">
                            <div className="flap-overlay-left" style={{transform: "translateX(-70px)"}}>
                                <div className=" display-6 title text-light text-center text-black-contour">
                                    {__("Hello, Friend!")}
                                </div>
                                <div className="title m-5 text-light text-center text-black-contour">
                                    {__("Enter your personal details and start journey with us")}
                                </div>
                                <Button
                                    className="py-3 px-5 rounded-pill"
                                    intent={Intent.DANGER}
                                    onClick={ this.flip }
                                >
                                    {__( "Sign up")}
                                </Button>
                            </div>
                            <div className="flap-overlay-right" style={{transform: "translateX(70px)"}}>
                                <div className=" display-6 title text-light text-center text-black-contour">
                                    {__("Welcome Back!")}
                                </div>
                                <div className="title m-5 text-light text-center text-black-contour">
                                    {__("To keep connected with us please login with your personal info")}
                                </div>
                                <Button
                                    className="py-3 px-5 rounded-pill"
                                    intent={Intent.DANGER}
                                    onClick={ this.flip }
                                >
                                    {__( "Sign in" )}
                                </Button>
                            </div>
                            
                        </div>  
                    </div> 

                    <div className={`w-100 order-${ orders[ 1 ] } overflow-hidden`} >
                        <div className={`under-flip-right h-100 ${ this.state.isFlip ? "flip" : "" }`}>
                           <div className="display-6 p-3 text-center">
                                {__("Create account")}
                            </div>
                            <RegisterForm {...this.props}/> 
                        </div>
                        
                    </div> 

                    <div className={`w-100 order-${ orders[ 2 ] }  d-flex flex-column flex-fill overflow-hidden`} style={{justifyContent:"stretch"}}>
                        <div className={`under-flip-left  min-height-100  ${ !this.state.isFlip ? "flip" : "" }`} style={{justifyContent:"stretch"}}>
                            <div className="display-6 p-3 text-center ">
                                {__("Sign in")}
                            </div>
                            <LoginForm  {...this.props}/>
                        </div>               
                    </div>               
                </Card>
            </div>
        </div>
    } 
}

export default compose( 
    withRouter,
  )( LoginRegisterForm )
import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { Callout, Dialog, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import WPFestSettings from '../../utilities/WPFestSettings'
import TextArea from '../../utilities/TextArea'
import { NEED_DESCRIPTION_COMMENT } from './Critery'

const CriteryCommentaryDialog = props => {
    const [, forceUpdate] = useReducer(x => x + 1, 0); 
    const [description , setDescription] = useState( props.description )
    const [placeholder, setPlaceholder] = useState("")
    const [isOpen , setOpen] = useState( false )
    useEffect(() =>
    {
        setOpen( props.isOpen )
    }, [props.isOpen])
    const onOpen = () =>
    {
        props.onOpen(!isOpen) 
    }
    const onIsDescr = ( ) => 
    {
        WPFestSettings.set_is_comment(!WPFestSettings.is_comment)
        forceUpdate()
    }
    const onDescription = evt =>
    {
        const value = evt.target.value
        setDescription(value)
        setPlaceholder("")
    }
    const onForceSend = () =>
    {
        if(props.lockWithoutComment && !description)
        {
            setPlaceholder(NEED_DESCRIPTION_COMMENT + "\n" + placeholder)
            return;
        }
        props.onSend( description )
        onOpen()
    }
    const comment = () =>
    {
        return <Callout intent={Intent.DANGER} className="w-100 ">
            { NEED_DESCRIPTION_COMMENT }
        </Callout> 
    }

    return <Dialog
        isOpen={ isOpen }
        className="rounded-0 little"
        onClose={ onOpen }
        title={__("Edit you description")}
    >
        <div className="d-flex flex-column justify-content-center">
            <div className="w-00">
                { props.lockWithoutComment ? comment() : null }
            </div>
            <textarea
                rows={7}
                placeholder={placeholder}
                className="form-control input dark p-4 border-bottom-0"
                onChange={ onDescription }
                value={ description }
            />
            <div className="d-flex w-100">
                <div className="btn btn-secondary rounded-0 btn-block" onClick={ onForceSend }>
                    { props.sendLabel || SEND_DESCRIPTION_LABEL }
                </div>
                {
                    props.isHideChangeDescr
                        ?
                        null
                        :
                        <label className="_check_ m-2 w-100">
                            <input
                                type="checkbox"
                                onChange={ onIsDescr }
                                value={1}
                                checked={ WPFestSettings.is_comment } 
                            />
                            {__("Do comments automaticaly")}
                        </label>
                }
                
            </div>
        </div>
    </Dialog>
}

CriteryCommentaryDialog.propTypes = {}

export default CriteryCommentaryDialog


export const SEND_DESCRIPTION_LABEL = "Send description"
export const SEND_DESCRIPTION_EXTEND_LABEL = "Change description and update rating"
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'

const FindForm = props => {
    const [searchString, setSearchString] = useState("")
    const onSearchString = evt =>
    {
        const value = evt.target.value
        setSearchString(value)
    }
    const onSearch = () =>
    {
        if(props.onSearch) props.onSearch( searchString ) 
    }
    const onClear = () =>
    {
        setSearchString("")
        if(props.onSearch) props.onSearch( "" ) 
    }
    return <div className='flex-centered'>
        <input 
            type="text"
            className='w_250 mx-2 input dark'
            value={searchString}
            onChange={onSearchString}
        />
        <Button 
            minimal
            icon="search"
            onClick={onSearch}
        />
        <Button
            minimal
            icon={ searchString.length > 0 ? "cross"  : "blank"}
            className={searchString.length > 0 ? "" : "untouchble"}
            onClick={onClear}
        />
                
    </div> 
}

FindForm.propTypes = {}

export default FindForm
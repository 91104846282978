import React from "react"
import { NavLink,   } from "react-router-dom"
import { __ } from "react-pe-utilities" 
import BasicState from "react-pe-basic-view"
import { isCapability } from "react-pe-utilities"
import Layouts, { menu } from "react-pe-layouts"
import $ from "jquery"
// out
class MainAdminView extends BasicState {
  componentDidMount()
  {
    setTimeout(() => {
      $(".card-text").each((index, element) => {
        max = Math.max( max, $(element).innerHeight() )
      })
      $(".card-text").innerHeight( max )

      $(".card-title").each((index, element) => {
        max2 = Math.max( max2, $(element).innerHeight() )
      })
      $(".card-title").innerHeight( max2 )
    }, 100)
    let max = 0, max2 = 0;
    
  }
  addRender() {
    // console.log(this.props);
    const isChildParams = this.props.extend_params && Array.isArray( this.props.extend_params.child_routes )
    const menus = (
      isChildParams
        ?
        menu().menu.filter(menu => {
          return this.props.extend_params.child_routes.filter(chr => chr == menu.route).length > 0 
        })
        :
        this.props.children
          ?
          this.props.children
          :
          []
    ).map((e, i) => {
          const isRole = isCapability(e.capability, this.props.user)
          if(isRole) return null
          const url = ( isChildParams ?  `${e.route}` : `${this.props.route}/${e.route}` ).replace(/\/+/g,'/')
          return (
            <div className="col-md-3 p-1" key={i}> 
              <div 
                className="card bg-light text-center text-secondary border-0" 
              >
                <div 
                    className="card-image"
                    style={{ 
                      width: "100%", 
                      height: "100%",
                      minHeight: 300,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${ e.thumbnail })`
                    }}
                >
                  <div className="card-image-icon">
                    <div className={e.icon}></div>
                  </div>
                </div>
                <div className="card-body d-flex flex-column align-items-center">                
                  <h4 className="card-title p-3 text-dark " dangerouslySetInnerHTML={{ __html:__(e.title) }}/>
                  <div className="card-text p-3" dangerouslySetInnerHTML={{ __html: __(e.description) }}/>
                  <NavLink
                    to={url}
                    className="btn btn-outline-info text-right mt-auto rounded-pill px-4"
                  >
                    { __( "Goto" ) }
                  </NavLink>
                </div> 
              </div>
            </div>
          )
        })
    return (
      <div className="container h-100 flex-centered">
        <div className="layout-state-description" dangerouslySetInnerHTML={{__html : __(this.props.description)}}/>
        <div className="row justify-content-center">
          {menus}
        </div>
      </div>
    )
  }

	getRoute = () => this.props.route;
}
export default MainAdminView

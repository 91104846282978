import React from 'react'
import PropTypes from 'prop-types'
import RussionRegionList from 'modules/pe-fest-admin-module/data/RussianRegionList'
import MultiSelect from 'states/scalars/Multiselect'


const RussianRegionsSelector = props => {
    const value = typeof props.value === "undefined" ? props.default : props.value
    //console.log( props )
    return <MultiSelect 
        field={props.field} 
        editable={props.editable} 
        default={props.default}
        value={value}
        multiple={props.multiple || false}
        values={ RussionRegionList().map(r => ({_id:r, title:r, color:"#000"})) } 
        on={ props.on } 
        data={{ color : "red" }} 
    />
}

RussianRegionsSelector.propTypes = {}
export default RussianRegionsSelector
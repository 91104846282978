import React, { Component } from "react"
import { compose } from "recompose"
import { withRouter } from "react-router"
import { NavLink } from "react-router-dom"
import { Dialog, Position, Popover, Tooltip,  } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import { getCookie, setCookie } from "../../utilities/utils"

import Login from "./Login" 
import login1 from "modules/pe-fest-module/assets/img/login1.svg"
import logout_img from "modules/pe-fest-module/assets/img/logout.svg"
import usser_img from "modules/pe-fest-module/assets/img/usser.svg"
import employee from "modules/pe-fest-module/assets/img/employee.svg" 
import WPFestSettings from "../../utilities/WPFestSettings"
import User from "../../utilities/User"
import { getAllRoutes, moduleExists } from "react-pe-layouts"
import RequestRoleForm from "./RequestRoleForm"
import _fetch from "../.." 
import actions, { SET_TRACK_FILTER_ACTION } from "modules/pe-fest-module/data/actions"
import { DEFAULT_MEMBER_FILTERS } from "modules/pe-fest-module/data/store"

class LoginForm extends Component {
  timeOut = -1
  constructor(props) {
    super(props)
    this.state = {
      isCreateOpen: false,
      is: false,
      reqRoles: [],
    }
    this.all_roles = [...WPFestSettings.fmru_evlbl_roles]
    this.all_roles.unshift("administrator")
  }
  componentWillUnmount()
  {
    clearTimeout( this.timeOut )
  }
  render() { 
    const token = WPFestSettings.getToken( WPFestSettings.url )
    return token && token.length > 0
      ?			this.logout()
      :			this.login()
  }

  login() 
  {
    //console.log(this.props.url)
    const style = { width: 50 }
    const register = WPFestSettings.is_register
      ?
      <NavLink
        className="indicator classic"
        to={`${this.props.route}/register`}
      >
        <div className="n1">
          { __("Register") }
        </div>
        <div className="iconnn">
          <img src={employee} alt="" style={style} />
        </div>
      </NavLink>
      :		
      null
    return <>
        {register}
        <Popover
          className=" mt-auto"
          position={Position.RIGHT_TOP}
          isOpen={this.state.isCreateOpen}
          content={(
            <div className="p-4">
              <Login
                onLogin={this.onLogin}
                url={this.props.url}
                festid={this.props.festid}
                hideUrl
              />
            </div>
          )}
        >
          <div className="indicator classic" onClick={this.onCreateToggle}>
            <div className="n1">
              { __("Log in") }
            </div>
            <div className="iconnn">
              <img src={login1} alt="" style={style} />
            </div>
          </div>
        </Popover>
      </> 
  }

	onCreateToggle = () => {
	  this.setState({ isCreateOpen: !this.state.isCreateOpen })
	}
  onMouseLeave = evt =>
    {
        clearTimeout( this.timeOut )
        this.timeOut = setTimeout(() =>
        {
          this.setState({ isLoginOpen: false })
        }, 1000)
    } 
  onLoginOpen = evt =>
    {
        clearTimeout( this.timeOut )
        this.setState({ isLoginOpen: !this.state.isLoginOpen })
    } 
	logout() { 
	  const style = { width: 50 }
	  const style2 = { width: 20, marginLeft: -7, marginTop: 12 }  
  
	  return (
        <>
          <div 
            className="indicator classic"
            onClick={this.onLoginOpen}               
            onMouseLeave={this.onMouseLeave}
          >
              <div className="n1">
                <div 
                  className="display-name-label" 
                  
                >
                  <Tooltip
                    content={ User.display_name } 
                    modifiers={[
                      {
                        name: 'computeStyles',
                        options: {
                          padding: 50,
                          roundOffsets: ({ x, y }) => ({
                            x: Math.round(x + 2),
                            y: Math.round(y + 100),
                          }),
                        },
                      }
                    ]}
                    position={Position.BOTTOM_RIGHT}
                  >
                    { User.display_name }
                  </Tooltip>
                </div>
              </div>
            <div className="iconnn">
              {
                User.avatar
                  ?
                  <div dangerouslySetInnerHTML={{__html:User.avatar}} className="my-avatar-cont"/>
                  :
                  <img 
                    src={usser_img} 
                    alt="" 
                    style={style} 
                  />
              }
            </div>
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={this.state.isLoginOpen}
              content={(
                <div className="w_175 p-0"  onMouseEnter={() =>  clearTimeout( this.timeOut ) }>
                  <div className="btn-group-vertical p-0" role="group">
                    <div className="btn btn-light btn-block p-3 small" onClick={this.onRequireOpen}>
                      {__("Require role")}
                    </div>
                    {
                      User.isRole(["Project_author", "Tutor"])
                        ? 
                        <NavLink
                          to={`${this.props.route}/insert-project`}
                          className="btn btn-light btn-block p-3 small"
                        >
                          {__("Insert new Project")}
                        </NavLink>
                        : 
                        null
                    }
                    {
                      User.isRole([ "administrator", "Track_moderator" ])
                        ? 
                        <>
                          <NavLink
                            to={
                                `${this.props.route}/admin-request-roles`
                            }
                            className="btn btn-light btn-block p-3 small"
                          >
                            {__("Requests from users")}
                          </NavLink> 
                        </> 
                        :									
                        null
                    }
                    {
                      User.isRole("administrator")
                        ? 
                        <> 
                          <NavLink
                            to={
                              moduleExists("pe-wpfest-admin-module") 
                                ?
                                this.getAdminRoute()
                                :
                                `${this.props.route}/admin`
                            }
                            className="btn btn-light btn-block p-3 small"
                          >
                            {__("Admin Festival")}
                          </NavLink>
                        </>                         
                        :									
                        null
                    }
                  </div>
                </div>
              )}
            >
        <div style={{ marginop: 10 }} />
      </Popover>
    </div>
    <div className="indicator classic" onClick={this.onLogout}>
      <div className="n1">
        { __("Log out") }
      </div>
      <div className="iconnn">
        <img src={logout_img} alt="" style={style2} />
      </div>
    </div>
    <Dialog
      isOpen={this.state.isRequireOpen}
      className="little"
      title={__("Require role")}
      onClose={ () => this.setState({ isRequireOpen: !this.state.isRequireOpen }) }
    >
      <div className=" btn-group-vertical p-4">
        <RequestRoleForm
          festid={this.props.festid}
          reqRoles={this.state.reqRoles}
        />
      </div>
    </Dialog>

  </>
	  )
	}
  getAdminRoute = () =>
  {
    const r = getAllRoutes().filter( e => e.component === "CabinetView" )
    return r 
      ? 
      '/' + r[0].route + "/site/" + WPFestSettings.festId 
      : 
      ''
  }
	onRequireOpen = () => {
	  if (!this.state.isRequireOpen) 
    {
	    _fetch(
        "get_fest_command",
	      {  festId: this.props.festid, command: "get_my_roles",  },
	      WPFestSettings.serviceURL,
	      WPFestSettings.getToken(WPFestSettings.url),
	      "wpfa",
	    )
	      .then((_data) => {
          const data = _data.data 
	        //console.log( data.reqRoles )
	        this.setState({
	          reqRoles: data.reqRoles,
	          isRequireOpen: !this.state.isRequireOpen,
	          isLoginOpen: false,
	        })
	      })
	  } else 
      this.setState({ 
        isRequireOpen: !this.state.isRequireOpen, 
        isLoginOpen: false 
      })
	} 

	onLogin = (data, url) => {
	  // console.log( data )
	  if (data.user.token) {
      WPFestSettings.updateToken(WPFestSettings.url, data.user.token)
      User.setData( data )
	    this.setState({ isCreateOpen: false })
	  }
	  this.props.history.replace()
	}

	onLogout = () => {
	  setCookie(`wpfest_token${WPFestSettings.url}`, "")
	  this.setState({ is: !this.state.is })
    WPFestSettings.setToken( '' )  
	  this.props.history.replace ( this.props.location.pathname ) 
    // console.log( this.props.history , this.props.location.pathname )
    actions(SET_TRACK_FILTER_ACTION, DEFAULT_MEMBER_FILTERS)
	}
}
export default compose(
  withRouter,
)(LoginForm)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import { Button, Callout, Intent, Tag } from '@blueprintjs/core' 
import { FormFieldLabels } from './single/ProjectForm'
import Moment from "react-moment"
import _fetch from 'modules/pe-fest-admin-module/api'

const CreateMemberLogs = props => { 
    const [posts, setPosts] = useState(props.data?.posts || [] )
    const onFormFields = data =>
    {

    }
    const onHide = log_id =>
    {
        _fetch( "hide_create_member_log", { id : props.blog_id, log_id } )
        .then(data =>
			{
				console.log( data )
                if(Array.isArray(data.lead?.posts))
                {
                    setPosts( data.lead?.posts )
                }
            })
    }
    const postsList = Array.isArray( props.data?.posts ) && props.data.posts.length > 0
        ?
        posts.map(p => {
            const fields =  p.form_fields 
            //console.log( fields )
            return <tr key={p.ID}>
                <td>
                    <Button 
                        icon="cross" 
                        intent={Intent.DANGER}
                        className="hint hint--right m-2"
                        data-hint={__("Do hide")}
                        onClick={() => onHide( p.ID ) } 
                    />
                </td> 
                <td>
                    <div className="d-flex flex-wrap align-items-center pl-2 pb-2 pt-4" >
						<div className="pr-3" >
							{__("Title")}: <Tag intent={Intent.SUCCESS} className="px-3 ml-1" round>{ p.post_title }</Tag>
						</div> 
						<div className="px-3" >
							{__("Track")}: { Array.isArray(p.tracks) ?  p.tracks.map(track => <Tag key={track.ID} className="px-3 ml-1" round>{ track.post_title }</Tag>) : null}
						</div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center pl-2 pb-2" > 
                        <div className="pr-3" >
							{__("Description")}:
						</div>
                        <div className="small" >
							{ p.post_content }
						</div> 
                    </div> 
                    <FormFieldLabels
                        form_data={ props.data?.options?.form_fields }
                        values={fields}
                        onChange={ onFormFields }
                    /> 
                    <div className="d-flex flex-wrap align-items-center pl-2 pb-4" > 
                        <div className="pr-3" >
							{__("Time")}:
						</div>
                        <Tag className="px-3" round>
                            <Moment locale="ru" format="D MMMM YYYY HH:mm">
                                {new Date(p.time * 1000)}
                            </Moment> 
						</Tag> 
                    </div>
                </td>
                <td>
                    
                </td>  
            </tr>
        })
        :
        <tr >
            <td colSpan={12}>
                <Callout className='w-100 display-6 p-5 text-center'>
                    {__("No elements exists")}
                </Callout>
            </td>            
        </tr>
    return <div>
       <div className='lead mb-4 text-center'>
        {
            __("Log of all attempts to create new Project from Festival's client")
        }
       </div>
       <table className='table table-striped table-hover2 mb-5'>
            <thead style={{ backgroundColor : "#225d39", color: "#eeeeee"}}>
                <tr>
                    <th col="bulk" width="50" style={{ minWidth: 50, maxWidth: 50 }}>
                        
                    </th> 
                    <th col="post_content" width="80%" style={{ maxWidth: "80%" }}>
                        { __( "Fields" ) }
                    </th>
                    <th col="boo" width="20%" style={{ minWidth: "40%", maxWidth: "20%" }}>
                        { __( "Or" ) }
                    </th>
                </tr>
            </thead>
            <tbody>
                { postsList }
            </tbody>            
       </table>
    </div>
}

CreateMemberLogs.propTypes = {}
export default CreateMemberLogs

import React, {Fragment,Component} from "react"; 
import { __ } from "react-pe-utilities";
import { Icon, Tag, Intent, Button, Collapse } from "@blueprintjs/core";
import Categories from "./Categories";
import CriteryForm from "./single/CriteryForm";
import _fetch from "modules/pe-fest-admin-module/api"; 
import FieldInput from "react-pe-scalars/dist";

export default class Criteries extends Categories
{	
	type = "critery"
	componentWillMount()
	{
		_fetch("lead_fest_ganres", {id: this.props.blog_id})
			.then(data =>
				{ 
					//console.log(data)
					let fmru_ganres = data.lead.post || []
					if(!data.isError)
					{					
						_fetch("lead_fest_milestones", {id: this.props.blog_id})
							.then(data =>
							{ 
								let fmru_milestones = data.lead?.post || []
								if(!data.isError)
								{
									_fetch("lead_fest_cats", {id:this.props.blog_id} ) 
										.then(data =>
										{		
											// console.log( fmru_milestones );
											if(!data.isError)
											{
												this.init({
													form_component	: CriteryForm,
													theadColor		: "#416348",	
													meta			: { 
														fmru_category: {}, 
														fmru_categories: data.lead.post,
														fmru_ganres: fmru_ganres,
														fmru_milestones: fmru_milestones
													}
												})
												this.setState({
													fiter_categories_filter: data.lead.post.map(f => f.id),
													fmru_milestones_filter : fmru_milestones.map(f => f.id)
												})
											}
											else
											{
												
											}
										})
								}
							})
					}
				})
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color' );
		if(this.props.options.is_independent_grade_criteries )
		{
			rw.splice( 3, 0,
				[
					'max_rating',	
					<div style={{minWidth:70, display:"flex", justifyContent:"center"}} key={"max_rating"}>
						{__("Max raiting")}
					</div>, 
					70
				]
			); 
		}
		rw.splice( 3, 0,
			[
				'milestones',	
				<div style={{minWidth:170, display:"flex", justifyContent:"center"}} key={"milestone"}>
					{__("Milestones")}
				</div>, 
				170
			]
		); 
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "milestones":
				const fmru_milestone = elem.fmru_milestone?.map(e => <Tag 
					interactive
					round
					minimal
					key={e.id}
				>
					{e.post_title}
				</Tag>);
				txt = <div className="d-flex flex-centered">{fmru_milestone}</div>
				break
			case "max_rating":
				txt = <div className="d-flex flex-centered">{elem.max_rating}</div>
				break
		}
		return txt;
	}
	getPostTitle = elem =>
	{
		const tags = elem.fmru_category?.map(e => <Tag
					style={{backgroundColor : e.color}}
					interactive={true}
					key={e.id}
				>
					{e.post_title}
				</Tag>);
		
		return <Fragment>
			<div>{elem.post_title}</div>
			<div>{tags}</div>			
		</Fragment>
	}
	
	filterMe = ( element, i ) =>
	{ 
		if(this.state.searchString)
		{ 
			return element.display_name.toLowerCase().indexOf( this.state.searchString.toLowerCase() ) >= 0
		}
		return (
			this.state.fmru_milestones_filter?.filter( milestone => {
				return element.fmru_milestone?.filter(r => r.id === milestone).length > 0
			}).length > 0 
			|| element.fmru_milestone.length === 0
			|| !this.state.fmru_milestones_filter 
		)
		&&
		(
			this.state.fiter_categories_filter?.filter( category => {
				return element.fmru_category?.filter(r => r.id === category).length > 0
			}).length > 0 
			|| element.fmru_category.length === 0
			|| !this.state.fiter_categories_filter 
		)  
	}
	filters = () =>
	{
		return <Button
			minimal={!this.state.isFilterOpen}
			intent={Intent.SUCCESS}
			onClick={this.onFilterOpen}
		>
			{__("Filters")}
		</Button>
	}
	onFilterOpen = () =>
	{
		this.setState({isFilterOpen:!this.state.isFilterOpen})
	}
	preTable2 = () =>
	{
		// console.log( this.state.fmru_milestones_filter )
		return <Collapse isOpen={this.state.isFilterOpen} className="bg-secondary-super-light">
			<div className="py-1 border-bottom border-secondary">
			{
				<FieldInput
					type="tags"
					visualization="festFormsFields" 
					multiple 
					editable
					value={this.state.fiter_categories_filter}
					values = { this.meta.fmru_categories?.map(cat => {
						return {_id:cat.id, title: cat.post_title}
					})} 
					title={__("Categories")}
					onChange={this.onCategoriesFilter}
                    on={this.onCategoriesFilter}
				/>
				
			}
			</div>
			<div className="py-1 border-bottom border-secondary">
			{
				<FieldInput
					type="tags"
					visualization="festFormsFields" 
					multiple 
					editable
					value={this.state.fmru_milestones_filter}
					values = { this.meta.fmru_milestones?.map(milestone => {
						return {_id:milestone.id, title: milestone.post_title}
					})} 
					title={__("Milestones")}
					onChange={this.onMilestoneFilter}
                    on={this.onMilestoneFilter}
				/>
				
			}
			</div>
		</Collapse>
	}
	onCategoriesFilter = fiter_categories =>
	{
		this.setState({ fiter_categories_filter : fiter_categories })
	}
	onMilestoneFilter = fmru_milestones =>
	{
		this.setState({ fmru_milestones_filter : fmru_milestones })
	}
}
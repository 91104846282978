import React, { Component } from "react"
import { __ } from "react-pe-utilities" 
import User from "./utilities/User"
 
const UserDescription = props =>
{
  const user_descr = User.roles.map((elem) => (
    <div
      className="role_descr "
      key={`roledescr_${elem}`}
      dangerouslySetInnerHTML={{ __html: __(elem) }}
    />
  ))
  return <div className=" d-flex order-3 mx-md-0 mx-auto">
    { user_descr }
  </div>
}
export default UserDescription

import React, { Component } from "react"
import { compose } from "recompose"
import { withRouter, } from "react-router"
import _fetch from "."
import { initArea, __ } from "react-pe-utilities"
//import TextEditor from "../../../../layouts/utilities/TextEditor"
import {TextEditor} from "react-pe-useful" 
import {MediaChooser} from "react-pe-useful" 
import GanreCheckBoxList from "./GanreCheckBoxList"
import WPFestSettings from "./utilities/WPFestSettings"
import FieldInput from "react-pe-scalars/dist"
import { withFestStore } from "modules/pe-fest-module/data/store"
import isEmail from "states/utils/isEmail"

class FestMemberSetting extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      ...props,
      img: this.props.img,
      img_name: this.props.img_name,
      isEdited: this.props.isNew,
      isEmailCorrect:false,      
      tutorName: this.props.owners?.tutor?.display_name,
      tutor_email: this.props.owners?.tutor?.user_email
    }
  }

  componentWillUnmount() {
    clearTimeout(this.content)
    clearTimeout(this.soc)
  }  
  

  render() {
    //console.log( WPFestSettings.getToken( WPFestSettings.url ) );
    const socs = [
      { name: "vk_url", t: "vk" },
      { name: "youtube_url", t: "youtube" },
      { name: "ok_url", t: "odnoklassniki" },
    ]
      .map((e, i) => (
        <div className="input-group mb-1" key={i}>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={`fab lead fa-${e.t}`} />
            </span>
          </div>
          <input
            name={e.name}
            value={this.state[e.name]}
            onChange={this.onSocs}
            className="input dark form-control"
          />
        </div>
      ))
    const content = this.state.isEdited
      ? 
      //<TextEditor onChange={this.onContent} text={this.state.content} />
      <textarea 
        className="w-100 input dark m-0 " 
        rows={10}  
        onChange={ evt => this.onContent(evt.currentTarget.value )}
        value={this.state.content}
      >
        {this.state.content}
      </textarea>
      :
      <>
        <div
          className=" mcontent"
          dangerouslySetInnerHTML={{ __html: this.state.content }}
        />
        <div className=" px-4">
          <div className="btn btn-primary " onClick={this.onEdit}>
            {__("Edit")}
          </div>
        </div>
      </>
    return (
      <div className="row">
        <div className="col-12">
          <div className="row data-list">
            <div className="col-md-4 col-sm-4 my-auto">
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='require-sign mr-4 h-100'>
                  <div className='d-flex user-select-none descr text-danger'>
                    {__("Required field")}
                  </div>  
                </div> 
                <div className="title text-danger">
                  {__("Project title")} :
                </div>
              </div>              
            </div>
            <div className="col-md-8 col-sm-8 pt-2">
              <input
                value={this.state.title || ""}
                onChange={this.onTitle}
                className="input dark form-control" 
              />
            </div>
          </div>
          <div className="row data-list">
            <div className="col-md-4 col-sm-4 title  my-auto text-right">
              {__("Thumbnail")}:
            </div>
            <div className="col-md-8 col-sm-8 pt-2 pb-3"> 
              <MediaChooser
                prefix="_post_thumbnail_"
                url={this.state.img}
                id=""
                ID=""
                hideLib={true}
                isURLHide={true}
                height={200}
                width={300}
                padding={6}
                tab="ld"
                onChange={this.onThumbnail}
              />
            </div>
          </div>
          <div className="row data-list ">
            <div className="col-md-4 col-sm-4 my-auto2">
              <div className='d-flex justify-content-end align-items-center w-100'>                 
                <div className="title ">
                  {__("Description")}:
                </div>
              </div>  
            </div>
            <div className="col-md-8 col-sm-8 pl-3">
              {content}
            </div>
          </div>
          {
            this.props.store.options.fmru_evlbl_roles.filter(role => role === "Tutor").length > 0
              ?
              <>
                <div className="row data-list">
                  <div className="col-md-4 col-sm-4 my-auto">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <div className='require-sign mr-4 h-100'>
                        <div className='d-flex user-select-none descr text-danger'>
                          {__("Required field")}
                        </div>  
                      </div> 
                      <div>
                        <div className="title text-danger">
                          {__("Tutor name")} :
                        </div>                        
                        <div className='descr'>
                            { __( "" ) }
                        </div> 
                      </div>
                      
                    </div>              
                  </div>
                  <div className="col-md-8 col-sm-8 pt-2">
                    <input
                      disabled={!this.props.isNew}
                      value={this.state.tutorName || ""}
                      onChange={this.onTutorName}
                      className="input dark form-control" 
                    />
                  </div>
                </div>
                <div className="row data-list">
                  <div className="col-md-4 col-sm-4 my-auto">
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <div className='require-sign mr-4 h-100'>
                        <div className='d-flex user-select-none descr text-danger'>
                          {__("Required field")}
                        </div>  
                      </div> 
                      <div className="text-right">
                        <div className="title text-danger">
                          {__("Tutor email")} :
                        </div> 
                      </div>                      
                    </div>                       
                    <div className='descr text-right'>
                        { __( "If this is a new email in the system, a new User will be created" ) }
                    </div>              
                  </div>
                  <div className="col-md-8 col-sm-8 pt-2 position-relative">
                    <input
                      value={this.state.tutor_email || ""}
                      disabled={!this.props.isNew}
                      onChange={this.onTutorEmail}
                      className={`input dark form-control ${this.state.isEmailCorrect ? "opacity_5" : ""} `}
                    />
                    <div className="small text-danger position-absolute bottom right mx-4 my-2">
                      { __( !this.state.isEmailCorrect ?  "" : "uncorrect e-mail" ) }
                    </div>
                  </div>
                </div>
              </>
              :
              null
          } 
          {
            Array.isArray(this.props.tracks) && this.props.tracks.length > 0 
              ?
              <div className="row data-list">
                <div className="col-md-4 col-sm-4 my-auto">
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <div className='require-sign mr-4 h-100'>
                      <div className='d-flex user-select-none descr text-danger'>
                        {__("Required field")}
                      </div>  
                    </div> 
                    <div className="text-right">
                      <div className="title text-danger">
                        {__("Track")} :
                      </div> 
                    </div>                      
                  </div> 
                </div> 
                <div className="col-md-8 col-sm-8 p-2">
                  <FieldInput
                    visualization="festFormsFields" 
                    type={ "tags" }
                    values={ this.props.store.tracks?.map(ms => {
                      return {
                        _id : ms.id,
                        title: ms.post_title,
                        disabled: ms.is_closed,
                        hint: __("Resister closed!")
                      }
                    }) }
                    className={` w-100 input dark form-control `}
                    vertical
                    editable={ this.props.isNew }
                    value={ this.state.track?.ID } 
                    on={this.onTrack}
                    onChange={this.onTrack}
                  /> 
                </div>
              </div>  
              :
              null
          }
          {
            WPFestSettings.is_ganres
              ?
              <div className="row data-list">
                <div className="col-md-4 col-sm-4 test-descr-title  my-auto">
                  <span className="ml-3">
                    {__("Ganres:")}
                  </span>
                </div>
                <div className="col-md-8 col-sm-8 p-2 lead">
                  <GanreCheckBoxList
                    ganres={WPFestSettings.ganres?.map((e, i) => {
                      e.check = this.props.isNew
                        ? 
                        0
                        : 
                        this.state.ganres.filter((ee) => ee.id == e.id).length > 0
                          ? 
                          1
                          : 
                          0
                      return e
                    })}
                    ignore={[]}
                    name="my_ganres"
                    onGanre={this.onGanre}
                  />
                </div>
              </div>
              :
              null
          }
           {
                initArea(
                    "fest-register-form",
                    {
                      ...this.props,
                      form_fields : this.props.form_fields,
                      role : "Tutor",
                      onExtendFetchParams : this.onExtendFetchParams
                    },
                )
            }
          {
            this.props.isNew
              ? 
              null
              :
              <div className="row data-list">
                <div className="col-md-4 col-sm-4 test-descr-title  my-auto">
                  {__("Valuations:")}
                </div>
                <div 
                  className="col-md-8 col-sm-8 p-2 lead" 
                  dangerouslySetInnerHTML={{__html : this.state.rait}}
                />
              </div>
          }
        </div>
      </div>
    )
  }
  onExtendFetchParams = (data, field, n, i) =>
  {
    //console.log( data, field, n, i )
    let form_fields = [...this.state.form_fields]
    form_fields[n] = [...( form_fields[n] || [] ) ]
    form_fields[n][i] = data
    console.log( form_fields )
    //this.setState({ form_fields })
    this.fetch("form_fields", form_fields)
  }
  onTrack = track =>
  {
    // console.log(track)
    const TrackObj = this.props.tracks?.filter(t => t.ID === track)[0]
    this.setState({track : TrackObj })
    this.fetch("fmru_track", [ track ])
  }
  onThumbnail = (value, file) => {
    this.setState({
      img: value,
      img_name: file.name,
    })
    if (!this.props.isNew) {
      _fetch(
        "get_fest_command",
        {
          
          festId: this.props.festid,
          id: this.props.id,
          command: "update_project_fields",
          fields: [
            { field: "thumbnail", value },
            { field: "thumbnail_name", value: file.name },
          ],

        },
        WPFestSettings.serviceURL,
        WPFestSettings.getToken( WPFestSettings.url ),
        "wpfa",
      )
        .then((_data) => {
          // console.log( data );
        })
    } else if (this.props.ons) {
      this.props.ons({
        id: this.props.id, 
        fields: [
          { field: "thumbnail", value },
          { field: "thumbnail_name", value: file.name },
        ],

      })
    }
  }

  onGanre = (ganres) => {
    if (this.props.onGanre) this.props.onGanre(ganres)
    this.fetch("fmru_group_player", ganres)
  }

  onSocs = (evt) => {
    const state = {}
    const name = evt.currentTarget.getAttribute("name")
    const { value } = evt.currentTarget
    state[name] = value
    this.setState(state)
    this.soc = setTimeout(() => {
      clearTimeout(this.soc)
      this.fetch(name, value)
    }, 2000)
  }

  onContent = (data) => {
    this.setState({ content: data })
    this.content = setTimeout(() => {
      clearTimeout(this.content)
      this.fetch("post_content", data)
    }, 200)
  }

  onEdit = () => {
    this.setState({ isEdited: !this.state.isEdited })
  }

  onTitle = (evt) => { 
    const title = evt.currentTarget.value
    this.setState({ title })
    this.fetch( "post_title", title )
  }
  onTutorName = (evt) => { 
    const tutorName = evt.currentTarget.value
    
    this.setState({ tutorName })
    this.fetch( "tutor_name", tutorName )
  }
  onTutorEmail = (evt) => { 
    const tutor_email = evt.currentTarget.value
    this.setState({ tutor_email, isEmailCorrect: !isEmail( tutor_email ) })
    this.fetch( "tutor_email", tutor_email )
  }

  fetch = (field, value) => {
    if (!this.props.isNew) 
    {
      //this.props.on({ id: this.props.id, field, value })
      _fetch(
        "get_fest_command",
        { id: this.props.id, field, value, command: "update_project_field", festId:WPFestSettings.festId, },
        WPFestSettings.serviceURL,
        WPFestSettings.getToken( WPFestSettings.url ),
        "wpfa",
      )
        .then((data) => {
          console.log( data );
        })
    } 
    else if (this.props.on) 
    {
      // console.log( field, value )
      this.props.on({ id: this.props.id, field, value })
    }
  }
}
export default compose(
  withRouter,
  withFestStore
)(FestMemberSetting)

import React, {Component, Fragment} from "react"; 

import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, NumericInput
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";
import FieldInput from "react-pe-scalars/dist";
import { MediaChooser } from "react-pe-useful/dist";
import CabinetView from "modules/pe-fest-admin-module/views/CabinetView";
 
export default class UserForm extends Component
{
	state = {
		display_name	: this.props.display_name,
		user_login		: this.props.user_login,
		avatar			: CabinetView.serverURL + "/avatars/avatar" + this.props.ID + ".jpg" || "",
		user_email		: this.props.user_email,
		user_descr		: this.props.user_descr,
		roles			: this.props.roles && this.props.roles.length 
			? this.props.roles.map(elem => elem.id)
			: [],
		role			: this.props.role,
		is_blocked		: this.props.is_blocked
	}
	onValue = (value, field) => {
		console.log(field, value );
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.staet}
		state[field] = value
		this.setState( state );
	}
	onAvatar = (value, file) => {
		console.log( file );
		console.log( value );
		this.props.onChange("avatar", value, this.props.ID);
		let state = { ...this.state }
		state.avatar = value
		this.setState( state );
	}
	display_name = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("display_name", txt, this.props.ID);
		this.setState({display_name : txt});
	}
	onRole = (evt) =>
	{
		const checked = evt.currentTarget.checked;
		const rol = evt.currentTarget.value;
		let roles = this.state.roles.slice(0);
		
		if(checked)
		{
			roles.push(rol);
		}
		else
		{
			roles = roles.filter(e => e != rol);
		}
		console.log(roles);
		this.setState({roles});
	}
	onSave = () =>
	{
		console.log( this.state );
		let state = {...this.state}
		state.names = [ this.state.display_name ]
		this.props.onSave( state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	} 
	onBan = () =>
	{
		console.log( this.state.is_blocked )
		this.setState( { is_blocked: !this.state.is_blocked } );

	}
	render()
	{		
		// console.log(this.props);
		const del_btn = this.props.isNew 
			?
			null 
			: 
			<>
				<Popover
					position={Position.TOP_LEFT}
					content={
						<div className="square p-3">
							<div>
								<p>
									{__("Are you realy want delete?")}
								</p>
								<ButtonGroup className="p-2 tex-right">
									<Button
										intent={Intent.DANGER}
										text={__("Yes")}
										icon="cross"
										onClick={this.onDelete}
									/>
								</ButtonGroup>
							</div>							
						</div>					
					}
				>
					<Button
						text={__("Delete")}
						icon="cross"
					/>
				</Popover>
				<Button
					text={__("Force delete from all festivals")}
					intent={Intent.DANGER}
					icon="cross"
					onClick={ () => this.props.onForceDelete (this.props.ID) }
				/>
				<Button
					text={__( this.state.is_blocked ? "Unbanned" : "Banned")}
					intent={this.state.is_blocked ? Intent.SUCCESS : Intent.WARNING}
					icon="blocked-person"
					onClick={ this.onBan }
				/>
			</>	
		return <Fragment>  
			<FieldInput
				type="string"
				description={ __( "insert title by any symbols" ) }
				title={ __( "Display name" )}
				field={ 'display_name' }
				value={ this.state.display_name }
				onChange={ this.onValue }
			/>
			<div className="row dat strob01 " row_data="avatar">
				<div className="col-md-3  layout-label">
					{ __( "Avatar" ) } 
				</div>
				<div className="col-md-9 layout-data py-3 ">
					<MediaChooser
						prefix="_post_thumbnail_"
						url={this.state.avatar}
						accept=".jpg,.jpeg"
						id=""
						ID=""
						hideLib={true}
						isURLHide={true}
						height={150} 
						padding={15}
						tab="ld"
						onChange={this.onAvatar}
					/>
				</div>
			</div> 
			<FieldInput
				type="string"
				description={ __( "this is unchangeble field" ) }
				editable={false}
				title={ __( "Login" )}
				field={ 'user_login' }
				value={ this.state.user_login }
				onChange={ this.onValue }
			/> 
			<FieldInput
				type="email" 
				editable 
				title={ __( "E-mail" )}
				field={ 'user_email' }
				value={ this.state.user_email }
				onChange={ this.onValue }
			/> 
			<FieldInput
				type="html" 
				editable 
				title={ __( "Description" )}
				field={ 'user_descr' }
				value={ this.state.user_descr }
				onChange={ this.onValue }
			/> 
			<FieldInput
				type="checkbox"
				description={ __( "Choose one or more Roles" ) } 
				title={ __( "Roles" )}
				field={ 'role' }
				value={ this.state.role }
				values={ this.props.meta.roles.map(elem => {
					return {
						_id: 	elem.id,
						title :	elem.post_title
					}	
				}) }
				onChange={ this.onValue }
			/>
			<ButtonGroup className="p-2 tex-right">
				<Button 
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}
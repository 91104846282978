import React from 'react'
import RussionRegionList from 'modules/pe-fest-admin-module/data/RussianRegionList'
import PropTypes from 'prop-types'
import Tags from './Tags'

const RussianRegionsTags = props => 
{
    return <Tags
        value={ props.value }
        values={ RussionRegionList().map(r => ({_id:r, title:r, color:"#000"})) }
        field={ props.field }
        multiple={ props.multiple }
        onChange={ props.onChange }
    />
}

RussianRegionsTags.propTypes = {}

export default RussianRegionsTags
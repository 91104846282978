import React, {Component, Fragment, useState} from "react";  

import {   
	Intent, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, 
	Tag
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";  
import FieldInput from "react-pe-scalars/dist";
import ProjectUserForm from "./forms/ProjectUserForm";
 
export default class HoneycombsForm extends Component
{
	state = {
		post_title 		: this.props.post_title,
		post_content	: this.props.post_content,
		thumbnail		: this.props.thumbnail, 
		order 			: parseInt( this.props.isNew ? this.props.meta.order : this.props.order) 
	} 
	onValue = (value, field, i, ii) => {
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state[field] = value
		console.log( value, field, i, ii )
		console.log( state )
		this.setState( state );
	} 
	title = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("post_title", txt, this.props.ID);
		this.setState({post_title : txt});
	} 
	onOrder = n => 
	{
		//console.log( n );
		this.setState({order : n});
	} 
	onSave = () =>
	{
		let state = {...this.state} 
		console.log( state );
		this.props.onSave( state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	} 
	render()
	{		
		// console.log(this.state.fmru_honeycombs.post_title );
		const del_btn = this.props.isNew 
			? 
			null 
			: 
			<Popover
				position={Position.TOP_LEFT}
				content={
					<div className="square p-3">
						<div>
							<p>
								{__("Are you realy want delete?")}
							</p>
							<ButtonGroup className="p-2 tex-right">
								<Button
									intent={Intent.DANGER}
									text={__("Yes")}
									icon="cross"
									onClick={this.onDelete}
								/>
							</ButtonGroup>
						</div>							
					</div>
					
				}
			>
				<Button
					text={__("Delete")}
					icon="cross"
				/>
			</Popover>  
		return <Fragment>
			<FieldInput
				type="string"
				description={ __( "insert title by any symbols" ) }
				title={ __( "Title" )}
				field={ 'post_title' }
				value={ this.state.post_title }
				onChange={ this.onValue }
			/>
			<FieldInput
				type="number"
				description={ __( "Position this Prjoject's in list" ) }
				title={ __( "Order" )}
				field={ 'order' }
				value={ this.state.order }
				onChange={ this.onValue }
			/>  
			
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}
import { create } from "zustand";
import React from "react" 
import User from "../views/wpfestREST/utilities/User";
export const DEFAULT_MEMBER_FILTERS = {
    honeycombs : [-1],
    tracks : [ -1],
    ganres: [-1],
    s:-1
}
export const useFestStore = create((set) => ({
    fests: [],
    setFests: ( fests ) => set( state => ({ fests }) ),
    fest : -1,
    setFest : fest => set( state => ( { fest : { ...state.fest, ...fest } } ) ),
    user: {},
    setUser: () => set( state => ({ user: state.user}) ),
    memberFilters: DEFAULT_MEMBER_FILTERS,
    setMemberFilters: filter => set(state => {
        const f = {
            memberFilters : {
                ...state.memberFilters,
                 ...filter
            }
        } 
        return f
    }),
    allMembers:[],
    setAllMembers: (allMembers) => set( state => { 
        return allMembers 
    }),
    members:[],
    setMembers: (members) => set( state => { 
        return members 
    }),
    allMyMembers:[],
    setAllMyMembers: (allMyMembers) => set( state => { 
        return allMyMembers 
    }),
    myMembers:[],
    setMyMembers: (myMembers) => set( state => { 
        return myMembers 
    }),
    honeycombs : [],
    setHoneycombs : honeycombs => set(state => {
        return { honeycombs }
    }),
    myHoneycombs : [],
    setMyHoneycombs : myHoneycombs => set(state => {
        return myHoneycombs
    }),
    criteries: [],
    tracks: [],
    setTracks: tracks => set(state => {
        return Array.isArray(tracks.tracks) ?  tracks : {tracks:[]}
    }),
    myTracks: [], 
    setMyTracks: myTracks => set(state => { 
        //console.log(myTracks)
        return Array.isArray( myTracks ) ?  {myTracks} : {myTracks:[]}
    }),
    allOwners: [], // list of Tutors or Project_aithor
    setAlOwners: all_owners => set(state =>
    {
        return { allOwners: all_owners }
    }),
    options: [],
    setOptions: options => set(state => {  
        return { options: { ...state.options,  ...options } }
    }),
    ganres: [],
    setGanres: ganres => set(state => {
        //console.log( ganres )
        return { ganres }
    }),
    milestones: [],
    setMilestones: milestones => set(state => {
        //console.log( ganres )
        return Array.isArray( milestones ) ?  {milestones} : {milestones:[]}
    }),
    statistics: {},
    setStatistics: statistics => set( state => { 
        return { statistics: statistics }
    }),
    searchString: "",
    setSearchString: searchString => set(state => {
        return { searchString }
    })
}))

export const withFestStore = ChildComponent => props => {
    const store = useFestStore()    
    return <ChildComponent {...props} store={store} />
}
 
/*
// IMember props
    id              : Number
    title           : String
    content         : String
    order           : Number
    diary           : [IDiary]
    form_field      : [IFormField]
    ganres          : [IGanre]
    img             : media
    track           : ITrack
    average_raiting : float
    rating          : IRating
    honeycombs      : IHoneycomds

// IRating props
    categories      : [ICategoryRating]
    orphans         : [ICriteryRating]
    uniq            : [ICriteryRating]

// ICategoryRating
    rating          : [ICriteryRating]
    category_id     : Number

// ICriteryRating props
    raiting         : [IExpertRating]
    critery_id      : Number

// IExpertRating props
    id              : Number
    raiting         : Number
    expert_id       : Number
    critery_id      : Number
    member_id       : Number
    desription      : string
*/
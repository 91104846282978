import React from "react"
import { compose } from "recompose"
import { Route, Switch, withRouter, } from "react-router" 
import _fetch from "."
import FestMemberData from "./FestMemberData"
import FestMemberRaiting from "./core/rating/FestMemberRaiting"
import FestMemberTeam from "./FestMemberTeam"
import FestMemberDuary from "./FestMemberDuary"
import TalkRoom from "./TalkRoom"
import MemberAdmin from "./MemberAdmin"
import { __ } from "react-pe-utilities"
import { initArea } from  "react-pe-utilities" 
import WPFestSettings from "./utilities/WPFestSettings"
import User from "./utilities/User"
import FestComponent from "./FestComponent" 
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import FestMemberMilestones from "./FestMemberMilestones"
import { withFestStore } from "modules/pe-fest-module/data/store"
import actions, { LOAD_MEMBER_ACTION } from "modules/pe-fest-module/data/actions"
import { Callout, Intent } from "@blueprintjs/core"
import ExpertDescriptions from "./ExpertDescriptions"

class FestMember extends FestComponent  
{
  ticker        = 1
  fetchCommand  = "fmru_player"
  fetchArgs     = {id: this.props.match.params.memberId}
  header_type   = "member"

  setStartState = () => {
    return {
      categories: [],
      max_raiting: 1,
      isEditForms: false,
      isLeftClosed: true,// window.innerWidth < 740,
      
    }    
  }
  setFirstUpdate = ()=>
  {
    // console.log(this.state.owners?.leader?.id == User.id)
    this.setState({
      is_owner : User.id && ( this.state.owners?.leader?.id === User.id  || this.state.owners?.tutor?.id === User.id),
      is_member : User.id
        && ( this.state.owners?.leader?.id === User.id 
          || this.state.owners?.tutor?.id === User.id 
          || this.state.owners?.member_0?.id === User.id 
          || this.state.owners?.member_1?.id === User.id 
          || this.state.owners?.member_2?.id === User.id 
          || this.state.owners?.member_3?.id === User.id 
          || this.state.owners?.member_4?.id === User.id 
          || this.state.owners?.member_5?.id === User.id 
          || this.state.owners?.member_6?.id === User.id 
          || this.state.owners?.member_7?.id === User.id 
          || this.state.owners?.member_8?.id === User.id 
          || this.state.owners?.member_9?.id === User.id )
    }) 
  }
  onComponentDidMount = () =>
  {  
    // return
    // очищаем данные интерактивных слайдеров
    // сссылки на компоненты ExpertSlider
    WPFestSettings.criteryComponents = []
    // ссылки на данные рейтингов у критриев
    WPFestSettings.criteriyObjects = []
    // получаем урл нашего Фестиваля
    // TODO - вынести в надкласс. Получать из location.params при переходе по NavLink
    // TODO - не работает с еденичной инсталляцией WP-Fest.plugin

    // Обновляем для получения актуальных данных
    this.ticker = setInterval(() => {
      //WPFestSettings.criteryComponents = []
      //WPFestSettings.criteriyObjects = []
      const split = this.props.location.pathname.split("/")
      // console.log( this.getMemberData() )
      if( document.visibilityState !== "visible" || split[split.length - 1 ] === "dev" || !this.getMemberData() )  return
      _fetch(
        "get_fest_command",
        { 
          //...this.fetchArgs, 
          member_id : this.props.match.params.memberId,
          version: this.getMemberData().v,
          command: "get_member_version",//this.fetchCommand, 
          festId: WPFestSettings.festId 
        },
        WPFestSettings.serviceURL,
        WPFestSettings.getToken( WPFestSettings.url ),
        "wpfa",
      )
        .then((_data) => {
          // console.log( data );
          const data = _data.data 
          if(data)
          { 
            actions(LOAD_MEMBER_ACTION, data) 
          } 
        })
      
    }, 5000)
  }
  componentWillUnmount() {
    clearInterval(this.ticker)
    WPFestSettings.criteryComponents = []
    WPFestSettings.criteriyObjects = []
  }
  getMemberData = () =>
  { 
    return Array.isArray(this.props.store.members) // && typeof this.props.match.params.memberId === "Number" 
      ?
      this.props.store.members[this.props.match.params.memberId]
      :
      {} 
  }

  /*
  onGetFestData = data =>
  {
    data.categories.forEach( category => {
      category.criteries.forEach( critery =>
      {
        WPFestSettings.add_critery( critery )
      })
    })
    data.orphans.forEach( critery =>
    {
      WPFestSettings.add_critery( critery )
    }) 
    this.setState({ ...data.member })
  } 
  */

  onEditFormToggle = isEditForms =>
  {
    this.setState({isEditForms:isEditForms })
  }

  addRender = () =>
  {
    const { memberId} = this.props.match.params 
    const {festID} = this.props 
    const {is_owner, is_member } = this.state  
    const singleMember = this.props.store.allMembers[ memberId ]
    // console.log( singleMember )
    
    const options = this.props.store.options 
    return this.getMemberData()
      ?
      <>   
        <Switch>
          <Route
            exact
            path={ `${this.props.route}/member/${ memberId}`}
          >
            <>
              <FestMemberData
                //{ ...this.state }
                { ...singleMember }
                options = { options } 
                memberId={memberId}
                is_owner={is_owner}
                is_member={is_member}
                user={User}
                route={this.props.route}
                festid={festID}
                onEditFormToggle={this.onEditFormToggle}
                isEditForms={this.state.isEditForms || false}
              />
              {
                this.state.milestones?.length > 0
                  ?
                  <FestMemberMilestones   
                    //{ ...this.state }
                    { ...singleMember }
                    options = { options } 
                    is_owner={is_owner && User.isRole([ "Tutor", "Project_author" ])}
                    is_member={is_member && User.isRole("Project_member")}
                    user={User}
                    festid={festID}
                    onEditFormToggle={this.onEditFormToggle}
                    isEditForms={this.state.isEditForms || false}
                  />
                  :
                  <FestMemberRaiting        
                    // { ...this.state }
                    { ...singleMember }
                    options = { options } 
                    noMilestones
                    is_owner={is_owner}
                    is_member={is_member}
                    user={User}
                    festid={festID} 
                    isEditForms={this.state.isEditForms || false}
                  /> 
              } 
              <ExpertDescriptions
                { ...singleMember }
                data={ singleMember.expert_descr || [] }
                user={User}
                member_id={memberId}
                denabled={ User.isRole(["Expert", "Track_moderator" ]) && this.props.expert_descr?.length < 1 }
              />
            </>
          </Route>  
          <Route
            exact
            path={`${this.props.route}/member/${ memberId}/team`}
          >
            <FestMemberTeam          
              // { ...this.state }
              { ...singleMember }
              options = { options } 
              is_owner={is_owner}
              is_member={is_member}
              user={User}
              festid={festID}
            />
          </Route>
          {
            this.state.is_diaries
              ? (
                <Route
                  exact
                  path={`${this.props.route}/member/${ memberId}/diary`}
                >
                  <FestMemberDuary           
                    // { ...this.state }
                    { ...singleMember }
                    options = { options } 
                    user={User}
                    is_owner={is_owner}
                    is_member={is_member}
                    festid={festID}
                  />
                </Route>
              )
              : null
          }
          {
            is_member
              ? (
                <Route
                  exact
                  path={`${this.props.route}/member/${ memberId}/jitsi`}
                >
                  <TalkRoom               
                    //{ ...this.state }
                    { ...singleMember }
                    options = { options } 
                    user={User}
                    is_owner={is_owner}
                    is_member={is_member}
                  />
                </Route>
              )
              : null
          }
          <Route
            exact
            path={`${this.props.route}/member/${memberId}/dev`}
          >
            <MemberAdmin
              is_owner={is_owner}
              is_member={is_member}
              {...this.props}         
              //{ ...this.state }            
              { ...singleMember }
              options = { options } 
              festid={festID}
              preroute={`${this.props.route}/member/${ memberId}`}
            />
          </Route>
          
          {
            initArea(
              "fest-member-route",
              {
                ...this.props,      
                //...this.state,              
                ...singleMember,
                options, 
                festid:festID,
                preroute: `${this.props.route}/member/${ memberId}`,

              },
            )
          }
        </Switch>
      </>
      :
      <Callout className="p-5 text-center display-5" intent={Intent.DANGER}>
        {__("No element exists")}
      </Callout>
  }

  onLeftSwitch = () => {
    this.setState({ isLeftClosed: !this.state.isLeftClosed })
    this.props.onLeftSwitch(!this.state.isLeftClosed)
  }
}
export default compose(
  withFestStore,
  withRouter,
)(FestMember)



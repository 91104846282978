import React, {Fragment,Component} from "react"; 
import { Icon, Tag, Intent, Tooltip, Card, Position, Popover, Button } from "@blueprintjs/core";
import Categories from "./Categories";
import TrackForm from "./single/TrackForm"; 
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-fest-admin-module/api";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";

export default class Tracks extends Categories
{
	type = "track"
	componentWillMount()
	{ 
		IndexDB.find( 
				"peFestAdmin_mySites",
				"sites",
				"sites",
				this.props.blog_id
			)
				.then( sites =>
				{
					let users = sites?.data?.users
						?
						sites.data.users
						:
						[] 
					this.init({
						form_component	: TrackForm,
						theadColor		: "#ab2da1",
						meta			: { 
							fmru_users		: users, 
							festivalOptions	: this.props.options,
							content			: "",
							order 			: this.state.post.length > 0 
								? 
								parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
								: 
								0
						}
					})
				})
									
				_fetch("lead_fest_users", {id: this.props.blog_id})
					.then(data1 =>
					{
						let fmru_users 		= data1.lead.post 
						console.log(fmru_users)
						this.init({
							form_component	: TrackForm,
							theadColor		: "#ab2da1",
							meta			: {      
								fmru_users		: fmru_users, 
								festivalOptions	: this.props.options,
								content			: "",
								moderator_id	: -1,
								moderator_id2	: -1,
								order 			: this.state.post.length > 0 
									? 
									parseInt(this.state.post[this.state.post?.length - 1].order) + 1 
									: 
									0
							}
						})
						IndexDB.find( 
							"peFestAdmin_mySites",
							"sites",
							"sites",
							this.props.blog_id
						)
							.then(res =>
							{
								//console.log( { ...res.data, ganres : data.lead.post, users: fmru_users } )
								IndexDB.save(
									{
										...res.data, 
										users: fmru_users
									},
									"sites",
									this.props.blog_id,
									"sites",
									"peFestAdmin_mySites",
									1
								)
								IndexDB.save(
									{
										...res.data, 
										users: fmru_users 
									},
									"sites",
									this.props.blog_id,
									"sites",
									"peFestAdmin_allSites",
									1
								)
							})		
					}) 
	}
	rows()
	{
		let rw = super.rows().filter(elem => elem[0] != 'color');  
		rw.splice( 3, 0, [
			"moderators",
			<div key="moderators">
				{ __("Track moderators") }
			</div>
		] , 
		[
			"count",
			<div style={{minWidth:40, display:"flex", justifyContent:"center"}} key="count"> 
				<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Projects count")}><Icon icon="projects" /></Tooltip>
			</div>, 
			50
		], 
		[
			"is_closed",
			<div style={{minWidth:40, display:"flex", justifyContent:"center"}} key="is_closed"> 
				<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Is register opened?")}><Icon icon="unlock" /></Tooltip>
			</div>, 
			50
		] ) 
		rw.splice( 2, 0, [
			'thumbnail',	
			<div style={{minWidth:40, display:"flex", justifyContent:"center"}} key={"thumbnail"}>
				<Tooltip intent={Intent.DANGER}  position={Position.TOP} content={__("Thumbnail")}><Icon icon="media" /></Tooltip>
			</div>, 
			50
		])
		return rw;
	}
	onRow(col, elem)
	{
		let txt = super.onRow(col, elem);
		switch(col)
		{
			case "moderators":
				txt = <div className=" w-100">
					<div className="small">{elem.moderator?.display_name}</div>
					<div className="small">{elem.moderator2?.display_name}</div>
				</div>
				break
			case "count":
				txt = <div className="text-center w-100">
					<div className="small">{elem.count}</div> 
				</div>
				break
			case "is_closed":
				txt = <div className="text-center w-100">
					<Icon icon={!elem.is_closed ? "tick" : "cross"} intent={!elem.is_closed ? "success" : "danger"} /> 
				</div>
				break
			case 'thumbnail':
				txt =  <div style={{
					width: 40,
					height:40,
					backgroundSize: "cover",
					backgroundPosiztion:"center",
					backgroundImage:`url(${elem.thumbnail})`
				}} />
				break
			default:
				break
		}
		return txt;
	}
	getPostTitle = elem =>
	{
		const fields = Array.isArray( elem.form_fields ) && Array.isArray( this.props.options.form_fields )
			?	
			elem.form_fields.map((form, i) => { 
				if(!this.props.options.form_fields[i]) return
				let label = form ? form.join(" ") : ""
				switch(this.props.options.form_fields[i].type)
				{
					case "url":
						label = form.map((e, ii) => {
							return e ? <a key={ii} href={e} target="_blank" className="ml-2">{__("link")}</a> : null	
						})
						break;
					default:
						label = form ? form.join(" ") : ""
				}
				return this.props.options.form_fields[i].hide_in_projects
					?
					null
					:				
					<Tag 
						interactive={true}
						className="small"	 
						minimal
						key={i}
						style={{margin: 2, fontSize: 10, padding: "0 15px", minHeight: 12 }}
						round
					>
						{ this.props.options.form_fields[i].name }:<span className="title ml-2">{label}</span> 
					</Tag>
			})
			:
			null
		//console.log( elem )
		const tags = elem.fmru_ganre?.map(e => <Tag
					style={{backgroundColor : e.color, marginRight:2}}
					interactive={true}
					key={e.id}
				>
					{e.post_title}
				</Tag>);
		return <Fragment>
			<div style={{width: 240}} className="title">{elem.post_title}</div>
			<div style={{width: 240}}>{tags}</div>
			<div style={{width: 240}}>{fields}</div>
		</Fragment>
	} 
	onApproved = (evt, elem) =>
	{
		//console.log( elem.id )
		let state = {} 
		const post = [...this.state.post].map((e, i)=> {
			if(e.id === elem.id)
			{
				elem.post_status = evt.currentTarget.checked ? "publish" : "pending"
				state = e

			}
			return e;
		})
		this.setState({post})
		_fetch( "update_object" , { 
			format 	: this.props.format, 
			termin 	: this.props.termin, 
			data	: state,
			id		: elem.id,
			blog_id	: this.props.blog_id
		})
			.then(data =>
			{
				console.log(data);
				// if(data.post) this.setState({ post:data.post })
			}) 
	}
	onCreate = data =>
	{
		this.setState({post:data});
	} 
	onBitchCreation = () =>
	{
		this.setState({is_batch_creation:!this.state.is_batch_creation, isNew:false});
	} 
}
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import _fetch from "modules/pe-fest-admin-module/api";
import React, {Component, Fragment} from "react"; 
import { __ } from "react-pe-utilities/dist";

export default class CreatedUsers extends Component
{
	state ={post:[]};
	componentWillMount()
	{		
		IndexDB.find( 
			"peFestAdmin_mySites",
			"sites",
			"sites",
			this.props.blog_id
		)
			.then( sites =>
			{ 
				if(sites.data.created_users)
				{ 
					this.setState({post: sites.data.created_users})
				} 
			})
		_fetch("lead_fest_created_users", {id:this.props.blog_id})
			.then(data =>
			{		
				//console.log(data);
				if(!data.isError)
				{
					this.setState({post:data.lead.post})
					IndexDB.find( 
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res =>
						{ 
							IndexDB.save(
								{
									...res.data, 
									created_users: data.lead.post
								},
								"sites",
								this.props.blog_id,
								"sites",
								"peFestAdmin_mySites",
								1
							)
							IndexDB.save(
								{
									...res.data, 
									created_users: data.lead.post
								},
								"sites",
								this.props.blog_id,
								"sites",
								"peFestAdmin_allSites",
								1
							)
						}) 
				}
			});
	}
	render()
	{
		const tbl = this.state.post.map(elem => <tr key={elem.id} >
			<td>{elem.display_name}</td>
			<td className="font-weight-bold">{elem.user_login}</td>
			<td>{elem.pass}</td>
			<td>{ elem.role.map(e => <span className=" member_span">{e}</span> )}</td>
		</tr>);
		
		return <table className="table table-striped mb-5" >
			<thead className="thead-dark">
				<tr>
					<td>{__("Display name")}</td>
					<td>{__("Login")}</td>
					<td>{__("Password")}</td>
					<td>{__("Roles")}</td>
				</tr>
			</thead>
			<tbody>
				{tbl}
			</tbody>
		</table>;
	}
}
import React from "react"
import { compose } from "recompose"
import { withRouter, } from "react-router"  
import FestMemberStroke from "./core/cards/FestMemberStroke"
import FestMemberCard from "./core/cards/FestMemberCard" 
import FestMemberBtn from "./core/cards/FestMemberBtn"
import FestMemberTwoSideCard from "./core/cards/FestMemberTwoSideCard" 

import { __ } from "react-pe-utilities"
import {Loading} from 'react-pe-useful' 
import { Button, Callout } from "@blueprintjs/core"
import FestComponent from "./FestComponent"
import { withFestStore } from "modules/pe-fest-module/data/store"
import User from "./utilities/User"  
import FestMemberSimpleStroke from "./core/cards/FestMemberSimpleStroke"
import WPFestSettings from "./utilities/WPFestSettings"
import SearchMemberEngine from "./core/SearchMemberEngine"
import { LOAD_MEMBERS_LIST_ACTION } from "modules/pe-fest-module/data/actions"
import CardTypeEngine from "./core/CardTypeEngine"
import CardFilterEngine from "./core/CardFilterEngine"

class FestMembers extends FestComponent  
{
	command = LOAD_MEMBERS_LIST_ACTION
	
    getCenterMenu = () => 
    {
        return this.props.store.allMembers?.length > 10
			?
			<>
				<SearchMemberEngine />
				<CardTypeEngine
					cardType={this.state.mtype}
					onClick={mtype => {
						this.setState({mtype})
						localStorage.setItem("pefest-mtype", mtype)
					}}
				/> 
			</>
			:
			null
    }
	sorting = (a, b) =>
	{
		let aa, bb
		switch(this.state.orderType)
		{
			case "nacs":
				return Number(a.o) - Number(b.o) > 0 ? 1 : -1
			case "ndecs":
				return Number(b.o) - Number(a.o) > 0 ? 1 : -1
			case "racs":
				aa = parseFloat(a.correct_member_rate?.rate ? a.correct_member_rate.rate : a.r2)
				bb = parseFloat(b.correct_member_rate?.rate ? b.correct_member_rate.rate : b.r2)
				return aa - bb <= 0 ? 1 : -1
			case "rdecs":
				aa = parseFloat(a.correct_member_rate?.rate ? a.correct_member_rate.rate : a.r2)
				bb = parseFloat(b.correct_member_rate?.rate ? b.correct_member_rate.rate : b.r2) 
				return aa - bb >= 0 ? 1 : -1
			case "acs": 
				aa = a.title.toString().toLowerCase().replaceAll('"', "").replaceAll('«', "")
				bb = b.title.toString().toLowerCase().replaceAll('"', "").replaceAll('«', "")
				if(aa > bb)
					return 1
				if(aa <=  bb)
					return -1
			case "decs":
				aa = a.title.toString().toLowerCase().replaceAll('"', "").replaceAll('«', "")
				bb = b.title.toString().toLowerCase().replaceAll('"', "").replaceAll('«', "")
				if(aa <= bb)
					return 1
				if(aa >  bb)
					return -1
		}
	}
	addRender = () =>
	{ 
		// console.log( this.props.store.members, this.props.store.myTracks )
		if (this.state.loading) {
			return (
				<div className="layout-state p-0 m-0">
					<Loading />
				</div>
			)
		}
		const { mtype, my_members } = this.state 
		const { tracks } = this.props.store
		// list of all mine honeycombs
		const hIDs = my_members?.filter(mm => mm.honeycombs.ID).map(mm => mm.honeycombs.ID ) 
		//console.log( this.props.store.members )
		let members = this.props.store.members
			.filter( member => member.status !== "draft")
			.filter( member => tracks.length == 0 ||  member.tutor?.roles?.filter(role => role === "Tutor").length > 0 )
			.filter( member => my_members?.filter(mm => {
				return mm.id === member.id
			}).length === 0 )
			.sort( this.sorting )
			.sort((a, b) => compareTracks(a, b, this.props.store.myTracks) ? 1 : -1 )
			.sort((a, b) => compareHoneycoms(a, b, hIDs) ? -1 : 1 )
			.map((e, i) => {
				//console.log(e)
				const isMineHoneycoms = hIDs.filter( hid => Number(e.honeycombs?.ID) == Number(hid) ).length > 0 
				switch (mtype) {
					case "box":
						return <FestMemberBtn
							key={e.id}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } }
							{...e}
						/> 
					case "twosidecard":
						return <FestMemberTwoSideCard
							key={e.id}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMineHoneycoms={isMineHoneycoms}
							{...e}
						/> 
					case "stroke":
						return <FestMemberStroke
							key={e.id}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMineHoneycoms={isMineHoneycoms}
							{...e}
						/> 
					case "siple_stroke":
						return <FestMemberSimpleStroke
							key={e.id}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMineHoneycoms={isMineHoneycoms}
							{...e}
						/> 
					case "card":
					default: 
						return <FestMemberCard
							key={i}
							festid={this.props.match.params.festid}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMineHoneycoms={isMineHoneycoms}
							{...e}
						/> 
				}
			}) 
		/**/
		const my_projects = this.props.store.myMembers			
			.filter( member => tracks.length == 0 || member.tutor?.roles?.filter(role => role === "Tutor").length > 0 )
			.sort( this.sorting )
			.map((e, i) => {
				//console.log(e)
				switch (mtype) {
					case "box":
						return <FestMemberBtn
							key={e.id + "mine"}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMine
							{...e}
						/> 
					case "twosidecard":
						return <FestMemberTwoSideCard
							key={e.id + "mine"}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMine
							{...e}
						/> 
					case "stroke":
						return <FestMemberStroke
							key={e.id + "mine"}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMine
							{...e}
						/> 
					case "siple_stroke":
						return <FestMemberSimpleStroke
							key={e.id + "mine"}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMine
							{...e}
						/> 
					default:
						return <FestMemberCard
							key={e.id + "mine"}
							festid={WPFestSettings.festId}
							route={this.props.route}
							festData={ { ...this.state } } 
							isMine
							{...e}
						/> 
				}
			})  

		let noVerified = tracks.length > 0 
			?
			this.props.store.members
				.filter( member => member.status !== "draft")
				.filter( member => {
					if( !member.tutor || !member.tutor?.roles ) return true
					return member.tutor?.roles?.filter(role => role === "Tutor").length === 0 
				})
				.sort( this.sorting )
				.map((e, i) => {
					// console.log( e.tutor?.id, User.id )
					const isMine = e.tutor?.id === User.id
					const isMineHoneycoms = hIDs.filter( hid => Number(e.honeycombs?.ID) == Number(hid) ).length > 0 
					switch (mtype) {
						case "box":
							return <FestMemberBtn
								key={e.id}
								festid={WPFestSettings.festId}
								route={this.props.route}
								festData={ { ...this.state } } 
								isMine={isMine}
								{...e}
							/> 
						case "twosidecard":
							return <FestMemberTwoSideCard
								key={e.id}
								festid={WPFestSettings.festId}
								route={this.props.route}
								festData={ { ...this.state } } 
								isMineHoneycoms={isMineHoneycoms}
								isMine={isMine}
								{...e}
							/> 
						case "stroke":
							return <FestMemberStroke
								key={e.id}
								festid={WPFestSettings.festId}
								route={this.props.route}
								festData={ { ...this.state } } 
								isMineHoneycoms={isMineHoneycoms}
								isMine={isMine}
								{...e}
							/> 
						case "siple_stroke":
							return <FestMemberSimpleStroke
								key={e.id}
								festid={WPFestSettings.festId}
								route={this.props.route}
								festData={ { ...this.state } } 
								isMineHoneycoms={isMineHoneycoms}
								isMine={isMine}
								{...e}
							/> 
						case "card":
						default: 
							return <FestMemberCard
								key={i}
								festid={this.props.match.params.festid}
								route={this.props.route}
								festData={ { ...this.state } } 
								isMineHoneycoms={isMineHoneycoms}
								isMine={isMine}
								{...e}
							/> 
					}
				})
				:
				[] 	

		members = [...my_projects, ...members].length > 0 
			?
			[...my_projects, ...members]
			:
			<div className="alert alert-secondary p-5 text-center lead w-100 ">
				{__("No Projects exists")}
			</div>	
		return <>
			<div className="w-100 d-flex">
				<div className="w-20px ">
					<CardFilterEngine 
						type={ this.state.orderType } 
						onClick={this.onOrder}
					/>
				</div>
				<div className=" mb-5 d-flex flex-wrap flex-grow-100 w-100-40px">
					{ members }
				</div>
			</div>	
			{
				noVerified.length > 0
					?
					<>
						<div className=" w-100 d-flex ">
							<div className="w-20px "></div>
							<div className="display-6 m-4">
								{ __("Pending confirmation") }
							</div>							
						</div>
						<div className=" w-100 d-flex ">
							<div className="w-20px "></div>	
							<div className="opacity_50 mb-5 d-flex flex-wrap flex-grow-100 w-100-40px">
								{ noVerified }
							</div>				
						</div>				
					</>
					:
					null
			} 
		</>		
	} 
	onGanre = (ganres) => {
		this.setState({ ganreFilterIDs: ganres })
	}
	onOrder = orderType =>
	{
		localStorage.setItem( "pefest-orderType",  orderType )
		this.setState({orderType})
	}
}
export default compose(
	withRouter,
	withFestStore,
  )(FestMembers)

export const compareHoneycoms = ( a, b, hIDs ) =>
{
	if( !Array.isArray( hIDs ) ) return true
	const compareA = hIDs.filter( hid => Number(a.honeycombs?.ID) == Number(hid) ).length > 0
	const compareB = hIDs.filter( hid => Number(b.honeycombs?.ID) == Number(hid) ).length > 0 
	return Number(compareA) > Number(compareB)
}
export const compareTracks = (a, b, myTracks) =>
{
	if( !Array.isArray( myTracks )  || !User.isRole(["Track_moderator"]) ) return true
	const compareA = myTracks.filter( trID => Number(a.track?.ID || 0 ) === trID ).length > 0
	const compareB = myTracks.filter( trID => Number(b.track?.ID || 0 ) === trID ).length > 0
	return Number(compareA) < Number(compareB)
}

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Popover, Position } from '@blueprintjs/core'
import { sprintf, __ } from 'react-pe-utilities/dist'

const MemberRatingDisplay = props => {
    return props.correct_member_rate && props.correct_member_rate.rate
        ?
        <div className='d-flex align-items-center '>
            <div className={` ${props.className}`}> 
                <span className=""> { props.correct_member_rate.rate }</span>
            </div> 
            <div className={` `}>
                <Popover
                    position={Position.TOP}
                    interactionKind="hover"
                    content={<div 
                        className="p-4 large-square text-center" 
                        dangerouslySetInnerHTML={{ 
                            __html: sprintf(
                                __("This is the grade adjusted by the Track moderator, %s. <p>Reason: %s. <p>The average rating given by the experts is %s"),
                                `<b>${props.correct_member_rate?.display_name}</b>`,
                                props.correct_member_rate?.description
                                    ?
                                    `<b>${props.correct_member_rate?.description}</b>` 
                                    :
                                    __("no exist"),
                                    parseFloat(props.countedRating).toFixed(2).replace(/\.?0*$/,'')
                            ) 
                        }} 
                    />}
                >
                    <div className={`btn btn-link opacity_5 d-flex align-items-center ml-1 ${ props.className } `}>
                        <Icon icon="help" iconSize={15} />
                    </div>
                </Popover>
            </div>
        </div>
        :
        <div className='d-flex align-items-center '>
            <div className={` ${props.className}`}> 
                <span className=""> { parseFloat(props.countedRating).toFixed(2).replace(/\.?0*$/,'') }</span>
            </div> 
        </div>
}

MemberRatingDisplay.propTypes = {}

export default MemberRatingDisplay
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MilestoneButton from '../milestone/MilestoneButton'
import { __ } from 'react-pe-utilities/dist'
import MilestoneIncludeList from '../milestone/MilestoneIncludeList'
import FestMemberRaiting from './core/rating/FestMemberRaiting'
import { Icon } from '@blueprintjs/core'
import { isStatusFinished } from './utilities/isStatusOpened'

const FestMemberMilestones = props => 
{
    // console.log(props.milestones)
    const [milestones, setMilestones] = useState( props.milestones || [] )
    const [isEditForms, setEditForms] = useState( props.isEditForms || false )
    const [currentMilestone, setCurrentMilestone] = useState(props.milestones && props.milestones[0] ? props.milestones[0].ID : -1)
    useEffect(() => { 
        setMilestones( props.milestones || [] )
    }, [props.milestones])
    
    useEffect(() => {
        setEditForms( props.isEditForms )
    }, [props.isEditForms]) 
    
    // console.log( props.milestones )    

    const milestoneList = milestones.map((milestone =>
    { 
        let tick = null 
        if( milestone.is_attachments_required && milestone.attachments?.length == 0)
        {
            tick = <Icon 
                icon="error" 
                color="#c23030" 
                className='ml-2 opacity_100 hint hint--top'
                data-hint={__("Attachments must be registered")}
            />   
        }
        else if(true) 
        {
            tick = <span className='ml-2 lead'>
                {milestone.average_rating}
            </span>
        }
        return <MilestoneButton
            key={milestone.ID}
            tick={tick}
            {...milestone}
            currentMilestone={currentMilestone}
            setCurrentMilestone={ id => {
                setCurrentMilestone(id)
            }}
        />
    }))
    let currentMilestoneObj = milestones.filter(m => m.ID === currentMilestone)[0] 
    //console.log( "FestMemberMilestones.orphans:", props )
    const articleElements = currentMilestoneObj?.experts.map(e => e.display_name)
    const experts = articleElements?.length > 0 
        ?  
        <div className=" transparent flex-centered mb-3"> 
            <div className=" test-descr-title text-nowrap m-0 mr-3">
                {__("Experts, who put raiting:")}
            </div>
            <div className="font-weight-bold text-nowrap">{ articleElements.join(", ") }</div>
        </div> 
        : 
        ""  
    return <div className="pb-4 forms-short">
        <div className='row milestones-cont'>
            <div className='pt-3 pr-5 opacity_25'>{__("Milestones")}: </div>
            { milestoneList }
        </div> 
        <div className="lead">
            { experts }
        </div> 
        <MilestoneIncludeList 
            festid={props.festid}
            memberId={props.id}
            data={ currentMilestoneObj }
            isEditForms={isEditForms}
            is_owner={ props.is_owner && isStatusFinished() }
            onEditFormToggle={props.onEditFormToggle} 
        />
        {
            // Если текущий Этап предусматривает обязательые вложения, а их нет
            currentMilestoneObj?.is_attachments_required && currentMilestoneObj?.attachments?.length === 0
                ?
                null
                :
                <FestMemberRaiting
                    {...props}
                    milestone={ currentMilestoneObj }
                    isEditForms={ isEditForms }
                /> 
        }
        
    </div>
}

FestMemberMilestones.propTypes = {}

export default FestMemberMilestones
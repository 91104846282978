import React, { Component, Fragment,   } from "react" 
import {  Intent, Callout, Collapse, Button, Icon, } from "@blueprintjs/core" 
import { __ } from "react-pe-utilities" 
import WPFestSettings from "../../utilities/WPFestSettings" 
import _fetch from "../.."  
import { AppToaster } from 'react-pe-useful'  
import { compose } from "recompose"
import { withRouter, } from "react-router" 
import chroma from 'chroma-js'
import Style from "style-it"
import AverageRatingDisplay from "../AverageRatingDisplay"
import CriteryDescriptionDisplay from "./CriteryDescriptionDisplay"
import CriteryRatingViewDisplay from "./CriteryRatingViewDisplay"
import CriteryRatingExpertDisplay from "./CriteryRatingExpertDisplay"
import CriteryCommentaryDialog from "./CriteryCommentaryDialog" 
import actions, { CHANGE_CRITERY_ACTION, GET_SINGLE_MEMBER_ACTION } from "modules/pe-fest-module/data/actions"
import User from "../../utilities/User"
import { isPartner } from "../../utilities/isPartner"
import isStatusOpened from "../../utilities/isStatusOpened"


export const NEED_DESCRIPTION_COMMENT = __("The maximum score must be justified.")
class Critery extends Component 
{
	RATING_ESCAPED = __("The rating is escaped.")
	constructor(props)
	{
		super(props)
		this.state = {
			description: this.getMyRating().description,//this.props.descr,
			rating: this.props.rating,
			isCommentaryOpen: false,
			is: true,
			opacityClassName:""
		}
	}
	forceUpdate = () => this.setState({ k: !this.state.k })
	onSwitchComment = () =>
	{
		WPFestSettings.set_is_comment(!WPFestSettings.is_comment)
        this.setState({
			isCommentaryOpen : true, 
			commentary: ''
		})
	}
	isPartner = () =>
	{
		return isPartner(this.props.member_id, this.props.my_honeycombs) && User.roles.filter((e) => e === "Tutor").length > 0
	}
	isExpert = () =>
	{ 
		// 1. Если Этап самооценочный, а ты - Наставник этого проекта
		// 2. Если ты - Эксперт, а Этапа нет или он не самооценочный
		// 3. Если ты партнёр по Соте, а Этапа нет или он не самооценочный
		const isExpert = this.props.milestone?.is_auto_rait 
			?
			this.props.is_owner
				?
				User.roles.filter((e) => e === "Tutor").length > 0
				:
				false
			: 
			( User.roles.filter((e) => e === "Expert").length > 0 && ( !this.props.is_owner || WPFestSettings.is_owner_rate ) ) ||
			( !this.props.is_owner && this.isPartner())		
		return isExpert
	}
	getMyRating = () =>
	{
		const { full_rating_data, user } = this.props
		if(!full_rating_data)	return
		const r = Array.isArray(full_rating_data)
			?
			full_rating_data.filter(d => Number(d.expert_id) === user.id)
			: 
			[]
		return r[0] ? r[0] : {}
	}
	render() {
	  //console.log( this.props ) 
	  const { title, experts, average_critery_rating, full_rating_data, id } = this.props
	  const { description, rating, commentary } = this.state
	  const is_expert = this.isExpert()
	  
      const accentuated = chroma(this.props.color).get('hsl.l') > .55 ? "#000000" : "#FFFFFF" 
	  const accentuated2 = chroma(this.props.color).get('hsl.l') > .77 ? "#6c757d33" : "#FFFFFF33" 
	  return Style.it(
		`
		.first
		{
			border-left-color: ${this.props.color};
			---border-left-width: 36px;
		}
		.critery_cell
		{
			background-color: ${this.props.color === "#FFF" ? "#DDD" : chroma( this.props.color ).brighten( .66 ).css(true)};
			color: ${accentuated};
			border-bottom: none;
		}
		.critery_cell .text-secondary.font-weight-light > span,
		.critery-descr
		{
			color: ${accentuated};
		}
		.fmRU_button svg path
		{
			fill: ${accentuated};
		}
		.critery-block
		{
			border-bottom: 1px solid ${accentuated2};
		}
		.critery-title > .title 
		{
			color: ${ accentuated };

		}
		.dots-after::after
		{
			border-bottom-color: ${ accentuated }77!important;
		}
		`,
		<div className="row critery-block">
			<div className="col-md-7 col-12 critery_cell grey first bl-20 pb-0" >
				<div className="critery-title ml-4"> 
					<div className="title ">
						{ title }
					</div>
					<CriteryDescriptionDisplay
						id={id}
						is_expert={is_expert}
						commentary={commentary}
						description={ description }
						onOpen={ () => this.setState({ isCommentaryOpen: !this.state.isCommentaryOpen }) }
					/>
				</div>
			</div>
			<div className={`col-md-5 col-12 critery_cell grey pb-0_`} >
				<div className="d-flex flex-centered flex-md-row flex-column mr-3"> 
					<div className={` w-100 flex-grow-100 ${this.state.opacityClassName}`}> 
						{ 
							is_expert && isStatusOpened()
								? 
								<CriteryRatingExpertDisplay 
									is_expert={is_expert}
									id={this.props.id}
									title={this.props.title}
									rating_type={this.props.rating_type}
									rating={this.state.rating}
									member_id={this.props.member_id}
									critery_rating={this.props.critery_rating}
									category_id={this.props.category_id}
									color={this.props.color}
									max_raiting={this.getMaxRating()}
									onOpen={(isCommentaryOpen, isForce, data) => {
										//console.log(isForce, data)
										this.setState({
											rating: data.c,
											isCommentaryOpen,
											lockWithoutComment: isForce, 
											
										})
									}}
									onCheck={this.onCheck}
								/>
								: 
								<CriteryRatingViewDisplay 
									rating={this.props.average_critery_rating}
								/>
						}
					</div>
					<Button 
						minimal
						onClick={this.onSwitchComment}
						data-hint={__("Comment your rating")}
						className={`hint hint--top ${WPFestSettings.is_comment ? "unvisibled" : ""} `}
					>
						<Icon icon="edit" color={ accentuated } /> 
					</Button>
					<AverageRatingDisplay
						user={this.props.user}
						is_expert={is_expert}
						average_critery_rating={average_critery_rating}
						full_rating_data={ full_rating_data }
						experts={ experts }
						color={ accentuated }
					/>					
				</div>
			</div>	 
			<CriteryCommentaryDialog
				isOpen={this.state.isCommentaryOpen}
				commentary={this.state.commentary}
				lockWithoutComment={this.state.lockWithoutComment}
				onOpen={isCommentaryOpen => this.setState({ isCommentaryOpen })}
				onSend={this.onSendDescripion}
			/>
		</div>
	  )
	}

	onEdit = () => {

	}

	onOpen = () => {
		const { isOpen, oldRating, rating, description } = this.state
		const { rating_type, category_rating_type } = this.props
	  	if (WPFestSettings.is_comment) 
	  		this.setState({ isOpen: false, commentary:"" })
	  	else 
	  		this.setState({ isOpen: !isOpen, commentary:"" })

		//console.log(!description, WPFestSettings.max_raiting, Number(rating))
		if(
			!description 
			&& (
				rating_type === "independent_grade"
				|| (rating_type === "each_category" && category_rating_type === "independent_grade")
			) 
			&& 
			this.getMaxRating() === Number(rating)
		)
		{
			this.setState({ rating : oldRating, oldRating : rating })
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: NEED_DESCRIPTION_COMMENT,
			})
		}
	} 

	onSendDescripion = description => {
	  	this.setState(
			{ 
				description 
			},
			() => this.onSend()
		)
	}

	onCheck = (data) => {
	  	data.d = this.state.description 
	  	this.setState(
	    	{
				oldRating : this.state.rating,
				rating: data.c,
				isOpen: WPFestSettings.is_comment,
	    	},
			() => this.onSend()
	  	)
	}
	onPersentage = rating =>
	{
		this.setState( 
			{ 
				oldRating : this.state.rating,
				rating, 
				//isOpen: WPFestSettings.is_comment, 
			},
			
		)
	}
	onFinalPersentage = rating =>
	{
		this.setState( 
			{ 
				oldRating : this.state.rating,
				rating, 
				//isOpen: WPFestSettings.is_comment, 
			},
			() => this.onSend() 
		)		
	} 
	getMaxRating = () =>
	{
		const { max_rating } = this.props
		return WPFestSettings.is_independent_grade_criteries
				?
				max_rating ? max_rating : WPFestSettings.max_raiting
				:
				WPFestSettings.max_raiting
	}
	onSend = () => {
	  const { rating, description } = this.state 
	  const args = {
	    d: description,
	    c: parseInt(rating),
	  }
	  //console.log( args )
	  this.ozenka(args)
	}
	ozenka = (args) =>
	{
		const { id, member_id } = this.props
	  	this.setState({ opacityClassName : "opacity_5", })
		_fetch(
			"get_fest_command", 
			{
				...args, 
				mstnID			: this.props.milestone?.ID,
				mid				: parseInt(member_id),
	    		crid			: parseInt(id),
				festId			: WPFestSettings.festId, 
				command			:"ozenka"
			},
			WPFestSettings.serviceURL,
			WPFestSettings.getToken( WPFestSettings.url ),
			"wpfa",
		)
			.then((_data) => { 
				// console.log( _data ) 
				actions(
					CHANGE_CRITERY_ACTION, 					
					{
						member_id : 	parseInt(member_id), //
						critery_id :	parseInt(id),
						rating :		_data.data.c,
						critery_rating :_data.data.c,
						description : 	_data.data.descr,
						type :			"orphans"
					}
				)			
				this.setState({ 
					opacityClassName : "",
				})
			})
	}
}
export default compose(
	withRouter,
  )(Critery)


 export const CRITERY_PARENT_TYPES = {
	category :		"category",
	orphans :		"orphans",
	uniqs :			"uniqs",
 } 

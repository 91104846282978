import React, { Component } from "react"
import { withRouter, } from "react-router"
import { compose } from "recompose"
import { Intent } from "@blueprintjs/core"
import _fetch from "."
import FestMemberSetting from "./FestMemberSetting" 
import { __, initArea, sprintf} from "react-pe-utilities" 
import { AppToaster } from 'react-pe-useful'
import WPFestSettings from "./utilities/WPFestSettings"
import FestComponent from "./FestComponent"
import User from "./utilities/User"
import SuperLoading from "states/utils/SuperLoading"
import { withFestStore } from "modules/pe-fest-module/data/store"
import isEmail from "states/utils/isEmail"
import KraefestRegisterForm from "modules/pe-fest-module/widgets/KraefestRegisterForm"

/*
	Экран формы редактирования Проекта его владельцем (Наставник, Автор проекта)
*/
class FestNewProjectForm extends FestComponent { 
		
	fetchCommand  = "new_project"
	fetchArgs     = { }
	header_type   = "insert-progect"  
	setStartState = () =>
	{
		return {
			form_fields: [],
			...this.props,
			current: "settings",
			isLoading: false,
		}
	}
	// https://picsum.photos/400/600?grayscale

	addRender = () =>
	{  
		if( this.state.isLoading )
		{
			return <SuperLoading type="foldTheCube" />
		}
		// console.log( this.props )
		return <div className="row">
		{ 
			User.id 
				? 
				this.content() 
				: 
				this.the_none() 
		}
		</div> 
	}

	the_none() {
		return (
			<div className="short-container text-center alert alert-danger p-5 w-100 my-2 lead">
				{__("Log in for read content")}
			</div>
		)
	} 
	onExtendFetchParams = ( value, field, n, i ) =>
	{
		//console.log("onExtendFetchParams:",  value, field, n, i  )
		let state = {}
		//state[field] = value
		state.form_fields = [ ...(this.state.form_fields || []) ]  
		state.form_fields[ n ] = state.form_fields[ n ] || []
		state.form_fields[ n ][ i ] = value
		//console.log( state.form_fields )
		//this.setState( state )
		this.state = {...this.state, ...state}
	}

	on = (data) => {
		// console.log(this.state.form_fields)
		const state = { form_fields : this.state.form_fields }
		state[data.field] = data.value
		this.setState(state)
	}

	ons = (data) => {
		// console.log(data)
		const state = { form_fields : this.state.form_fields }
		data.fields.forEach((e) => {
			state[e.field] = e.value
		})
		this.setState(state)
	}
	content() {
		switch (this.state.current) 
		{
			case "team":
				return
			case "settings":
			default:
				return this.props.store.options.is_register_project
					?
					<>
						<div className="col-12  p-4">
							<FestMemberSetting
								all_ganres={this.state.ganres}
								ganres={[]} 
								tracks={this.state.tracks} 
								isNew
								on={this.on}
								ons={this.ons}
							/>
							<KraefestRegisterForm
								{...this.props}
								{...this.state}
								options={this.props.store.options}
								role="Tutor"
								isNew={true}
								form_fields={this.state.form_fields}
								onExtendFetchParams={this.onExtendFetchParams} 
							/>
							{/* {
								initArea(
									"fest-register-form",
									{
										...this.props,
										...this.state,
										options: this.props.store.options,
										role: "Tutor",
										isNew: true,
										values: this.state.form_fields, 
										onExtendFetchParams : this.onExtendFetchParams
									},
								)
							} */}
							{/* 
							<FestMemberTeam
								is_owner
								isNew
								owners={{
									leader: this.state.user || {},
									member_0: {},
									member_1: {},
									member_2: {},
									member_3: {},
									member_4: {},
									member_5: {},
									member_6: {},
									member_7: {},
									member_8: {},
									member_9: {},
									tutor: {},
								}}
								on={this.on} 
							/> 
							*/}

						</div>
						<div className="col-md-8 offset-md-4  p-4">
							<div className="btn btn-lg btn-primary" onClick={this.onInsert}>
								{__("Insert new Project")}
							</div>
						</div>
					</>
					:
					<div className="container text-center alert alert-danger p-5 w-100 my-2 lead">
						{__("No evalable")}
					</div>
		}
	}

	onSwitch = (evt) => {
		this.setState({ current: evt.currentTarget.getAttribute("e") })
	}

	onInsert = () => 
	{
		if( Array.isArray(this.state.tracks) && this.state.tracks.length > 0 && !this.state.fmru_track)
		{
			AppToaster.show({
				message: __("Need track"),
				className: "p-4",
				intent: Intent.DANGER
			})
			return
		}
		if(!isEmail(this.state.tutor_email))
		{
			AppToaster.show({
				message: __("Email field not corrent"),
				className: "p-4",
				intent: Intent.DANGER
			})
			return
		}
		let args = {
			post_title: this.state.post_title,
			post_content: this.state.post_content || " ",
			thumbnail: this.state.thumbnail,
			thumbnail_name: this.state.thumbnail_name, 
			fmru_group_player: this.state.fmru_group_player,
			tracks: this.state.fmru_track, // wp-таксономия. На серваке записывается как массив
			tutor_name: this.state.tutor_name,
			tutor_email: this.state.tutor_email,
			vk_url: this.state.vk_url,
			fb_url: this.state.fb_url,
			instagramm_url: this.state.instagramm_url,
			tutor_id: this.state.tutor_id,
			leader_id: this.state.leader_id,
			member_0: this.state.member_0,
			member_1: this.state.member_1,
			member_2: this.state.member_2,
			member_3: this.state.member_3,
			member_4: this.state.member_4,
			member_5: this.state.member_5,
			member_6: this.state.member_6,
			member_7: this.state.member_7,
			member_8: this.state.member_8,
			member_9: this.state.member_9,
			form_fields: this.state.form_fields,
			command: "create_project_with_patams",
			festId: WPFestSettings.festId,
		}
		
		// console.log( args )
		// return
		let yes = true
		if (!this.state.post_title) {
			yes = false
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Insert title"),
			})
		}


		if ( this.props.store.options.fmru_evlbl_roles.filter(role => role === "Tutor").length > 0)
		{
			if ( !this.state.tutor_name  ) 
			{
				yes = false
				AppToaster.show({
					intent: Intent.DANGER,
					icon: "tick",
					message: __("Insert Tutor name"),
				})
			} 
			if ( !this.state.tutor_email  ) 
			{
				yes = false
				AppToaster.show({
					intent: Intent.DANGER,
					icon: "tick",
					message: __("Insert Tutor name"),
				})
			} 
		} 
		

		if(
			( !Array.isArray(this.state.form_fields) || this.state.form_fields.length === 0 ) &&			
			Array.isArray( this.props.store.options?.form_fields ) &&
			this.props.store.options?.form_fields.length > 0
		)
		{
			yes = false
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Insert fields"),
			})
		}
		else
		{ 
			this.props.store.options.form_fields.forEach((ff, i) => 
			{
				// console.log( this.state.form_fields[i] )
				// console.log( this.props.store.options.form_fields[i].require )
				// console.log( 
				// 	!this.state.form_fields[i] && 
				// 	this.props.store.options &&
				// 	Array.isArray( this.props.store.options?.form_fields ) && 
				// 	this.props.store.options.form_fields[i].require
				//  )

				if(
					!this.state.form_fields[i] && 
					this.props.store.options &&
					Array.isArray( this.props.store.options?.form_fields ) && 
					this.props.store.options.form_fields[i].require
				)
				{
					yes = false
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						message: sprintf ( 
							__("Require field «%s» no must be empty"), 
							this.props.store.options.form_fields[i].name 
						),
					})
				}
			})
		}
		
		// if (!this.state.fmru_group_player || this.state.fmru_group_player.length == 0) {
		// 	yes = false
		// 	AppToaster.show({
		// 		intent: Intent.DANGER,
		// 		icon: "tick",
		// 		message: __("Insert one or more ganre"),
		// 	})
		// }

		if (!yes) return
		this.setState({ isLoading : true })
		
		_fetch(
			"get_fest_command",
			args,
			WPFestSettings.serviceURL,
			WPFestSettings.token,
			"wpfa",
		)
			.then((_data) => {
				const data = _data.data 
				//console.log(this.props.route)
				//console.log(data)
				this.setState({ 
					isLoading : false,					 
				})
				if(data.message)
				{
					AppToaster.show({
						className: "p-5 display-7 text-center ",
						intent: Intent[ data.message_type ],
						icon: "tick",
						message: __( data.message ) + " " + __("Please wait"),
					})
				}
				setTimeout(() => {
					this.props.history.push(
						`${WPFestSettings.getFestURL()}/member/${data.created_id}?help&phase=register-project`
					)
				}, 5000)
				
			})
	}
}
export default compose(
	withRouter,
	withFestStore
)(FestNewProjectForm)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import tracksLogo from "../../assets/img/tracks.svg"
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import actions, { SET_TRACK_FILTER_ACTION } from 'modules/pe-fest-module/data/actions'
import { DEFAULT_MEMBER_FILTERS, useFestStore } from 'modules/pe-fest-module/data/store'
import User from 'modules/pe-fest-module/views/wpfestREST/utilities/User'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const TracksForm = props => {
    let timeOut = -1
    const filters = useFestStore(state => state.memberFilters)
    const [title, setTitle] = useState(
        useFestStore.getState().memberFilters == DEFAULT_MEMBER_FILTERS
            ?
            __("all Tracks")
            :
            props
                .tracks
                    ?.filter( t => t.ID === useFestStore.getState().memberFilters.tracks[0] )[0]
                        ?.post_title
    )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)    
    const location = useLocation() 
    const onOpen = boo =>
    {
        setOpen(boo)
    }
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const closeLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(false)
    }
    useEffect(() => {
        // console.log( filters.tracks[0] )
        setTitle( __( filters.tracks[0] == -1 ? "all Tracks" : filters.tracks[0] === "mine" ? "Only mine Tracks" : title ) )
    }, [filters])
    useEffect(() =>
    {
        window.document.body.addEventListener("click", closeLoginOpen)
        return () => {
            window.document.body.addEventListener("click", closeLoginOpen)
            clearTimeout( timeOut )
        }
    }, [])
    const selectTrack = id =>
    { 
        actions( SET_TRACK_FILTER_ACTION, { tracks: [id] } )
        //console.log( id )
        if(id === -1)
        {
            setTitle( __("all Tracks") )
            window.history.pushState( null, null, `${location.pathname}` )
        }
        else if( id === "mine")
        {
            setTitle( __("Only mine Tracks") )
            window.history.pushState( null, null, `${location.pathname}` )
        }
        else
        {
            setTitle(  props.tracks?.filter(h => h.ID === id)[0]?.post_title )
            window.history.pushState( null, `track=${ id }`, `${location.pathname}?track=${ id }` )
        }
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = evt =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    } 
    if( !Array.isArray( props.tracks )  || props.tracks?.length == 0 ) return null
    return <>   
        <div 
            className="indicator lrg classic"             
            onMouseLeave={onMouseLeave}
        >
            <div className="n1" onClick={handleLoginOpen} >
                { title }
            </div>
            <div className="iconnn" onClick={handleLoginOpen} >
                <img src={ tracksLogo } alt="" />
            </div>
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <div className='overflow-y-auto' style={{maxHeight:"calc(100vh - 150px)"}}>
                    <ButtonGroup 
                        vertical 
                        fill 
                        minimal 
                        onMouseEnter={() =>  clearTimeout( timeOut ) }
                        style={{ minWidth: 200 }}
                    >
                        {
                            filters.honeycombs[0] !== -1 || filters.tracks[0] !== -1 
                                ?
                                <Button className='p-3' onClick={() => selectTrack( -1 ) }>
                                    {__("all Tracks")}
                                </Button>
                                :
                                null
                        }                      
                        {
                            User.isRole([ "Tutor", "Project_author", "Track_moderator" ]) 
                                ?   
                                <Button className='p-3' onClick={() => selectTrack( "mine" ) }>
                                    {__("Only mine Tracks")}
                                </Button> 
                                :
                                null
                        }
                        {
                        Array.isArray( props.tracks )
                            ?
                            <>
                                <Button 
                                    icon="chevron-down" 
                                    minimal
                                    small
                                    className={isCollapse ? "hidden" : " p-3 "} 
                                    onClick={event => {
                                        setCollapse(true)
                                        onOpen(true)
                                        event.preventDefault()
                                        event.stopPropagation()
                                    }}
                                />
                                <Collapse isOpen={isCollapse} >
                                    <div className="d-flex flex-column">
                                    {
                                        props.tracks.map(h =>
                                            {
                                                return <div className='btn btn-light p-0' key={h.ID} onClick={() => selectTrack(h.ID) }>
                                                    <div className='d-flex'>
                                                        <span className='flex-grow-100 p-3 small '>{ h.post_title }</span>
                                                        <span className ='p-3 border-left border-white bg-secondary-super-light small '>{ h.count }</span>
                                                    </div>
                                                </div>
                                                
                                            })
                                    }                                    
                                    </div>
                                </Collapse>
                            </>
                            :                        
                            <div className='p-3' >
                                {__("no Tracks")}
                            </div>   
                        }
                    </ButtonGroup>
                </div>
              )}
            >
                <div style={{ marginTop: 10 }} />
            </Popover> 
        </div> 
    </> 
}

TracksForm.propTypes = {}

export default TracksForm
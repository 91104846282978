import { STATISTICS_FULL_DATA_ACTION } from 'modules/pe-fest-module/data/actions'
import { withFestStore } from 'modules/pe-fest-module/data/store'
import React from 'react'
import { __ } from 'react-pe-utilities/dist'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import _fetch from '.' 
import FullStatistics from './core/statistics/FullStatistics'
import FestComponent from './FestComponent'
import User from './utilities/User' 

class FestStatistics extends FestComponent 
{ 
    header_type     = "statistics"
    fetchCommand    =  STATISTICS_FULL_DATA_ACTION
    fetchArgs       = { }
    
    constructor(props)
    {
        super(props) 
        this.state = {
            fest_members: [],
            mtype: "box",
            ganres: [],
            ganreFilterIDs: [],
            loading: true, 
            menuHeight: "0px",
            currentTab : "members",
            table:""
        }
    }
    onSwitch = tab =>
    {
        this.setState({ currentTab: tab })
    }
    addRender = () => 
    {
        return User.isRole(["administrator", "Track_moderator"])
            ?
            <div className=' '> 
                <div className='row ' id="fest-head-switcher">
                    <div className='col-12 milestones-cont d-flex'>
                        <div className='pt-3 pr-5 display-6 '>{__("Statistics")}</div>
                        <div 
                            className={ `milestone-button ${this.state.currentTab === "members" ? "active" : ""}` }
                            onClick={() => this.onSwitch("members")}
                        >
                            <span className={`milestone-button-label `}>
                                { __("Members") }
                            </span>  
                        </div> 
                        <div 
                            className={ `milestone-button ${ this.state.currentTab === "experts" ? "active" : "" }` }
                            onClick={() => this.onSwitch("experts")}
                        >
                            <span className={`milestone-button-label `}>
                                { __("Users and rates") }
                            </span>  
                        </div> 
                        <div 
                            className={ `milestone-button ${ this.state.currentTab === "generate" ? "active" : "" }` }
                            onClick={() => this.onSwitch("generate")}
                        >
                            <span className={`milestone-button-label `}>
                                { __("Synthesizer") }
                            </span>  
                        </div> 
                        <div 
                            className={ `milestone-button ${ this.state.currentTab === "specials" ? "active" : "" }` }
                            onClick={() => this.onSwitch("specials")}
                        >
                            <span className={`milestone-button-label `}>
                                { __("Specials") }
                            </span>  
                        </div> 
                    </div>                 
                </div>                  
                <div  className='row' > 
                    <FullStatistics currentTab={this.state.currentTab} />
                </div>
            </div>
            :
            <div className=" alert alert-danger p-5 lead text-center">
                {__("You haven't rights")}
            </div>
    }
}
export default compose(
    withRouter,
    withFestStore
  )(FestStatistics)


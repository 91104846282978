export default (member, s) =>
{ 
    return member.title.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        member.content.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        member.tutor?.display_name.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        member.tutor?.login.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        member.project_author?.display_name.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        member.project_author?.login.toLowerCase().indexOf( s.toLowerCase() ) >= 0 ||
        Array.isArray(member.form_fields)
            ?
            member.form_fields.filter(ff => {
                if(Array.isArray(ff))
                {
                    return ff.filter(fff => fff?.toString().toLowerCase().indexOf( s.toLowerCase() ) >= 0 ).length > 0
                }
                return false
            }).length > 0 
            :
            false

}
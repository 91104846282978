import React, { Component, Suspense } from "react"
import { compose } from "recompose"
import { Redirect, Route, Switch, withRouter } from "react-router"
import { concatRouting, mainPage, routeData } from "react-pe-layouts"
import { isCapability } from "react-pe-utilities"
import Layouts from "react-pe-layouts"
import { __, importAll} from "react-pe-utilities" 
import { initArea } from "react-pe-utilities"
import { Loading } from "react-pe-useful"          

/* ================================
**
** TODO: включить lazy-loading
**
================================ */ 

const components = {}
const lazies = {}
const plViews = [] 

 

class LayoutMain extends Component 
{
  constructor(props)
  {
    super(props)
    this.state = {
      isLoaded:false
    }
  }
  componentDidMount()
  { 
    // Все Компоненты из папки states для последующей подстановки в роутинги
    importAll(require.context("states/", false, /\.js$/), [], components, lazies, "states/")

    // Собираем все компоненты из папки modules (которые указаны в layouts.json, раздел modules)
    // для последующей подстановки в роутинги
    const plgns = Layouts().modules
    Object.keys(plgns).forEach((plugin) => {
      Object.keys(plgns[plugin].views).forEach((view) => {
        plViews.push(view)
      })
    })
    importAll(require.context("modules/", true, /\.js$/), plViews, components, lazies, "modules/")
    setTimeout(() => {
      this.setState({isLoaded:true})
    }, 30);
  }

  render() 
  {
    if(!this.state.isLoaded)
    {
      return <Loading />
    }

    const main = mainPage()
    let routing = []
    routing = [
      ...concatRouting(),
      {
        title: "Sign in",
        icon: "fas fa-sign-in-alt",
				route: "login",
				component: "LoginView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Register User",
        icon: "fas fa-sign-in-alt",
				route: "register",
				component: "RegisterView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Recover password",
        icon: "",
				route: "remember",
				component: "RememberPasswordView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Reset password",
        icon: "",
				route: "reset",
				component: "ResetPasswordView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Settings",
        icon: "fas fa-cog",
				route: "cog",
				component: "SettingsView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Restore password",
        icon: "fas fa-cog",
				route: "restore/:id/:code",
				component: "FinishRestorePasswordView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Verify password",
        icon: "fas fa-cog",
				route: "verify",
				component: "VerifyUserView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Search",
        icon: "fas fa-search",
				route: "search",
				component: "SearchView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
      {
        title: "Change e-mail",
        icon: "fas fa-envilope",
				route: "changeemail/:id/:code",
				component: "ChangeEmailView",
				module: "pe-basic-module", 
				is_cabinet: false,
        is_left: Layouts().template.is_left, 
				menu: "extended_routes"
      },
    ]

    const routers = []; 
    const overs = []; 
    const grands = []; 
    //const grandgrands = []
    // console.log(routing);
    routing.forEach((e, i) => {
      if (e.children && e.children.length > 0) {
        e.children.forEach((elem, n) => {
          if (elem.children && elem.children.length > 0) {
            elem.children.forEach((element, nn) => {
              grands.push(this.searchRouteData(nn, e, elem, element))
            })
          }
          overs.push(this.searchRouteData(n, e, elem))
        })
      }
      routers.push(this.searchRouteData(i, e))
    })
    const NoMatchView = components.NoMatchView.default
    const SettingsView = components.SettingsView ? components.SettingsView.default : NoMatchView
    const RegisterView = components.RegisterView  ? components.RegisterView.default : NoMatchView
    const LoginView = components.LoginView  ?  components.LoginView.default : NoMatchView
    const RememberPasswordView = components.RememberPasswordView  ?  components.RememberPasswordView.default : NoMatchView
    const ResetPasswordView = components.ResetPasswordView  ?  components.ResetPasswordView.default : NoMatchView
    const FinishRestorePasswordView = components.FinishRestorePasswordView  ?  components.FinishRestorePasswordView.default : NoMatchView
    const ChangeEmailView = components.ChangeEmailView  ?  components.ChangeEmailView.default : NoMatchView
    const VerifyUserView = components.VerifyUserView  ?  components.VerifyUserView.default : NoMatchView
    const SearchView = components.SearchView  ?   components.SearchView.default : NoMatchView
    return (
      <Switch> 
        {this.searchRouteData(0, main, null, null, "")}
        {grands}
        {overs}
        {routers}

        {/* <Route
          path="/login/"
          strict={false}
          exact
          component={(routeProps) => (
            <LoginView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
              title={ __("Sign in") }
            />
          )}
          key="login"
        />
        <Route
          path="/register/"
          strict={false}
          exact
          component={(routeProps) => (
            <RegisterView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="register"
        />
        <Route
          path="/remember/"
          strict={false}
          exact
          component={(routeProps) => (
            <RememberPasswordView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="remember"
        />
        <Route
          path="/cog/"
          strict={false}
          exact
          component={(routeProps) => (
            <SettingsView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="cog"
        />
        <Route
          path="/reset/"
          strict={false}
          exact
          component={(routeProps) => (
            <ResetPasswordView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="reset"
        />
        <Route
          path="/restore/:id/:code"
          exact
          component={(routeProps) => (
            <FinishRestorePasswordView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="restore"
        /> 
        <Route
          path="/search/"
          exact
          component={(routeProps) => (
            <SearchView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="search"
        />
        <Route
          path="/changeemail/:id/:code"
          exact
          component={(routeProps) => (
            <ChangeEmailView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="changeemail"
        />       
        <Route
          path="/verify/:id/:code"
          exact
          component={(routeProps) => (
            <VerifyUserView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key="verify"
        />
        */} 
        { 
          initArea( 
            "main-routes", 
            { ...this.props },
            <Route
              component={(routeProps) => (
                <NoMatchView
                  onChangeStyle={(style) => this.props.onChangeStyle(style)}
                  user={this.props.user}
                />
              )}
            />
          ) 
        }
        <Route
          path="*"
          component={(routeProps) => (
            <NoMatchView
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
        />
        <Redirect to="/404" />
      </Switch>
      
    )
  }

  /*
    @i - (string | int) key index
    @e - (object)- layouts.json element (in menu, profile, extended_routes, bells, comments, help)
    @child - (object) child of @e
    @grandchild - (object) child of @child
    @forceRoute - routee's URL forced up the object.route
    return Route
  */
  searchRouteData(i,
    e = undefined,
    child = undefined,
    grandchild = undefined,
    forceRoute = undefined) {
    const routeData1 = routeData(e, child, grandchild, forceRoute)
    if (isCapability(routeData1.capability, this.props.user)) {

    } 
    else 
    {
      try{
        let rt = this.returnedRoute(i, routeData1.currentE, routeData1.preroute, routeData1.route, routeData1.noexact_route)
        return rt
      }
      catch(errpr)
      {
        console.log(errpr)
        console.log("e", e)
        console.log("child", child)
        console.log("grandchild", grandchild)
        console.log("forceRoute", forceRoute)
        return null
      }
      
    }
  }

  returnedRoute(i, currentE, preroute, route, noexact_route) {
    // const is = `${preroute}/${route}` === this.props.location.pathname
    //   || `${preroute}/${route}/` === this.props.location.pathname
    
    const NoMatchView = components.NoMatchView.default
    const DataTableView = components.DataTableView? components.DataTableView.default : NoMatchView
    const DataView = components.DataView ? components.DataView.default : NoMatchView
    const HTMLView = components.HTMLView  ? components.HTMLView.default : NoMatchView
    const HTMLSourceVew = components.HTMLSourceVew  ? components.HTMLSourceVew.default  : NoMatchView   
    const SingledDataTypeView = components.SingledDataTypeView ?  components.SingledDataTypeView.default : NoMatchView
    const FeedDataTypeView = components.FeedDataTypeView  ? components.FeedDataTypeView.default : NoMatchView

    //console.log( currentE )
    if (currentE.html_source) {
      return (
        <Route
          exact 
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <HTMLSourceVew
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={i}
        />
      )
    } 
    else if (currentE.html) 
    {
      return [
        <Route
          exact
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <HTMLView
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={`${i}_2`}
        />,
      ]
    } 
    else if (currentE.redirect)
    {
      return [
        <Route
          exact
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <Redirect to={currentE.redirect} />
          )}
          key={`${i}_2`}
        />,
      ]
    } 
    else if (currentE.singled_data_type) 
    {
      const schemaElement = Layouts().schema[ currentE.singled_data_type ]
      const SingleComponento = schemaElement && schemaElement.view
        ?
        components[ schemaElement.view].default 
        :
        SingledDataTypeView
      return [
        <Route
          strict
          path={`${preroute}/${noexact_route}`}
          render={(routeProps) => (
            <SingleComponento
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              path={`${preroute}/${noexact_route}`}
              route={`${preroute}/${route}`}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key={`${i}_2`}
        />,
      ]
    } 
    else if (currentE.feed_data_type) {
      return [
        <Route
          strict
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <FeedDataTypeView
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              route={`${preroute}/${route}`}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
              user={this.props.user}
            />
          )}
          key={`${i}_2`}
        />,
      ]
    } 
    else if (currentE.single_data_type) {
      // console.log( currentE );
      // console.log( preroute + '/' +  route );
      return (
        <Route
          strict
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <DataView
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              preroute={preroute}
              route={`${preroute}/${route}`}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={`${i}_2`}
        />
      )
    } else if (currentE.data_type) {
      // console.log( currentE.route);

      return [
        <Route
          strict
          path={`${preroute}/${noexact_route}`}
          render={(routeProps) => (
            <DataView
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              route={`${preroute}/${route}`}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={`${i}_2`}
        />,
        <Route
          exact
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <DataTableView
              extend_params={{ ...Layouts().template.extend_params }}
              {...currentE}
              route={`${preroute}/${route}`}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={`${i}_1`}
        />,
      ]
    } else if (currentE.component) {
      try {
        const ElComponento = components[currentE.component].default 
        //const ElComponento = allComponents[currentE.component]
        
        return [
          <Route
            strict
            path={`${preroute}/${noexact_route}`}
            component={(routeProps) => (
              <Suspense fallback={<Loading />}>
                <ElComponento
                  extend_params={{ ...Layouts().template.extend_params }}
                  {...currentE}
                  preroute={preroute}
                  onChangeStyle={(style) => this.props.onChangeStyle(style)}
                  user={this.props.user}
                />
              </Suspense>
            )}
            key={`${i}_1`}
          />,
          <Route
            exact
            path={`${preroute}/${route}`}
            render={(routeProps) => (
              <Suspense fallback={<Loading />}>
                <ElComponento
                  extend_params={{ ...Layouts().template.extend_params }}
                  {...currentE}
                  preroute={preroute}
                  onChangeStyle={(style) => this.props.onChangeStyle(style)}
                  user={this.props.user}
                />
              </Suspense>
            )}
            key={`${i}_2`}
          />,
        ]
      } catch (e) {
        return [
          <Route
            exact
            path={`${preroute}/${route}`}
            render={(routeProps) => (
              <NoMatchView
                extend_params={{ ...Layouts().template.extend_params }}
                title={__("No exists Component: ") + currentE.component}
                icon=""
                style_id={currentE.style_id}
                is_left={currentE.is_left}
                onChangeStyle={(style) => this.props.onChangeStyle(style)}
              />
            )}
            key={i}
          />,
        ]
      }
    } else{
      return [
        <Route
          exact
          path={`${preroute}/${route}`}
          render={(routeProps) => (
            <NoMatchView
              extend_params={{ ...Layouts().template.extend_params }}
              title="- 404 -"
              icon=""
              style_id={currentE.style_id}
              is_left={currentE.is_left}
              onChangeStyle={(style) => this.props.onChangeStyle(style)}
            />
          )}
          key={i}
        />,
      ]
    }    
  }
}

export default compose(
  withRouter,
)(LayoutMain)

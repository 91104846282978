import React, {Component} from "react"; 
import ButtonList from "./ButtonList";
import buttons from "./buttons.json";
import Panel from "./Panel"; 
import compose from "recompose/compose";
import { withRouter } from "react-router";

import {  
	Icon, 
	Card,
	Intent
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-fest-admin-module/api";
import CabinetView from "../CabinetView";
import { moduleExists } from "react-pe-layouts";
import { AppToaster, Loading } from "react-pe-useful/dist";
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";

class SiteSingle extends Component
{
	allButtons = {...buttons }
	constructor(props)
	{
		super(props);
		// console.log(this.props.location.pathname.split("/")[4])
		const active = this.props.location.pathname.split("/")[4]
		this.state = {
			active: active ? active : "settings",
			active2: "content",
			currentButton: this.allButtons.settings,
			data:{},
			domain:"",
			url:"",
			description:"",
			icon:"",
			loading:true
		}
	}
	componentWillMount() 
	{
		//console.log(this.props.blog_id);
		_fetch("site", {id:this.props.blog_id})
			.then(data =>
			{
				if(!data.isError)
				{
					//console.log( data );
					this.setState({
						icon		: data.site.icon,
						url			: data.site.url,
						domain		: data.site.domain,
						blog_id		: data.site.blog_id,
						description	: data.site.description,
						data		: data.site.options,
						options		: data.site.options,
						loading		: false
					});
					IndexDB.save(data.site, "sites", this.props.blog_id, "peFestAdmin_mySites", "sites", 1)
				}
				else
				{
					IndexDB.find(
						"peFestAdmin_mySites",
						"sites",
						"sites",
						this.props.blog_id
					)
						.then(res => { 
							let siteData = res?.data
							if(siteData)
							{
								this.setState({
									icon		: siteData.icon,
									url			: siteData.url,
									domain		: siteData.domain,
									blog_id		: siteData.blog_id,
									description	: siteData.description,
									data		: siteData.options,
									options		: siteData.options,
									loading		: false
								});
							}
							else
							{
								this.setState({ 
									blog_id		: -1,
									loading		: false
								});
							}
							
						})
				}
			});
		SiteSingle.instance = this
	}
	render()
	{
		if(this.state.loading) 	return <Loading />
		const {icon, domain, blog_id, description} = this.state;
		// console.log(this.state.active)
		const children = this.allButtons[this.state.active].children && 
			Object.keys(this.allButtons[this.state.active].children).length > 0
			?
			Object.keys(this.allButtons[this.state.active].children)
				.filter( elem => !this.allButtons[this.state.active].children[elem].disabled )
					.map((elem, i) =>
					{
						const child = this.allButtons[this.state.active].children[elem]
						return <div 
							style={{
								//height:130, 
								backgroundColor:this.state.active2 == elem ? "white": "rgb(255, 110, 74)",
								color:this.state.active2 != elem ? "white": "rgb(32, 43, 51)",
								marginBottom:10,
								marginLeft:-5,
								marginRight:-5,
								cursor:"pointer",
								animationName: "wobble3",
								animationDuration: ".75s",
								animationDelay: ( .25 * i ) + "s",
								animationFillMode: "both",
								opacity:0
							}}
							className="uabtn"
							key={child.component}
							n={elem}
							onClick={this.onChildren}
						>
							<Icon icon={child.icon}  iconSize={20} /> <div className="small mt-sm-1">{__(child.title)}</div>
						</div>
					}) 
					: 
					null;
		return <Card interactive={false} className="w-100 py-0 node"  style={{paddingRight:0}}>
			<div className="row node">
				<div className="col-sm-2 fl uabtn-cont col-6 order-1" style={{ backgroundColor:"#202B33" }}>
					<ButtonList 
						active={this.state.active} 
						switchPanel={this.switchPanel} 
						blog_id={this.state.blog_id}
					/>
				</div>
				<div className="col-sm-8 col-12 order-sm-2 order-3">
					<Panel 
						active={this.state.active} 
						currentButton={this.state.currentButton} 
						data={this.state.data || { format:"", termin:"" }} 
						blog_id={this.state.blog_id}
						domain={this.state.domain}
						options={this.state.options}
						url={
							this.state.url
						}
					/>
				</div> 
				<div className="col-sm-2 fl uabtn-cont col-6 order-sm-3 order-2" style={{ backgroundColor:"#30404D" }}>
					<div 
						className={"uabtn pointer"}
						style={{ margin: "0 -15px 0 -15px", textAlign:"center", color:"#FF6E4A" }}
						onClick={this.props.onList}
					>
						<Icon icon={"undo"}  iconSize={20} /> <div className="small mt-1">{__("to list")}</div>
					</div>
					<div 
						className={"text-dark bg-light uabtn hidden"}
						style={{ margin: "0 -15px 0 -15px", textAlign:"center" }}						
					>
						<div className="">
							<img src={ icon } alt=""  height={40}/>
						</div>
						<div className="small mt-1">{this.props.domain}</div>
					</div>
					{ children }
				</div>
			</div>
		</Card>
	}
	onChildren = evt =>
	{
		const n = evt.currentTarget.getAttribute("n");
		this.switchPanel2(n);
	}
	switchPanel2 = n =>
	{
		if( !this.allButtons[this.state.active].children[n].command ) 
		{
			this.setState({ 
				active2:n, 
				currentButton:this.allButtons[this.state.active].children[n],
				data:{}
			});
		} 
		_fetch( this.allButtons[this.state.active].children[n].command, {id:this.state.blog_id} ).
			then(data =>
			{
				// console.log(data);
				// console.log( this.allButtons[this.state.active].children[n] );
				
				if(!data.isError)
				{
					this.setState({ 
						active2:n, 
						currentButton:this.allButtons[this.state.active].children[n],
						data:data.lead 
					})
					IndexDB.find(
						this.allButtons[this.state.active].children[n].indexDB_table,
						this.allButtons[this.state.active].children[n].indexDB_objectName, 
						this.allButtons[this.state.active].children[n].indexDB_keyName, 
						this.state.blog_id
					)
						.then(res => { 
							//console.log( data.lead )
							//console.log( res )
							let posts = {}
							const resData = res ? res.data : []
							if( this.allButtons[this.state.active].children[n].indexDB_field)
							{
								posts = {...resData}
								posts[ this.allButtons[this.state.active].children[n].indexDB_field ] = data.lead?.post
								const fields = this.allButtons[this.state.active].children[n].indexDB_fields
								if( fields )
								{
									Object.keys(fields).map(key => {
										if(key !== "post")
											posts[ fields[key] ] = data.lead[ fields[key] ]
									})
								}
							}
							else
							{
								posts = {...resData, ...data.lead}  
							}							
							IndexDB.save(
								posts,
								this.allButtons[this.state.active].children[n].indexDB_keyName,
								this.state.blog_id,
								this.allButtons[this.state.active].children[n].indexDB_objectName,
								this.allButtons[this.state.active].children[n].indexDB_table,
								1
							)
						}) 
				}
				else
				{
					if( this.allButtons[this.state.active].children[n].indexDB_table )
					{
						IndexDB.find(
							this.allButtons[this.state.active].children[n].indexDB_table,
							this.allButtons[this.state.active].children[n].indexDB_objectName, 
							this.allButtons[this.state.active].children[n].indexDB_keyName, 
							this.state.blog_id
						)
							.then(res => {
								if(res?.data)
								{
									const _data =  this.allButtons[this.state.active].children[n].indexDB_field 
										?
										{ post: res.data[ this.allButtons[this.state.active].children[n].indexDB_field ] }
										:
										res.data 
									const fields = this.allButtons[this.state.active].children[n].indexDB_fields
									if( fields )
									{
										Object.keys(fields).map(key => {
											if(key !== "post")
											{
												//console.log( fields[ key ] )
												_data[fields[key]] = res.data[ fields[ key ] ]
											}												
										})
									}
									//console.log( res.data )
									//console.log( this.allButtons[this.state.active].children[n].indexDB_field )
									//console.log( _data, fields )
									this.setState({
										active2:n, 
										currentButton:this.allButtons[this.state.active].children[n],
										data : _data
									})
								} 								
								else
								{
									AppToaster.clear()
									AppToaster.show({
										intent:Intent.DANGER,
										icon:"error",
										message:__("Error load restored presets. No internet connection.")
									}) 
								}
							})
					}
					else
					{
						this.setState({
							active:n, 
							active2: this.allButtons[this.state.active].children[n].children ? Object.keys(this.allButtons[n].children)[0] : 0, 
							currentButton:this.allButtons[this.state.active].children[n],
							data : {}
						})
					}
				}
			});
		
	}
	switchPanel = n =>
	{
		if( !this.allButtons[n].command ) {
			this.setState({ 
				active:n, 
				active2: this.allButtons[n].children ? Object.keys(this.allButtons[n].children)[0] : 0, 
				currentButton:this.allButtons[n],
				data:{} 
			});
		}
		_fetch( this.allButtons[n].command, { id: this.state.blog_id } ).
			then(data =>
			{
				// console.log(this.allButtons[n]);
				if(!data.isError)
				{					
					// console.log(data);
					this.setState({ 
						active:n, 
						active2: this.allButtons[n].children ? Object.keys(this.allButtons[n].children)[0] : 0, 
						currentButton:this.allButtons[n],
						data:data.lead 
					});
					IndexDB.find(
						this.allButtons[n].indexDB_table,
						this.allButtons[n].indexDB_objectName, 
						this.allButtons[n].indexDB_keyName, 
						this.state.blog_id
					)
						.then(res => { 
							// console.log( res )
							const resData = res ? res.data : []
							let posts = {}
							if( this.allButtons[n].indexDB_field)
							{
								posts = {...resData}
								posts[ this.allButtons[n].indexDB_field ] = data.lead?.post
							}
							else
							{
								posts = {...resData, ...data.lead}
							}							
							IndexDB.save(
								posts,
								this.allButtons[n].indexDB_keyName,
								this.state.blog_id,
								this.allButtons[n].indexDB_objectName,
								this.allButtons[n].indexDB_table,
								1
							)
						})
					// this.props.history.push( 
					// 	`/${CabinetView.WPFESTAdminRoute}/site/${this.state.blog_id}/${n}`
					// )
				}
				else
				{
					if( this.allButtons[n].indexDB_table )
					{
						IndexDB.find(
							this.allButtons[n].indexDB_table,
							this.allButtons[n].indexDB_objectName, 
							this.allButtons[n].indexDB_keyName, 
							this.state.blog_id
						)
							.then(res => {
								if(res?.data)
								{
									const _data =  this.allButtons[n].indexDB_field 
										?
										{ post: res.data[ this.allButtons[n].indexDB_field ] }
										:
										res.data 
									//console.log( res.data )
									//console.log( this.allButtons[n].indexDB_field )
									//console.log( _data )
									this.setState({
										active:n, 
										active2: this.allButtons[n].children ? Object.keys(this.allButtons[n].children)[0] : 0, 
										currentButton:this.allButtons[n],
										data : _data
									})
								}
								else
								{
									AppToaster.clear()
									AppToaster.show({
										intent:Intent.DANGER,
										icon:"error",
										message:__("Error load restored presets. No internet connection.")
									}) 
								} 
							})
					}
					else
					{
						this.setState({
							active:n, 
							active2: this.allButtons[n].children ? Object.keys(this.allButtons[n].children)[0] : 0, 
							currentButton:this.allButtons[n],
							data : {}
						})
					}
				}
				
			});
		
	}
}
export default compose( withRouter )( SiteSingle )
import React, {Component, Fragment, useState} from "react";  

import {   
	Intent, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, 
	Tag
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist"; 
import GanreRange from "modules/pe-fest-admin-module/utilities/GanreRange";
import FieldInput from "react-pe-scalars/dist";
import ProjectUserForm from "./forms/ProjectUserForm";
 
export default class ProjectForm extends Component
{
	state = {
		post_title 		: this.props.post_title,
		post_content	: this.props.post_content,
		thumbnail		: this.props.thumbnail,
		fmru_track		: this.props.fmru_track,
		fmru_honeycombs: this.props.fmru_honeycombs,
		color 			: this.props.color,
		leader_id 		: this.props.leader_id,
		tutor_id 		: this.props.tutor_id,
		member_0 		: this.props.member_0,
		member_1 		: this.props.member_1,
		member_2 		: this.props.member_2,
		member_3 		: this.props.member_3,
		member_4 		: this.props.member_4,
		member_5 		: this.props.member_5,
		member_6 		: this.props.member_6,
		member_7 		: this.props.member_7,
		member_8 		: this.props.member_8,
		member_9 		: this.props.member_9,
		prize	 		: this.props.prize,
		nomination 		: this.props.nomination,
		order 			: parseInt( this.props.isNew ? this.props.meta.order : this.props.order),
		form_fields 	: this.props.form_fields,
		fmru_users		: this.props.meta.fmru_users, 
		fmru_ganre		: this.props.meta.fmru_ganre && this.props.meta.fmru_ganre.length 
			? this.props.meta.fmru_ganre.map(elem => elem.id)
			: []
	} 
	onValue = (value, field, i, ii) => {
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state[field] = value
		console.log( value, field, i, ii )
		console.log( state )
		this.setState( state );
	} 
	onTrack = (value, field) => {
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state.fmru_track = this.props.meta.fmru_tracks.filter(track => track.id === value)[0]
		state.fmru_track_id = value
		console.log( state )
		this.setState( state );
	} 
	onHoneycombs = (value, field) => {
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state.fmru_honeycombs = this.props.meta.fmru_honeycombs.filter(track => track.id === value)[0]
		state.fmru_honeycombs_id = value
		console.log( state )
		this.setState( state );
	} 
	title = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("post_title", txt, this.props.ID);
		this.setState({post_title : txt});
	}
	onChangeThumbnail = (thumbnail, name, id, data) =>
	{
		//thumbnail
		console.log( thumbnail )
		this.setState({ thumbnail,  thumbnail_id:null,  thumbnail_name: data.thumbnail_name });
	}
	onOrder = n => 
	{
		//console.log( n );
		this.setState({order : n});
	}
	onDescrEdit = evt =>
	{ 
		this.setState({ post_content : evt.currentTarget.value });
	}
	onCategory = n =>
	{
		this.setState({fmru_ganre : n});
	}
	onSave = () =>
	{
		let state = {...this.state}
		state.fmru_track 		= state.fmru_track_id
		state.fmru_honeycombs 	= state.fmru_honeycombs_id
		console.log( state );
		this.props.onSave( state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	}
	onFormFields = values =>
	{
		console.log(values)
		this.setState({ form_fields: values})
	}
	render()
	{		
		// console.log(this.state.fmru_honeycombs.post_title );
		const del_btn = this.props.isNew 
			? 
			null 
			: 
			<Popover
				position={Position.TOP_LEFT}
				content={
					<div className="square p-3">
						<div>
							<p>
								{__("Are you realy want delete?")}
							</p>
							<ButtonGroup className="p-2 tex-right">
								<Button
									intent={Intent.DANGER}
									text={__("Yes")}
									icon="cross"
									onClick={this.onDelete}
								/>
							</ButtonGroup>
						</div>							
					</div>
					
				}
			>
				<Button
					text={__("Delete")}
					icon="cross"
				/>
			</Popover>  
		return <Fragment>
			<FieldInput
				type="string"
				description={ __( "insert title by any symbols" ) }
				title={ __( "Title" )}
				field={ 'post_title' }
				value={ this.state.post_title }
				onChange={ this.onValue }
			/>
			<FieldInput
				type="number"
				description={ __( "Position this Prjoject's in list" ) }
				title={ __( "Order" )}
				field={ 'order' }
				value={ this.state.order }
				onChange={ this.onValue }
			/> 
			<FieldInput
				type="media" 
				title={ __( "Thumbnail" )}
				field={ 'thumbnail' }
				value={ this.state.thumbnail }
				onChange={ this.onChangeThumbnail }
			/>  
			<FieldInput
				type="html" 
				title={ __( "Description" )}
				field={ 'post_content' }
				value={ this.state.post_content }
				onChange={ this.onValue }
			/>   
			<FieldInput
				type="string" 
				title={ __( "Prize:" )}
				field={ 'prize' }
				value={ this.state.prize }
				onChange={ this.onValue }
			/>   
			<FieldInput
				type="string" 
				title={ __( "Nomination:" )}
				field={ 'nomination' }
				value={ this.state.nomination }
				onChange={ this.onValue }
			/>    
			<FieldInput
				visualization="festFormsFields" 
				type="tags" 
				editable
				title={ __( "Track" )}
				field={ 'fmru_track_id' } 
				value={ this.state.fmru_track?.id }
				values={ this.props.meta.fmru_tracks.map((track, i) => {
					return {
						_id:   track.ID,
						title: track.post_title
					}
				}) }
				onChange={ this.onTrack }
			/>
			<FieldInput
				visualization="festFormsFields" 
				type="tags" 
				editable
				title={ __( "Honeycambs" ) }
				field={ 'fmru_honeycombs_id' } 
				value={ this.state.fmru_honeycombs?.ID }
				values={ this.props.meta.fmru_honeycombs.map((honeycomb, i) => {
					return {
						_id:   honeycomb.ID,
						title: honeycomb.post_title
					}
				}) }
				onChange={ this.onHoneycombs }
			/>
			{
				this.props.meta.festivalOptions?.fmru_evlbl_roles.filter( e => e === "Project_author" || e === "Tutor" )
					?
					<ProjectUserForm 
						{ ...this.state }
						fmru_evlbl_roles={ this.props.meta.festivalOptions?.fmru_evlbl_roles }
						on={ this.onValue }
					/>
					:
					null
			}
			<FormFieldForms
				form_data={this.props.meta.festivalOptions.form_fields}
				values={this.state.form_fields}
				onChange={ this.onFormFields }
			/>  
			{
				this.props.meta.festivalOptions.is_ganres
					?
					<FieldInput
						type="image_checkbox" 
						title={ __( "Parent Ganre" )}
						field={ 'fmru_ganre' }
						value={ this.state.fmru_ganre }
						values={ this.props.meta.fmru_ganres.map((ganre, i) => {
							return {
								_id : ganre.id,
								title:ganre.post_title,
								img:ganre.icon,
								height:75

							}
						}) }
						onChange={ this.onValue }
					/> 
					:
					null
			} 

			{
				this.props.meta.festivalOptions.is_ganres && false
					?
					<FormGroup
						label={__("Parent Ganre")}
						helperText="Choose Ganre"
						className="p-2"
					>
						<GanreRange
							fmru_ganres={this.props.meta.fmru_ganres}
							name={"ganres"}
							checked={this.state.fmru_ganre}
							onChange={this.onCategory}
						/>
					</FormGroup>
					:
					null
			}
			
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}
// ({form_data, values})
export const FormFieldLabels = props =>
{
	const [values, setValues] = useState(
		Array.isArray( props.form_data )
			?
			props.form_data
			:
			[ [ ] ]
	)
	return Array.isArray(props.form_data)
		?
		props.form_data.map((fd, i) =>
		{
			let form = ""
			switch(fd.type)
			{  
				case "string":
				default:
					form = <div className="d-flex flex-wrap align-items-center pl-2" key={i}>
						<div
							className="pr-3"
						>
							{__(fd.name)}:
						</div>
						{
							Array(fd.count).fill(1).map((fm, ii) =>
							{
								return <Tag className="px-3 m-1" minimal round key={ ii + "_" + i }>
									{values && values[i] ? values[i][ii] : "-"}
								</Tag> 
							})
						}								
					</div>
			}
			return <div key={i}>
				{form}
			</div>
		})
		: 
		null
}
export const FormFieldForms = props =>
{
	const [values, setValues] = useState(
		Array.isArray(props.values)
			?
			props.values
			:
			[ [ ] ]
	)
	const changeValue = (evt, formOrder, inputOrder) =>
	{
		let vals = [...values]
		const newVal = evt.currentTarget.value
		if(!vals[formOrder]) vals[formOrder] = []
		vals[formOrder][inputOrder] = newVal
		setValues(vals)
		props.onChange( vals )
	}
	const onChangeFieldField = ( value, formOrder, inputOrder ) =>
	{
		let vals = [...values] 
		if( !vals[ formOrder ] ) vals[ formOrder ] = []
		vals[ formOrder ][ inputOrder ] = value
		setValues( vals )
		props.onChange( vals ) 
	}

	return Array.isArray(props.form_data)
		?
		props.form_data.map((fd, i) =>
		{  
			return <FieldInput
				key={ i }
				i={ i }
				value={ values[i] }
				type="field_field"
				visualization="festFormsFields" 
				form_data={fd}
				ptype={ fd.type }
				count={ fd.count }
				title={ fd.name }
				fdValues={ fd.values }
				values={ 
					typeof fd.values === "string"
						?  
						fd.values.split("/").map(field => {
							return field._id
								?
								field
								:
								{ _id:field, title:field, color:"#111111" }
						})
						: 
						[] 
					}
				commentary={ fd.description }
				editable={ !props.disabled }
				onChange={ onChangeFieldField }
				not_clear 
			/> 

			// let form = ""
			// switch(fd.type)
			// { 
			// 	case "url":
					
			// 		//console.log( values[0][0] ) 
			// 		form = <FormGroup
			// 			label={__(fd.name)}
			// 			disabled={props.disabled}
			// 			helperText={__(fd.description)}
			// 			className="p-2"
			// 		>
			// 			{
			// 				Array(fd.count).fill(1).map((fm, ii) =>
			// 				{
			// 					return <InputGroup
			// 						key={ii} 
			// 						leftIcon="desktop" 
			// 						fill={true}  
			// 						large={true}  
			// 						value={values && values[i] ? values[i][ii] : ""}
			// 						onChange={ (evt) => changeValue(evt, i, ii) }
			// 					/>
			// 				})
			// 			}						
			// 		</FormGroup>
			// 		break;
			// 	case "string":
			// 	default:
			// 		form =<FormGroup
			// 			label={__(fd.name)}
			// 			helperText={__(fd.description)}
			// 			className="p-2"
			// 		>
			// 			{
			// 				Array(fd.count).fill(1).map((fm, ii) =>
			// 				{
			// 					return <InputGroup
			// 						key={ii}  
			// 						leftIcon="new-text-box" 
			// 						fill={true}  
			// 						large={true}  
			// 						value={values && values[i] ? values[i][ii] : ""}
			// 						onChange={ (evt) => changeValue(evt, i, ii)}
			// 					/>
			// 				})
			// 			}	
						
			// 		</FormGroup>
			// }
			// return <div key={i}>
			// 	{form}
			// </div>
		})
		: 
		null
}
import React, { Component,   } from "react"
import {  Link, withRouter } from "react-router-dom" 
import _fetch from "../.."
import { __ } from "react-pe-utilities"  
import { withFestStore } from "modules/pe-fest-module/data/store"
import { compose } from "recompose"
import MemberRatingDisplay from "../MemberRatingDisplay"
import HoneycombsLabel from "modules/pe-fest-module/views/honeycombs/HoneycombsLabel"
import TrackLabel from "modules/pe-fest-module/views/track/TrackLabel"

class FestMemberSimpleStroke extends Component {
  onTrackClick = evt =>
  {
    //console.log("CLICK")
    evt.stopPropagation()
    evt.preventDefault()
    this.props.history.push( `${this.props.route}/track-moderator` )
  }
  drawSearchedTitle = () =>
  {
    const { title, store } = this.props 
    const slice = title.toString().toLowerCase().indexOf( store.searchString.toLowerCase() ) 
    if( slice < 0 )
    {
      return title
    } 
    else
    {
      const start = title.substring( 0, slice )
      const stroke = title.substring( slice, slice + store.searchString.length )      
      const end = title.substring(  slice + store.searchString.length )
      console.log( store.searchString.toLowerCase(), store.searchString.length, slice, start, stroke, end )
      return <>
        <span>{start}</span>
        <span className="bg-warning">{stroke}</span>
        <span>{end}</span>
      </>
    }
  }
  render() { 
    //console.log(this.props)
    const {
      id, o, e, img, ganre, title, content, ganres, track, honeycombs, festData
    } = this.props   
    const url = `${this.props.route}/member/${id}`
    const titleLabel = this.props.store.searchString 
      ?
      this.drawSearchedTitle()
      :
      title
    return (
      <div className="w-100 grey2 position-relative overflow-hidden stroke-a-dark"> 
        <Link to={url} className="row hover">  
          <div  className="col-lg-4 col-10 d-flex  py-2 align-items-center" >  
            <div className="px-4">{ o } </div>  
            <div>
              <h6  className="display-7 m-0 flex-grow-100 text-center text-lg-left" >
                { titleLabel }
              </h6>     
              <div className="text-danger title">{this.props.prize}</div>  
              <div className="text-danger text-uppercase small">{this.props.nomination}</div>  
            </div>       
                              
          </div> 
          <div  className="col-lg-1 col-2 d-flex flex-column justify-content-center align-items-center align-items-lg-left px-3" >            
            <MemberRatingDisplay 
              correct_member_rate={this.props.correct_member_rate}
              countedRating={this.props.r2}
              className={"text-dark "}
            />         
          </div> 
          <div  className="col-lg-3 col-12  d-flex flex-column justify-content-center  px-3" >            
            <div  className="text-overflow"> 
              <TrackLabel 
                track={track} 
                className="title d-flex my-2 ml-2 text-center text-lg-left align-items-center"
              />
            </div>     
          </div> 
          <div  className="col-lg-2 col-12  d-flex flex-column justify-content-center  px-3" >            
            <div>  <HoneycombsLabel honeycombs={honeycombs}  className=" d-md-inline my-2 ml-2 text-center text-lg-left"/> </div>      
          </div> 
          <div  className="col-lg-2 col-12 d-flex flex-column justify-content-center" >            
          {
            this.props.store.myTracks.filter(mtID => mtID == track.ID).length > 0
              ?
              <div className="my-track-label-stroke" onClick={this.onTrackClick}>
                { __( "My Track" ) }
              </div>
              :
              null
          }
          {
            this.props.isMine
              ?
              <div className="mine-label-stroke">
                { __( "My project" ) }
              </div>
              :
              null
          }
          {
            this.props.isMineHoneycoms
              ?
              <div className="mine-honeycombs-label-stroke">
                { __( "My honeycombs" ) }
              </div>
              :
              null
          }         
          </div> 
        </Link>
            
            {/* */}
      </div>
    )
  }
}
export default compose( 
	withFestStore,
  withRouter
  )(FestMemberSimpleStroke)

import { Button, ButtonGroup, Intent, Tab, Tabs } from "@blueprintjs/core"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import _fetch from "modules/pe-fest-admin-module/api"
import User from "modules/pe-fest-admin-module/User"
import { setCookie } from "modules/pe-fest-admin-module/utilities/utils"
import React, { useState } from "react"
import FieldInput from "react-pe-scalars/dist" 
import { AppToaster } from "react-pe-useful/dist"
import { __ } from "react-pe-utilities/dist"
import { useHistory } from "react-router"
import { TOKEN_COOKIE_NAME, TOKEN_RAFRESH_COOKIE_NAME, WPFEST_ADMIN_MY_ROUTE } from "../CabinetView"

const LoginForm = props =>
{
    const [login, onLogin] = useState("")
    const [password, onPassword] = useState("")
    const [email, onEmail] = useState("")
    const [display_name, onDisplayName] = useState("")
    const [tab, onTab] = useState("login")
    const [isLoader, onLoader] = useState(false)
    const history = useHistory()
    const onAuth = () =>
    {
        _fetch("auth", { login, password })
            .then(data => 
                {
                    // console.log(data) 
                    onlogged(data)
                })	
    }
    const onRegister = () =>
    {
        if(!login)
        {
            AppToaster.show({
                intent:	Intent.DANGER,
                icon: "tick", 
                message: __("Login needed"),
            })
            return
        }
        if(!password)
        {
            AppToaster.show({
                intent:	Intent.DANGER,
                icon: "tick", 
                message: __("Password needed"),
            })
            return
        }
        if(!email)
        {
            AppToaster.show({
                intent:	Intent.DANGER,
                icon: "tick", 
                message: __("E-mail needed"),
            })
            return
        }
        _fetch("register_user", { login, password, email })
            .then(data => 
                {
                    //console.log(data) 
                    onlogged(data)
                })	
    }
    const onlogged = data =>
	{ 
		if(data.is_login)
		{ 
			setCookie( TOKEN_COOKIE_NAME, data.token, { expires : 72 * 3600 } ); 
			setCookie( TOKEN_RAFRESH_COOKIE_NAME, data.refresh, { expires : 30 * 24 * 3600 } ); 
			User.setData( data.user );
            IndexDB.save(
                data.user,
                "user",
                "user",
                "user",
                "peFestAdmin_user",
                1
            )
            //window.location.reload()
            history.go(0)
            //history.push( props.preroute + "/" + props.route + "/" + WPFEST_ADMIN_MY_ROUTE  )
			props.onClose()
		}
	}
    const onSetLogin = value =>
    {
        //console.log( value )
        onDisplayName( value )
    }
    return <div className="">
        <Tabs selectedTabId={tab} onChange={navID => onTab( navID )} className="p-0 tab-light-head ">
            <Tab id="register" title={__("Register new")} panel={
                <div className="p-0 d-flex flex-column" style={{ height: 530 }}>
                    <div className="p-4 ">                    
                        <FieldInput
                            value={ display_name }
                            type="string"
                            title={__("How can I call you?")}
                            vertical={true} 
                            onChange={ ( value ) => onSetLogin( value ) }
                        /> 
                        <FieldInput
                            value={ password }
                            type="password"
                            title={__("Password")}
                            vertical={true} 
                            onChange={ ( value ) => onPassword( value ) }
                        />
                        <FieldInput
                            value={ email }
                            type="email"
                            title={__("E-mail")}
                            commentary={__("This is your login to log in")}
                            vertical={true} 
                            onChange={ ( value ) => onEmail( value ) }
                        />
                    </div>
                    <ButtonGroup className="pt-4 mt-auto" large>
                        <Button
                            intent={Intent.DANGER}
                            icon="cross"
                            onClick={props.onClose}
                        >

                        </Button>
                        <Button
                            intent={Intent.SUCCESS} 
                            onClick={onRegister}
                        >
                            {__("Register new")}
                        </Button>
                    </ButtonGroup>
                </div>
            } 
            />
            <Tab id="login" title={__("Sign in")} panel={
                <div className="p-0 d-flex flex-column" style={{ height: 530 }}>
                    <div className="p-4 ">
                        <FieldInput
                            value={ login }
                            type="string"
                            title={__("Login")}
                            vertical={true} 
                            onChange={ ( value ) => onLogin( value ) }
                        />
                        <FieldInput
                            value={ password }
                            type="password"
                            title={__("Password")}
                            vertical={true} 
                            onChange={ ( value ) => onPassword( value ) }
                        />
                    </div>
                    
                    <ButtonGroup className="pt-4 mt-auto" large>
                        <Button
                            intent={Intent.DANGER}
                            icon="cross"
                            onClick={props.onClose}
                        >

                        </Button>
                        <Button
                            intent={Intent.SUCCESS} 
                            onClick={onAuth}
                        >
                            {__("Log in")}
                        </Button>
                    </ButtonGroup>
                </div>
            } />  
        </Tabs>
        
    </div>
}
export default LoginForm
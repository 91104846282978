import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { __ } from 'react-pe-utilities/dist'
import { Button } from '@blueprintjs/core'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import actions, { SET_SEARCH_FILTER_ACTION, SET_SEARCH_STRING_ACTION, SET_TRACK_FILTER_ACTION } from 'modules/pe-fest-module/data/actions'
import User from '../utilities/User'

const SearchMemberEngine = props => {
    const [search, setSearch] = useState( props.search || "")
    const onChange = evt =>
    {
        const search = evt.target.value
        setSearch(search)
        actions( SET_SEARCH_STRING_ACTION, { search })
    }
    const onSearch = () => actions( SET_SEARCH_FILTER_ACTION, { s: search } )
    const onClear = () =>
    {
        actions( SET_SEARCH_STRING_ACTION, { search: "" })
        actions( SET_TRACK_FILTER_ACTION, { tracks: User.id && User.isRole("Tutor") ? ["mine"] : [-1] } )
    }
    return <div className='flex-centered mr-md-auto mx-auto flex-md-row flex-column order-md-2 order-4'>
        <div className="small ">
            {__("Search Member")}
        </div>
        <div className="d-flex ">
            <input 
                type="text"
                className="w_250 input dark input dark p-1 ml-2"
                value={ useFestStore.getState().searchString }
                onChange={ onChange }
            />
            <Button icon="search" minimal onClick={onSearch} />
            {
                useFestStore.getState().searchString 
                    ?
                    <Button icon="cross" minimal onClick={onClear} />
                    :
                    null
            } 
        </div>
        
    </div>
}

SearchMemberEngine.propTypes = {}

export default SearchMemberEngine
import React, { Component } from "react"
import { compose } from "recompose"
import { withRouter, } from "react-router"
import _fetch from "."
import { initArea, __ } from "react-pe-utilities" 
import WPFestSettings from "./utilities/WPFestSettings"
import User from "./utilities/User"
import PhaseLabel from "./PhaseLabel"
import UserDescription from "./UserDescription"
import { Loading } from "react-pe-useful/dist"
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB"
import { Callout } from "@blueprintjs/core"
import FestHelp from "./core/FestHelp"
import actions, { UPDATE_FEST_ACTION } from "modules/pe-fest-module/data/actions"
import { useFestStore, withFestStore } from "modules/pe-fest-module/data/store"  

class FestComponent extends Component {
    fetchCommand = "list" 
    fetchArgs = {festId: this.props.festID}
    header_type = "members-list"
    constructor(props)
    {
        super(props) 
        this.state = {
            fest_members: [],
            mtype: "box",
            ganres: [],
            ganreFilterIDs: [],
            loading: true, 
            menuHeight: "0px",
            orderType: localStorage.getItem( "pefest-orderType") ? localStorage.getItem( "pefest-orderType"): "ndecs",
            ...this.setStartState()
        }
    }
    setStartState = () =>
    {
        return {}
    }
    setFirstUpdate = () =>
    {

    }  
    onComponentDidMount = () =>
    {        
         
    }
    componentWillUnmount()
    { 
    }
    componentDidMount() 
	{
        this.onComponentDidMount()
        const fst = useFestStore.getState().fests[ this.props.festID ] 
		this.getFestData(
            fst.url,
            {...this.fetchArgs, festId: this.props.festID },
            WPFestSettings.getToken( fst.url )
        )
	}
    setIndexDBData = () =>
    {
        IndexDB.find(
            "peFest", 
            "peFest",
            "id",
            parseInt(this.props.festID)
        )
            .then(dat => {  
                    if(dat.data) 
                    { 
                        this.setData(dat.data.url, dat.data)
                    }
                    else
                    {
                        this.setState({
                            loading: false,
                            isWrong: true
                        })
                    } 
                })
    }
    onGetFestData = (data) =>
    {

    }
    getFestData = (url, args, token) =>
	{
        // IndexDB.find(
        //     "peFest", 
        //     "peFest",
        //     "id",
        //     parseInt(this.props.festID)
        // )
        //     .then(dat => { 
        //             if(dat?.data) { 
        //                 this.setData(url, dat.data)
        //             }
        //         })
        actions(
            UPDATE_FEST_ACTION, 
            { 
                id: parseInt(this.props.festID),
                fest: {
                    url
                }  
            }
        )
        if(this.fetchCommand)
        {
            _fetch(
                "get_fest_command",
                {...args, command: this.fetchCommand},
                WPFestSettings.serviceURL,
                token,
                "wpfa",
            )
                .then((fetchData) => {
                    const data = fetchData.data  
                    if(data)
                    {
                        //console.log(data)
                        this.setData(url, { ...data, routLoaction : this.props.location })
                    } 
                    else
                    {
                        
                    }
                })
        }
		else
        {
            this.setData(url, {})
        }
	}
    setData = (url, data) =>
    {
        // console.log( data )
		const _this = this
        IndexDB.find(
            "peFest", 
            "peFest",
            "id",
            parseInt(this.props.festID)
        )
            .then(dat =>
                {
                    const d = {...dat?.data, ...data} 
                    IndexDB.save(
                        d, 
                        "id", 
                        parseInt(this.props.festID), 
                        "peFest", 
                        "peFest", 
                        1 
                    )
                })  
        actions( this.fetchCommand, data ) 
        const mtype = localStorage.getItem("pefest-mtype")         
        User.setData(data) 
        WPFestSettings.set( { ...data, url, festId: this.props.festID} )  
        _this.setState({
            all_honeycombs: data.all_honeycombs,
            my_honeycombs: data.my_honeycombs,
            title: "",
            img:"",
            festival_reglament: data.festival_reglament,
            festival_reglament_title: data.festival_reglament_title,
            festival_title: data.festival_title,
            festival_description: data.festival_description,
            fest_members: data.members,
            my_members: data.my_members,
            is_ganres: data.is_ganres,
            is_expert: data.is_expert,
            is_diaries: data.is_diaries,
            is_register: data.is_register,
            logo: data.logo,
            colm: data.colm,
            max_raiting: data.max_raiting,
            status: data.status,
            member_card_height: data.member_card_height,
            ganres: data.ganres || [],
            content: data.content,
            honeycombs: data.honeycombs,
            tracks: data.tracks,
            ganreFilterIDs: Array.isArray(data.ganres) ?  data.ganres.map((e) => e.id) : [],
            mtype: mtype ? mtype : data.mtype,
            categories: data.categories,
            orphans: data.orphans,
            options : data.options,
            milestones : data.milestones,
            rating_type : data.rating_type,
            form_fields: data.form_fields,
            fest_form_fields: data.fest_form_fields,
            uniqs: data.uniqs,
            aut_criteries: data.aut_criteries,
            owners: data.owners,
            denabled: data.denabled,
            rait: data.rait,
            experts: data.experts || [],
            r2: data.r2, 
            is_experts_criteries: data.is_experts_criteries, 
            ext: data.ext,
            role_requests: data.role_requests,
            default_image: data.default_image,
            correct_member_rate: data.correct_member_rate,
            ...data.member,
            loading: false
        })
        
        // _this.onGetFestData( data )
        setTimeout(() => {
            this.setFirstUpdate()
        }, 1000) 
    }
    getCenterMenu = () => 
    {
        return null
    }
    addRender = () => {
        return "addRender"
    }
    render = () => {
        if( this.state.loading ) 
        {
            return <div className="layout-state p-0 m-0">
                <Loading />
            </div>
        }
        if( this.state.isWrong )
        {
            return this.byWrong();
        } 
        else
        {
            return this.byEnabled();
        }
        
    }
    byWrong = () =>
    {
        return <div className=" container w-100 h-100 flex-centered">
            <Callout icon="error">
                <div className="p-4 lead">
                {
                    __("No festival")
                }    
                </div>
                
            </Callout>
        </div>
    }
    byEnabled = () =>
    { 
        // console.log( useFestStore.getState().honeycombs )
        return <div className=" container-float position-relative " >
            <div 
                className=" container " 
                id="fest-header"
                style={{ }} 
            >
                { 
                    initArea(
                        "fest-header",
                        {
                            ...this.props,
                            ...this.state,
                            honeycombs: useFestStore.getState().honeycombs,
                            festID: this.props.festID,
                            url: WPFestSettings.url,
                            header_type: this.header_type,
                        },
                    )
                }
            </div>
            <div style={{height:this.state.menuHeight}} />
            <div className=" container ">
                <div className="row" id="fest-phase-labels">
                    <div className="col-12  pt-1">
                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                            <PhaseLabel phase={this.state.status} />
                            { this.getCenterMenu() } 
                            <UserDescription user={this.props.user} />
                        </div>
                    </div>
                </div>
                {this.addRender() }
            </div>
            <FestHelp
                {...this.props}
                {...this.state}
            />
        </div>
    }
}
export default FestComponent
import { Button, ButtonGroup, Card, Dialog, Intent, Tabs, Tab } from "@blueprintjs/core"
import _fetch from "modules/pe-fest-admin-module/api"
import React from "react"
import { useEffect, useState } from "react" 
import { Loading } from "react-pe-useful/dist"
import { Collapse } from "@blueprintjs/core"
import { sprintf, __ } from "react-pe-utilities/dist"
import shot2 from "../../../../assets/img/Screenshot_2.jpg"
import shot3 from "../../../../assets/img/Screenshot_3.jpg"
import shot4 from "../../../../assets/img/Screenshot_4.jpg"
import shot5 from "../../../../assets/img/Screenshot_5.jpg"
import shot6 from "../../../../assets/img/Screenshot_6.jpg"
import shot7 from "../../../../assets/img/Screenshot_7.jpg"
import shot8 from "../../../../assets/img/Screenshot_8.jpg"
import shot9 from "../../../../assets/img/Screenshot_9.jpg"
import YFCard from "./YFCard"

const YFThreadsForm = props =>
{
    const [isLoader, onLoader] = useState(true)
    const [isPrepaire, onPripaire] = useState(false)
    const [prepaireDeleteID, onPrepaireDeleteID] = useState(false)
    const [threads, setThreads] = useState([])
    const [blogURL, setBlogURL] = useState('')
    const [tabID, setTabID] = useState('rx')
    const [is_yndex_forms, setIs_yndex_forms] = useState( false )
    const [isCollapseOpen, onCollapseOpen] = useState(false)
    useEffect(() =>
    {        
        _fetch( 
            "get_yandex_forms_threads_list", 
            { 
                blog_id	: props.blog_id 
            } 
        )
            .then(data => 
                {
                    //console.log( data ) 
                    if(data.is_yndex_forms)
                    {
                        setIs_yndex_forms( true )
                        setThreads( data.threads )
                        setBlogURL( data.url )
                    }
                    else
                    {

                    }
                    onLoader( false )
                })	
    }, [])
    if(isLoader) return <Loading />
    if(!is_yndex_forms)
    {
        return <Card elevation={0} interactive="true" className="p-4 text-center text-danger lead ">
            {__("Yandex Form integration disabled")}
        </Card>
    }
    const changeTreadField = (treadId, value, field, f1, f2) =>
    {
        //console.log(treadId, value, field, f1, f2)
        let threadds = [...threads]
        threadds = threadds.map((thread, i) =>
        {
            if(thread.ID === treadId)
            {
                thread[field] = value
                _fetch("update_yf_thread_field", {post_data: thread, ID: treadId, blog_id: props.blog_id })
                    .then(data => console.log(data))
            }
            return thread
        })
        setThreads(threadds)
    } 
    const prepaireDeleteThread = threadID =>
    {
        onPrepaireDeleteID(threadID)
        onPripaire(!isPrepaire)
    }
    const deleteThread = treadId =>
    {
        let threadds = [...threads].filter(tr => tr.ID !== treadId)
        setThreads(threadds)
        _fetch("delete_yf_thread_field", { ID: treadId, blog_id: props.blog_id })
            .then(data =>  onPripaire(false))
    }
    const addThread = () =>
    {
        _fetch("add_yf_thread_field", { blog_id: props.blog_id })
            .then(data => {
                setThreads( data.threads )   
            })
    } 
    const cards = threads.map((thread, i) => {
        
        return <YFCard 
            key={i}
            thread={thread}
            prepaireDeleteThread={prepaireDeleteThread}
            changeTreadField={changeTreadField} 
        />
    })
    const handleTabChange = tabID =>
    {

    }
    return <>
        <div className=" lead title mb-3">{__("Yandex forms threads")}</div>
        <div className="p-3">            
            <Tabs id="TabsExample" onChange={ tabID => setTabID(tabID) } selectedTabId={tabID}>
                <Tab 
                    id="help"
                    title={__("Help")}
                    panel={
                        <>
                            <div className="mb-2">
                                {__("Чтобы всё заработало надо сделать 3 настройки")}
                            </div>
                            <div className="mb-2 display-6 title">
                                {__("1. Создайте каналы приёма информации о новых проектах с Яндекс Форм на Ваш сервер. ")}
                            </div> 
                            <div className="mb-4 " dangerouslySetInnerHTML={{__html: __(`<ul><li>Нажмите кнопку «Создать новый поток» на вкладке «Формы потоков», <li>укажите его название (придумайте сами), <li>укажите адрес админки Яндекс-формы, которую создали и настроили на 3-м шаге <li>включите канал на приём.</ul> <p>Вы можете создать и настроить сколько хотите каналов с разных Яндекс-форм.`)}} />
                            
                            <div className="mb-2 display-6 title">
                                {__("2. Настройте правила создания полей во вновь созданном Проекте. Вы можете это сделать в разделе «Дополнительные поля».")}
                            </div>
                            <div className="mb-4 " dangerouslySetInnerHTML={{__html: __(`<p>Все или некоторые ответы Пользователя на Яндекс Форме могут стать дополнительными полями в созданном Проекте.<p>В настройках каждого поля есть элемент «Сигнатура соответствия дополнительных полей Участинков Фестиваля и API Яндекс Форм»`)}} />
                            
                            <div className="mb-2 display-6 title">
                                {__("3. И главное. Создайте Яндекс-Форму и настройте передачу данных с заполненных анкет с сервиса Яндекс.")}
                            </div>  
                            <div 
                                className="py-4 " 
                                dangerouslySetInnerHTML={{ 
                                    __html: sprintf(
                                        __(`
                                            <div class='display-6'>Как настроить передачу данных с Яндекс Форм. Инструкция.</div>
                                            <div class='py-3'>
                                                <div class='title mb-3 lead'>
                                                    шаг 1.
                                                </div> 
                                                <div class='mb-3'>
                                                    <p>Создаём новую форму в Конструкторе Яндекс Форм (<a class='text-light' href='https://forms.yandex.ru/cloud/admin/'>https://forms.yandex.ru/cloud/admin/</a>).
                                                    <p>Переходим в раздел «Интеграция».
                                                </div> 
                                                <img src='%s' alt='' class='max-width-100 dark-bordered'/>
                                            </div>
                                            <div class='py-3'>                            
                                                <div class='title mb-3 lead'>
                                                    шаг 2.
                                                </div> 
                                                <div class='mb-3'>
                                                    <p>В нижней панели выбираем «API» — «Запрос JSON-RPC POST». 
                                                </div> 
                                                <img src='%s' alt='' class='max-width-100 dark-bordered'/>
                                            </div>
                                            <div class='py-3'>                           
                                                <div class='title mb-3 lead'>
                                                    шаг 3.
                                                </div> 
                                                <div class='mb-3'>
                                                    <p>1. В поле «URL» вставляем строчку - <input type='string' class='input form-control' value='%s' disabled />
                                                    <p>2. В поле «Метод» - "POST".
                                                    <p>3. Аккуратно создаём параметры. Нажимаем «Добавить параметр». В левое поле пишем «answers». 
                                                    <p>4. В правом — с помощью знака «плюс» из выпадающего списка выбираем «Ответы на вопросы».

                                                </div> 
                                                <img src='%s' alt='' class='max-width-100 dark-bordered'/>
                                            </div>
                                            <div class='py-3'>                          
                                                <div class='title mb-3 lead'>
                                                    шаг 4.
                                                </div> 
                                                <div class='mb-3'>
                                                    <p>1.Теперь поочерёдно таким же образом добавляем другие поля. Многие из них требуют указать дополнительные настройки. Делайте как показано на скриншотах.

                                                </div> 
                                                <img src='%s' alt='' class='max-width-100 dark-bordered mb-2'/>
                                                <img src='%s' alt='' class='max-width-100 dark-bordered mb-2'/>
                                                <img src='%s' alt='' class='max-width-100 dark-bordered mb-2'/>
                                                <img src='%s' alt='' class='max-width-100 dark-bordered'/>
                                            </div>
                                            <div class='py-3'>                                                      
                                                <div class='title mb-3 lead'>
                                                    шаг 5.
                                                </div> 
                                                <div class='mb-3'>
                                                    <p>1-4. В разделе «Добавить заголовок» добавляем 2 заголовка как указано на скриншоте:
                                                    <p><b>Accept</b> application/json
                                                    <p><b>Content-Type</b> application/json
                                                </div> 
                                                <img src='%s' alt='' class='max-width-100 dark-bordered'/>
                                            </div>
                                            <div class='py-3'>
                                                                                                    
                                                    <div class='title mb-3 lead'>
                                                    шаг 6.
                                                </div> 
                                                <div class='mb-3'> 
                                                    Сохраняем настройки. 
                                                </div>
                                            </div>
                                            <div class='py-3'>
                                            
                                            </div>
                                        `),
                                        shot2,  
                                        shot3,  
                                        `${ blogURL }/wp-json/yandexforms/getanswer`, 
                                        shot4, 
                                        shot5,  
                                        shot6,  
                                        shot7,  
                                        shot8,  
                                        shot9  
                                    )
                                }}
                            />
                        </>
                        
                    }
                /> 
                <Tab 
                    id="rx"
                    title={__("Forms of threads")}
                    panel={
                        <>
                            {cards}
                            <Button intent={Intent.DANGER} onClick={addThread}>
                                {__("Add new Thread")}
                            </Button>
                        </>
                    }
                />
            </Tabs> 
        </div>
        <Dialog
            isOpen={isPrepaire}
            onClose={() => onPripaire(!isPrepaire)}
            className="little"
            title={__("Realy delete Thread?")}
        >
            <div className="p-4"> 
                <ButtonGroup >
                    <Button intent={Intent.DANGER} onClick={() => deleteThread( prepaireDeleteID ) }>
                        {__("Yes")}
                    </Button>
                    <Button onClick={() => onPripaire(!isPrepaire)}>
                        {__("No")}
                    </Button>
            </ButtonGroup>
            </div>
        </Dialog>
    </>
}
export default YFThreadsForm
import React from 'react'
import PropTypes from 'prop-types'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import { Tag } from '@blueprintjs/core'

const FormFieldGroup = props =>
{
    if( !Array.isArray(props.fields) ) return null
    return <div className='d-flex flex-column flex-md-row align-items-center'>
        <div>{ props.fFMatrix?.name }:</div> 
        <div>
        {
            props.fields.map( (field, i) => (
                <div className='px-3 py-1 mx-1 mb-1 small pointer title text-md-left text-center' key={field + "_" + i}>
                    { field }
                </div> 
            ))
        }    
        </div>        
    </div>
}
const FormFieldMonitor = props => 
{
    if(!props.isFields) return null
    const fFMatrix = Array.isArray(useFestStore.getState().options.form_fields)
        ?
        useFestStore.getState().options.form_fields
        :
        [] 
    
    return <div className={ props.className } >
            {
                Array.isArray(props.form_fields)
                    ?
                    props.form_fields.map((ff, i) => {
                        return <FormFieldGroup fFMatrix={fFMatrix[i]} fields={ ff } key={i} i={i} />
                    })
                    :
                    null
            }
        </div> 
}

FormFieldMonitor.propTypes = {}

export default FormFieldMonitor

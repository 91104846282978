import React from 'react'
import PropTypes from 'prop-types'
import FullMemberStatistics from './FullMemberStatistics'
import FullExpertStatistics from './FullExpertStatistics'
import FullStatisticsGenerator from './FullStatisticsGenerator'
import SpecialStatictics from './SpecialStatictics'

const FullStatistics = props => {
  switch(props.currentTab)
  {
    case "members":
      return <FullMemberStatistics />
    case "generate":
      return <FullStatisticsGenerator />
    case "specials":
      return <SpecialStatictics />
    case "experts":
    default:
      return <FullExpertStatistics />
  } 
}

FullStatistics.propTypes = {}

export default FullStatistics
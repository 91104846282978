import { Button, Intent } from "@blueprintjs/core";
import _fetch from "modules/pe-fest-admin-module/api";
import React, {useEffect, useState} from "react"
import FieldInput from "react-pe-scalars/dist";
import { AppToaster, Loading } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";

const PatternSelectDialog = props =>
{
    const [newMap, onNewMap] = useState( )
    const [newTitle, onNewTitle] = useState( "" )
    const [newType, onNewType] = useState( "" )
    const [newAdress, onNewAdress] = useState( "" )
    const [sites, setSites] = useState( [] ) 
    const [patterns, setPatterns] = useState( [] ) 
    const [loading, setLoading] = useState( true )
    const [isCreating, setCreating] = useState( false )
    useEffect(() =>
    {
        _fetch( "init" )
            .then(data => 
                { 
                    console.log( data )
                    setPatterns( data.sites || [] )  
                    setLoading( false ) 
                })
    }, [])
    if(loading)
        return <Loading />
    const onInsertNew = () =>
    { 
        setCreating(true)
        const site = {
            post_title:     newTitle,
            post_content :  "",
            site :          newType, 
            domain:         newAdress,
            markers:        []
        }
        console.log( site ); 
        _fetch( "create_site", site )
			.then(data =>
			{
			    console.log(data); 
                if(data.msg)
                {
                    AppToaster.show({  
                        intent: Intent.DANGER,
                        icon: "floppy-disk", 
                        timeout:10000,
                        className: "px-4 py-4",
                        message: data.msg				
                    });    
                } 
                _fetch( "get_all_sites", {number:100, offset: 0})
                    .then(data => 
                        { 
                            console.log(data.sites)
                            setSites( data.sites || [] )  
                            setCreating( false ) 
                        })
            });
    }
    
    const creatingNewState = () =>
	{
		return <div className="p-4 w-100" >
			<div style={{width:200, margin:"120px auto"}}>
				<div className="xbox">
                    <div>
                        <div></div>
                    </div>
                </div>
			</div>
		</div>
	}
    const beforeCreateNewState = () =>
    {
        return <div className="p-4">                
            <FieldInput
                value={ newTitle }
                type="string"
                title={__("Title")}
                vertical={true} 
                onChange={ ( value ) => onNewTitle( value ) }
            />   
            {/*           
            <FieldInput
                value={ newType }
                type="select"
                values={ sites.map(site => {
                    // console.log(site)
                    return { _id: site.blog_id, title: site.name, descr: site.description }
                }) }
                title={__("Type of Festival")}
                vertical={true} 
                onChange={ ( value ) => { onNewType( value ); console.log(value); } }
            /> 
            */}
            <div className="w-100">
                <div className="layout-label-vert">
                    { __("Type of Festival") }
                </div>
                <select 
                    className="form-control" 
                    value={newType} 
                    onChange={evt => {
                        const value = evt.currentTarget.value
                        console.log(value); 
                        onNewType( value );
                    }}
                >
                {
                    patterns.map(site => {
                        return <option
                            key={ site.domain_id }
                            value={ site.domain_id }
                        >
                            {site.title}
                        </option>
                    })
                }    
                </select>
            </div>   
            <FieldInput
                value={ newAdress }
                type="string"
                title={__("New Site | web address")}
                commentary={__("Only lowercase letters (a-z) and numbers are allowed.")}
                vertical={true} 
                onChange={ ( value ) => onNewAdress( value ) }
            />            
            <FieldInput
                value={ newMap }
                type="geo"
                title={__("Put geoposition")}
                commentary={__("Set place of Event")}
                vertical={true} 
                onChange={ ( value ) => onNewMap( value ) }
            />  
            <div className="p-4">
                <Button onClick={onInsertNew}>
                    {__("Insert")}    
                </Button>    
            </div>               
        </div>
    }
    return isCreating
        ?
        creatingNewState()
        :
        beforeCreateNewState()
}
export default PatternSelectDialog
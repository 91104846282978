import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@blueprintjs/core'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import actions, { SET_HONEYCOMDS_FILTER_ACTION, SET_SEARCH_FILTER_ACTION, SET_SEARCH_STRING_ACTION } from 'modules/pe-fest-module/data/actions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const ClearFiltersForm = props => 
{
    const filters = useFestStore(state => state.memberFilters)
    const [isOpen, setOpen] = useState(false )
    const location = useLocation() 
    const onClose = () =>
    {
        actions( SET_HONEYCOMDS_FILTER_ACTION, { honeycombs: [-1] } )
        actions( SET_SEARCH_FILTER_ACTION, { s:-1 } )
        actions( SET_SEARCH_STRING_ACTION, { search: "" })
        window.history.pushState( null, null, `${location.pathname}` )
    }
    useEffect(() => {
        setOpen( filters.honeycombs[0] !== -1 || filters.tracks[0] !== -1 || filters.s !== -1 )
    }, [filters])

    return <div className={` pe-fest-clear-filter-cont ${isOpen ? ' open ' : ''}`}>
        <Button minimal onClick={onClose} className="pe-fest-clear-filter-close-button">
            <Icon icon="cross" iconSize={23} />
        </Button>
    </div> 
}

ClearFiltersForm.propTypes = {}

export default ClearFiltersForm
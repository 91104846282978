import React from "react"  
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities/dist"
import LoginRegisterForm from "./profile/LoginRegisterForm"


class LoginView extends BasicState {
  addRender = () => <div className="container h-100  flex-centered translate-y--50">
    <div className="row justify-content-center w-100">
      <LoginRegisterForm {...this.props}/>
    </div>
  </div>
}
export default LoginView 

import React, {Component, Fragment, useState} from "react";
import {   
	Intent, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, 
	Tag
} from "@blueprintjs/core";
import { __ } from "react-pe-utilities/dist";  
import FieldInput from 'react-pe-scalars/dist' 
 
export default class TrackForm extends Component
{
	state = {
		post_title 		: this.props.post_title,
		post_content	: this.props.post_content,
		thumbnail		: this.props.thumbnail,		 
		thumbnail_id	: this.props.thumbnail_id,	
		illustration	: this.props.illustration,		 
		illustration_id	: this.props.illustration_id,		 
		content			: this.props.content,	
		moderator		: this.props.moderator?.id  ? this.props.moderator  : {id:-1, display_name:""},	 
		moderator2		: this.props.moderator2?.id ? this.props.moderator2 : {id:-1, display_name:""},	 
		moderator_id	: this.props.moderator?.id,		 
		moderator_id2	: this.props.moderator2?.id,		 
		video_id		: this.props.video_id,		 
		is_closed		: this.props.is_closed,		 
		order 			: parseInt( this.props.isNew ? this.props.meta.order : this.props.order ), 
		fmru_users		: this.props.meta.fmru_users, 
	} 
	onValue = (value, field) => {
		this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state[field] = value
		this.setState( state );
	} 
	onCloseValue = (value ) => {
		this.props.onChange("is_closed", !value, this.props.ID);
		let state = {...this.state}
		state.is_closed = !value
		this.setState( state );
	} 
	onModerator = (value, number) =>
	{
		const field = number === 2 ? "moderator_id2" : "moderator_id"
		const us = number === 2 ? "moderator2" : "moderator"
		let user =  this.state.fmru_users.filter(fu => fu.id === value)[0]
		if(number === 2)
		{

		}
		else
		{

		}
		
		
		//this.props.onChange(field, value, this.props.ID);
		let state = {...this.state}
		state[field] = value
		state[us] = user
		this.setState( state );
		console.log( user, value, state )
	}
	title = evt =>
	{
		const txt = evt.currentTarget.value;
		this.props.onChange("post_title", txt, this.props.ID);
		this.setState({post_title : txt});
	}
	onChangeThumbnail = (thumbnail, name, id, data) =>
	{
		//thumbnail
		console.log( data.thumbnail_name)
		this.setState({ thumbnail,  thumbnail_id:null,  thumbnail_name: data.thumbnail_name });
	}
	onChangeIllustration = (illustration, name, id, data) =>
	{
		//illustration
		console.log( data.illustration_name)
		this.setState({ illustration,  illustration_id:null,  illustration_name: data.illustration_name });
	}

	onChangeVideoID = url =>
	{
		const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    	const match = url.match(regExp);
    	const video_id =  (match&&match[7].length==11)? match[7] : false;
		this.setState({  video_id });
	}

	onOrder = n => 
	{
		//console.log( n );
		this.setState({order : n});
	}
	onDescrEdit = evt =>
	{ 
		this.setState({ post_content : evt.currentTarget.value });
	}
	onCategory = n =>
	{
		this.setState({fmru_ganre : n});
	}
	onSave = () =>
	{
		//console.log( this.state );
		this.props.onSave( this.state, this.props.ID );
	}
	onDelete =() =>
	{
		this.props.onDelete( this.props.ID );
	} 
	render()
	{
		console.log( this.props )
		const del_btn = this.props.isNew 
			? 
			null 
			: 
			<Popover
				position={Position.TOP_LEFT}
				content={
					<div className="square p-3">
						<div>
							<p>
								{__("Are you realy want delete?")}
							</p>
							<ButtonGroup className="p-2 tex-right">
								<Button
									intent={Intent.DANGER}
									text={__("Yes")}
									icon="cross"
									onClick={this.onDelete}
								/>
							</ButtonGroup>
						</div>							
					</div>
					
				}
			>
				<Button
					text={__("Delete")}
					icon="cross"
				/>
			</Popover>  
		return <Fragment>
			<FieldInput
				type="string"
				description={ __( "insert title by any symbols" ) }
				title={ __( "Title" )}
				field={ 'post_title' }
				value={ this.state.post_title }
				onChange={ this.onValue }
			/>
			<FieldInput
				type="number"
				description={ __( "Position this Track in list" ) }
				title={ __( "Order" )}
				field={ 'order' }
				value={ this.state.order }
				onChange={ this.onValue }
			/> 
			<FieldInput
				type="boolean" 
				title={ __( "Is register open?" )}
				field={ 'is_closed' }
				value={ !this.state.is_closed }
				onChange={ this.onCloseValue }
			/> 
			<FieldInput
				type="media" 
				tab="lt"
				hideLib
				isURLHide
				title={ __( "Thumbnail" )}
				field={ 'thumbnail' }
				value={ this.state.thumbnail }
				onChange={ this.onChangeThumbnail }
			/>  
			<FieldInput
				type="media" 
				tab="lt"
				hideLib
				isURLHide
				title={ __( "Illustration" )}
				field={ 'illustration' }
				value={ this.state.illustration }
				onChange={ this.onChangeIllustration}
				commentary={__("Image, usage as alt Youtube video cover")}
			/>  
			<FieldInput
				type="url" 
				title={ __( "Youtube video" )}
				field={ 'video_id' }
				value={ this.state.video_id }
				onChange={ this.onChangeVideoID}
			/>  
			{/* <FieldInput
				type="html" 
				title={ __( "Description" )}
				field={ 'post_content' }
				value={ this.state.post_content }
				onChange={ this.onValue }
			/> */}
			<FieldInput
				type="html" 
				title={ __( "Content" )}
				field={ 'content' }
				value={ this.state.content }
				onChange={ this.onValue }
			/> 
			{
				this.props.meta.festivalOptions?.fmru_evlbl_roles.filter( e => e === "Track_moderator" )
					?
					<>  
						<FieldInput
							visualization="festFormsFields" 
							type={ "select-2" }
							title={ __( "Moderator" )}
							field={ 'moderator_id' } 
							editable
							className={` w-100 input dark form-control `}
							value={ this.state.moderator?.id }
							values={
								this.props.meta.fmru_users.map(user => {
									return {
										_id: user.id,
										title:user.display_name 
									}
								})
							}
							onChange={ value => this.onModerator(Number(value), 0) }
						/>
						<FieldInput
							visualization="festFormsFields" 
							type={ "select-2" }
							title={ __( "Moderator 2" )}
							field={ 'moderator_id2' } 
							editable
							className={` w-100 input dark form-control `}
							value={ this.state.moderator2?.id }
							values={
								this.props.meta.fmru_users.map(user => {
									return {
										_id: user.id,
										title:user.display_name 
									}
								})
							}
							onChange={ value => this.onModerator(Number(value), 2) }
						/> 
					</>
					
					// <ProjectUserForm 
					// 	{ ...this.state }
					// 	roles={ [ "Track_moderator" ] }
					// 	fmru_evlbl_roles={ this.props.meta.festivalOptions?.fmru_evlbl_roles }
					// 	on={ this.onValue }
					// />
					:
					null
			} 
			<ButtonGroup className="p-2 tex-right">
				<Button
					text={__("Save")}
					icon="floppy-disk"
					onClick={this.onSave}
				/>
				{del_btn}
				<Button
					icon="undo"
					onClick={this.props.onClose}
				>
					{__("Close")}
				</Button>
			</ButtonGroup>
		</Fragment>
	}
}
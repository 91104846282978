import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import { cardTypes } from 'modules/pe-fest-module/data/cardType'
import { __ } from 'react-pe-utilities/dist'
import { useFestStore } from 'modules/pe-fest-module/data/store'
import WPFestSettings from '../utilities/WPFestSettings'

const CardTypeEngine = props => { 
    return useFestStore.getState().options.is_change_card_type
        ?
        <ButtonGroup minimal>
            {
                cardTypes().map(cardtype => {
                    // if( cardtype.type !== props.cardType) return null
                    return <Button 
                        key={cardtype.type}
                        disabled={cardtype.type === props.cardType}
                        intent={cardtype.type !== props.cardType ? Intent.NONE : Intent.DANGER}
                        data-hint={ __(cardtype.type) }
                        className={`hint hint--top flex-centered `}
                        onClick={ () => props.onClick(cardtype.type) }
                    >
                        <i className={cardtype.icon + (cardtype.type === props.cardType ? "" : " opacity_5") } />
                    </Button> 
                })
            } 
        </ButtonGroup>  
        :
        null  
}

CardTypeEngine.propTypes = {}

export default CardTypeEngine
import { Dialog } from "@blueprintjs/core"
import LoginForm from "modules/pe-basic-module/views/profile/LoginForm"
import React from "react"
import BasicState from "react-pe-basic-view"
import { __ } from "react-pe-utilities/dist"

class PreBasicState extends BasicState
{
    loginUser = () =>
	{ 
        if(this.props.user)
        {
            this.onLogout()
        }            
        else
        {
            this.setState({isOpenLogin : !this.state.isOpenLogin})
        }		    
	}
    onLogout = () => {
        localStorage.removeItem("token")
        // this.props.refetchUser()
        window.location.reload()
    }
    onResult = props =>
    {
        window.location.reload()
    }
    render()
    { 
        return <> 
            { this.alternateRender() }
            <Dialog
                onClose={ this.loginUser }
                isOpen={ this.state.isOpenLogin }
                title={__( "Log in" )}
                className="little"
            >
                <div className="p-4 row">
                   <LoginForm onResult={this.onResult} onResultArgs={{}} />
                </div>
            </Dialog>
        </>
    }
}
export default PreBasicState

/*
    To refactor in react-pe-basic-view package in next versions
*/

import React, { Component, } from "react"
// import $ from "jquery"
import { Link } from "react-router-dom" 
//import _fetch from "."
import { __ } from "react-pe-utilities"
import GanreIcon from "../../GanreIcon"
import WPFestSettings from "../../utilities/WPFestSettings"
import TrackLabel from "../../../track/TrackLabel"
import MemberRatingDisplay from "../MemberRatingDisplay"
import { compose } from "recompose"
import { withFestStore } from "modules/pe-fest-module/data/store"

class FestMemberCard extends Component 
{
  componentDidMount() 
  {
    //$(".share").socialLikes()
  }

  componentDidUpdate(nextProps) 
  {
    //$(".share").socialLikes()
  }
  stopEvent = evt =>
  {
    evt.stopPropagation(); 
    evt.preventDefault();
    window.open(evt.currentTarget.getAttribute("href"), '_blank');
  }
  getFormValue = (form, value) =>
  {
    switch(form.type)
    {
      case "url":
        return value 
          ? 
          <a href={ value } target="_blank" onClick={ this.stopEvent } className="btn btn-sm btn-link pr-0">
            {__("link")}
          </a> 
          : 
          null
      default:
        return value
    }
  }
  get_form_fields = () =>
  {
    //console.log(this.state.form_fields)
    return Array.isArray(this.props.festData.fest_form_fields)
      ?
      this.props.festData.fest_form_fields
          .map((form , i ) => {
              let liClass = " "
              switch(form.type)
              {
                case "number":
                  liClass = " d-flex justify-content-between "
                  break
                case "string":
                  liClass = form.count == 1 //&& this.props.form_fields && Array.isArray(this.props.form_fields[i]) && this.props.form_fields[i][0].length < 15
                    ?
                    " d-flex justify-content-between "
                    :
                    ""
                  break
                case "url":
                  liClass = " d-flex justify-content-between"
                  break
                default:
                  liClass = " d-flex justify-content-between "
                  break
              }
              return !form.hide_in_card
                ?
                <li 
                  className="list-group-item " 
                  style={{ overflow: "hidden", texOverflow: "ellipsis" }}
                  key={i}            
                >
                  <div className={` py-1 px-3 ${liClass} `}>
                    <div className=" text-nowrap mr-3 ">
                      { form.name }
                    </div>
                    <div>
                    {
                      Array(form.count).fill(1).map((formForm, ii) =>
                      {
                        const value = Array.isArray( this.props.form_fields ) 
                          && Array.isArray( this.props.form_fields[ i ] )
                            ?
                            this.getFormValue( form, this.props.form_fields[ i ][ ii ] )
                            :
                            null 
                        return value ? <div key={ ii } className="text-dark">{ (value ) }</div> : null
                      })
                    }
                    </div>
                  </div>
                </li>
                :
                null
          })
      :
      null
  }
  render() 
  {
    const { id, o, e, img, track, title, content, ganres, r, r2, festData, correct_member_rate, nomination, prize } = this.props 
    let _ganres, ___ganres, gcolor = "#14172b"
    if(this.props.store.options.is_ganres)
    {
      _ganres = ganres.map((ganre, index) => <GanreIcon ganre={ganre} key={ganre.id} />) 
      const __ganres =  ganres.map((ganre, index) => `<strong style='color:${ganre.color}'>${ganre.name}</strong>`)
      const _ganre = ganres[0]
      gcolor = _ganre ? _ganre.color : "#14172b"
      ___ganres = <li
        className="list-group-item"
        style={{
          height: "42px", overflow: "hidden", padding: "0 1.25em", position: "relative", display: "table",
        }}
      >
        <span
          className="data w-100"
          style={{
            padding: "0 20px",
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
        >
          <span className="hideColor">.</span>
          <span dangerouslySetInnerHTML={{ __html: __ganres.join(", ") }} />
        </span>
      </li>
    }
    

    const url = [1, 2].filter((e) => e === WPFestSettings.status).length > 0
      ? `${this.props.route}/member/${id}`
      : `${this.props.route}/member/${id}`
    // console.log( this.props.store.myTracks )
    return (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">      
          <Link className="card h-100 m-0 p-0 position-relative " to={url}>
            <div
              className="card-img"
              style={{
                backgroundImage: `url(${img ? img : this.props.store.fests[ WPFestSettings.festId ].default_thumbnail})`,
                borderColor: gcolor,
                height: festData.member_card_height
              }}
              data-mid={id}
              data-fmru_type="fmru_player"
              data-args={id}
            >
              <div className="card-id">
                {o}
              </div>
              <TrackLabel track={track} />
            </div>
            <div className="card-icons">
              {_ganres}
            </div>
            <div
              className="card-header d-flex justify-content-center align-items-center"
              title={title}
              style={{
                height: 76,
                overflow: "hidden",
                padding: "0 1.25em",
                position: "relative",
                display: "table",
              }}
            >
              <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                {title}
              </h5>
            </div>
            <ul className="list-group list-group-flush flex-grow-100 ">
              {
                prize
                  ?
                  <li className="list-group-item "> 
                    <div className="data text-white flex-centered title text-center bg-pumpkit w-100 px-3" style={{height:80}}> 
                      {prize} 
                    </div>
                  </li>
                  :
                  null
              }
              {
                nomination
                  ?
                  <li className="list-group-item flex-centered">
                    <span className="discr">
                      {__("Nomination:")}
                    </span>
                    <span className="data text-danger text-uppercase small"> 
                      {nomination} 
                    </span>
                  </li>
                  :
                  null
              }
              { ___ganres }     
              { this.get_form_fields() } 
              <li className="list-group-item mt-auto">
                <span className="discr">
                  {__("Raiting:")}
                </span>
                <span className="data "> 
                  <MemberRatingDisplay 
                    correct_member_rate={correct_member_rate}
                    countedRating={ r2 }
                    className={"text-dark "}
                  />  
                </span>
              </li>
              <li className="list-group-item">
                <span className="discr">
                  {__("Expert count:")}
                </span>
                <span className="data selected">
                  {e === 0 ? "-" : e}
                </span>
              </li>
              <li className="list-group-item hidden-1">
                <div
                  className="social-likes share"
                  data-url={url}
                  data-title={title}
                  style={{ padding: "11px 20px" }}
                >
                  <div className="facebook" title="Поделиться ссылкой на Фейсбуке" />
                  <div className="twitter" data-via="@Metaversitet" title="Поделиться ссылкой в Твиттере" />
                  <div className="mailru" title="Поделиться ссылкой в Моём мире" />
                  <div className="vkontakte" title="Поделиться ссылкой во Вконтакте" />
                  <div className="odnoklassniki" title="Поделиться ссылкой в Одноклассниках" />
                </div>
              </li>
            </ul>
            {
              this.props.isMine
                ?
                <div className="mine-corner corner-3">
                  { __( "My project" ) }
                </div>
                :
                null
            }
            {
              this.props.isMineHoneycoms
                ?
                <div className="mine-honeycombs-corner corner-3">
                  { __( "My honeycombs" ) }
                </div>
                :
                null
            }{
              this.props.store?.myTracks?.filter(mtID => mtID == track.ID).length > 0
                ?
                <div className="my-track-label-stroke">
                  { __( "My Track" ) }
                </div>
                :
                null
            }
            
          </Link>
      </div>
    )
  }
}
export default compose( 
	withFestStore,
  )(FestMemberCard)

import { Button, ButtonGroup, Dialog, Intent, ProgressBar } from "@blueprintjs/core"; 
import IndexDB from "modules/pe-admin-module/views/utilities/IndexDB";
import _fetch, { FAILED_TO_FETCH } from "modules/pe-fest-admin-module/api";
import React, { useEffect, useState } from "react" 
import { moduleExists } from "react-pe-layouts";
import { Loading, Pagi } from "react-pe-useful/dist";
import { __ } from "react-pe-utilities/dist";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Style from "style-it";
import { WPFEST_ADMIN_STATUSES } from "../CabinetView";
import PatternSelectDialog from "../pattern/PatternSelectDialog";

const Mine = props =>
{
    const history = useHistory()
    const all_sites_number = 100
    const [sites, setSites] = useState( props.sites ) 
    const [offset, onOffset] = useState( 0 )
    const [loading, setLoading] = useState( true )
    const [isNewOpen, onNewOpen] = useState( false )
    useEffect(() => {
        IndexDB.recover("peFestAdmin_mySites", "sites", "sites")
            .then(sites =>
            {
                //console.log(sites)
                const mySites = Array.isArray(sites)
                    ?
                    sites.map(site => site.data)
                    :
                    []
                setSites( mySites ) 
            }) 
        _fetch( "get_my_sites", {number:100, offset: 0})
            .then(data => 
            { 
                const sites = data.sites || [] 
                setSites( sites ) 
                setLoading( false ) 
                sites.forEach( site =>  {
                    IndexDB.save( 
                        site, 
                        "sites", 
                        site.blog_id, 
                        "sites",
                        "peFestAdmin_mySites",  
                        1 
                    )
                }) 	 
            }) 
    }, [])
    if(loading)
        return <Loading />
    // console.log(sites)

    const onPagi = n =>
    {
        console.log( n )
        onOffset( n )
    }
    const onSite = evt =>
	{
		const domain_id = evt.currentTarget.getAttribute("domain_id");
		const domain = evt.currentTarget.getAttribute("domain");
		//console.log(domain_id, domain);
		// this.setState({state:1, domain_id, domain});
        history.push( props.preroute + "/" + props.route + "/site/" + domain_id)
	} 
    const goto = (evt, site) =>
    {
        evt.preventDefault ()
        evt.stopPropagation() 
        if( moduleExists( "pe-fest-module" ))
        {
            history.push( `/fest/${ site.blog_id}` )
        }
        else
        {
            window.open( site.url, '_blank' );
        }
        

    }

   

    const siteList = sites.map( elem => {
        const gotoPanel =  <div 
                className="btn btn-primary wp-fest-admin-btn" 
                onClick={ evt => goto( evt, elem ) }
            >
                {__("Goto")}    
            </div>
        return <div className="site-card-cont col-md-4 col-sm-6 m-0 p-0" key={elem.blog_id}>
            <div 
                className="site-card " 
                domain={elem.domain}
                domain_id={elem.blog_id}
                onClick={ onSite} 
            >
                <div className="text-overlay">
                    <div className="pb-3 text-center  small">
                        <div>{ __("Projects count")}: {elem.options?.page_id }</div>
                        <div>{ __(WPFEST_ADMIN_STATUSES[elem.options?.status]) } </div>
                    </div>
                    {
                        Style.it(
                            `
                            .wp-fest-api-thumb::before
                            {
                                background-image: url(${ elem.icon });
                            }
                            `,
                            <div className=" wp-fest-api-thumb-cont ">
                                <div className=" wp-fest-api-thumb ">

                                </div>
                            </div>
                        )
                    }
                    <div className=" wp-fest-api-name " >
                        {elem.name}
                    </div>
                    <div className=" wp-fest-api-domain " >
                        {elem.domain}
                    </div>
                    <div className=" wp-fest-api-type member_span hidden">
                        { elem.domain_type ? <div className="">{elem.domain_type}</div> : null }
                    </div> 
                </div>
                <div className="purchase-button-container pt-3">
                    <div className=" wp-fest-api-blogid ">{elem.blog_id}</div> 
                    <ButtonGroup vertical>
                        <div className="btn btn-primary wp-fest-admin-btn mb-2" >
                            {__("Edit")}    
                        </div>
                        { gotoPanel }
                    </ButtonGroup>
                    <div className="p-3">
                        {elem.description}
                    </div>
                </div>
            </div>
        </div>
    }) 
    return <div className=" py-3">
        <div className="row">
            {siteList} 
            <div className="site-card-cont col-md-4 col-sm-6 m-0 p-0" >
                <div 
                    className="site-card new-site " 
                    onClick={ () => onNewOpen(true) } 
                >
                    <div className="text-overlay">
                        <div className=" wp-fest-api-blogid hidden "> blog_id </div>
                        <div className=" wp-fest-api-thumb-cont ">
                            <div className=" wp-fest-api-thumb ">
                                <i className="fas fa-plus"/>
                            </div>
                        </div>
                        <div className=" wp-fest-api-name "  style={{fontSize: 13}} >
                            {__("Create new Festival")}
                        </div>
                        <div className=" wp-fest-api-domain " >
                            ...
                        </div> 
                    </div>
                    <div className="purchase-button-container">
                        <ButtonGroup vertical>
                            <div className="btn btn-primary btn-lg wp-fest-admin-btn ">
                                {__("Create new Festival")}    
                            </div>
                        </ButtonGroup>
                    </div>
                </div>
            </div>               
        </div>
        <Dialog
            isOpen={ isNewOpen }
            onClose={ () => onNewOpen(false) }
            title={__( "Create new Festival" )}
            className="little"
        >
           <PatternSelectDialog
           
           />
        </Dialog>
    </div>
}
export default Mine
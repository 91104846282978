import React, { Component } from "react"
import { __ } from "react-pe-utilities" 

export default class PhaseLabel extends Component {
	static labels = [
	  "The Festival has not yet begun",
	  "The preparation of the Projects",
	  "The Festival began. Experts evaluate the Projects",
	  "The Festival in archive",
	];

	render() {
	  return (
		<div className="status-descr mr-md-auto ml-md-0 mx-auto">
			{ __(PhaseLabel.labels[this.props.phase || 0]) }
		</div>
	  )
	}
}

import React, {Fragment, Component} from "react"; 
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, Slider, PopoverInteractionKind
} from "@blueprintjs/core";
import { sprintf, __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-fest-admin-module/api";
import { AppToaster } from "react-pe-useful/dist";
export default class UserBatchForm extends Component
{
	state={count:10, roles:[], is_exists:true};
	render()
	{
		//console.log(this.props.fmru_ganres);
		const roles = this.props.roles.map(elem =>
		{
			return <div key={elem.id} className="mb-1">
				<input
					type="checkbox"
					className="_checkbox"
					value={elem.id}
					id={elem.id}
					onChange={this.onRole}
					checked={ this.state.roles.filter(e => e == elem.id).length > 0 }
				/>
				<label htmlFor={elem.id}>{elem.post_title}</label>
			</div>
		})	
		return <Card className="p-4 text-dark">
			<div className="lead mb-2">
				{__("Batch Users creating")}
			</div>
			<FormGroup
				label={__("Count")}
				helperText="set count of new Users"
				className="p-2"
			>
				 <Slider
                    min={0}
                    max={50}
                    stepSize={1}
                    labelStepSize={10}
                    onChange={this.onCount}
                    value={this.state.count}
                    vertical={false}
                />
			</FormGroup>
			<FormGroup
				label={__("Role")}
				helperText="set one or more Roles"
				className="p-2"
			>
				{roles}			
			</FormGroup>
			<div className="p-3">
				<Popover
					position = { Position.TOP_LEFT }
					isOpen={ this.state.POCreate === true ? true : false }
					hasBackdrop={false}
					interactionKind={PopoverInteractionKind.CLICK}
					popoverClassName="pt-popover-content-sizing"
					usePortal={true}
					
					content = {
						<div className="squar p-3">
							<div className="lead text-center">
								{__( 
									this.isExists()
										? "Are you realy want create batch of Projects?"
										: "No all parameters are selected!"
								)}
							</div>
							{
								this.isExists() 
									? <ButtonGroup className="p-2 tex-right">
										<Button
											text={__("No")}
											onClick={()=>this.setState({POCreate : !this.state.POCreate})}
										/>
										<Button
											intent={Intent.DANGER}
											text={__("Yes")}
											onClick={this.onBathCreate}
										/>
									</ButtonGroup> 
									: 
									<div className="py-3">
										<Icon icon="error" iconSize={30} />
									</div>
							}
						</div>
					}
				>
					<Button text={ __("Create batch") } onClick={()=>this.setState({POCreate : !this.state.POCreate})}/>
				</Popover>
				<Button icon="undo" onClick={ this.goClose }/>
			</div>
		</Card> 
	}
	onRole = evt =>
	{
		const checked = evt.currentTarget.checked;
		const role = evt.currentTarget.value;
		let roles = this.state.roles.slice(0);
		
		if(checked)
		{
			roles.push(role);
		}
		else
		{
			roles = roles.filter(e => e != role);
		}
		//console.log(roles);
		this.setState({roles});
	}
	onCount = n =>
	{
		this.setState({count : n});
	}
	
	onBathCreate = () =>
	{
		let st = Object.assign({}, this.state);
		st.blog_id = parseInt( this.props.blog_id );
		console.log(st);
		_fetch("batch_user_create", st)
			.then(data =>
			{
				console.log(data);
				AppToaster.show({  
					intent: Intent.SUCCESS,
					icon: "layout-grid", 
					timeout:10000,
					className: "px-4 py-4",
					message: sprintf(__("Success creating %s Users"), this.state.count )				
				});
				this.props.onCreate( data.post );
				//this.props.goClose();
			});
	}
	goClose = () =>
	{
		this.props.goClose();
	}
	isExists = () =>
	{
		return this.state.roles.length && this.state.count ? 1 : 0;
	}
}
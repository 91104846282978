import React, {Fragment, Component} from "react";  
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup, Slider
} from "@blueprintjs/core";
import { sprintf, __ } from "react-pe-utilities/dist";
import _fetch from "modules/pe-fest-admin-module/api";
import { AppToaster } from "react-pe-useful/dist";
import GanreRange from "modules/pe-fest-admin-module/utilities/GanreRange";

export default class ProjectBatchForm extends Component
{
	state={count:10, fmru_ganre:[], is_exists:true};
	render()
	{
		//console.log(this.props.fmru_ganres);
		return <Card className="text-dark p-0">
			<div className="p-4 dark-bordered">
				<div className="lead mb-2">
					{__("Batch Projects creating")}
				</div>
				<FormGroup
					label={__("Count")}
					helperText="set count of new Projects"
					className="p-2"
				>
					<Slider
						min={0}
						max={50}
						stepSize={1}
						labelStepSize={10}
						onChange={this.onCount}
						value={this.state.count}
						vertical={false}
					/>
				</FormGroup>
				<FormGroup
					label={__("Ganres")}
					helperText="set one or more Ganres"
					className="p-2"
				>
					<GanreRange
						fmru_ganres={this.props.fmru_ganres}
						name={"ganres"}
						checked={this.state.fmru_ganre}
						onChange={this.onGanre}
					/>
				</FormGroup>
				<div className="p-3">
					<Popover
						position={Position.TOP_LEFT}
						content={
							<div className="square p-3">
								<div className="lead text-center">
									{__( 
										this.isExists()
											? "Are you realy want create batch of Projects?"
											: "No all parameters are selected!"
									)}
								</div>
								{
									this.isExists() 
										? <ButtonGroup className="p-2 tex-right">
											<Button
												intent={Intent.DANGER}
												text={__("Yes")}
												onClick={this.onBathCreate}
											/>
										</ButtonGroup> 
										: 
										<div className="py-3">
											<Icon icon="error" iconSize={30} />
										</div>
								}
							</div>
						}
					>
						<Button text={__("Create batch")} />
					</Popover>
					<Button icon="undo" onClick={ this.goClose }/>
				</div>
			</div>
		</Card> 
	}
	onCount = n =>
	{
		this.setState({count : n});
	}
	onGanre = ids =>
	{
		if( ids.length )
			this.setState({ fmru_ganre : ids });
	}
	onBathCreate = () =>
	{
		let st = Object.assign({}, this.state);
		st.blog_id = this.props.blog_id;
		_fetch("batch_projects_create", st)
			.then(data =>
			{
				console.log(data);
				AppToaster.show({  
					intent: Intent.SUCCESS,
					icon: "layout-grid", 
					timeout:10000,
					className: "px-4 py-4",
					message: sprintf(__("Success creating %s anonymous Projects"), this.state.count )				
				});
				this.props.onCreate( data.post );
				//this.props.goClose();
			});
	}
	goClose = () =>
	{
		this.props.goClose();
	}
	isExists = () =>
	{
		return this.state.fmru_ganre.length && this.state.count ? 1 : 0;
	}
}
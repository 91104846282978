import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types' 
import { __ } from 'react-pe-utilities/dist'
import { useFestStore, withFestStore } from 'modules/pe-fest-module/data/store'
import { Button, ButtonGroup, Collapse, Icon, Intent } from '@blueprintjs/core'
import FieldInput from 'react-pe-scalars/dist'
import RussionRegionList from 'modules/pe-fest-admin-module/data/RussianRegionList'
import FormFieldMonitor from './FormFieldMonitor'
import $ from "jquery"
import TagFilters from './TagFilters'
import TrackLabel from 'modules/pe-fest-module/views/track/TrackLabel'
import MemberRatingByCriteries from '../../utilities/MemberRatingByCriteries'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'

// https://www.npmjs.com/package/react-export-excel-xlsx-fix

const ORDER_SORT = "order"
const ALPHABET_SORT = "alphabet"
const RATING_SORT = "rating"
const NOMINATION_SORT = "nomination"
const SORT_ORDER_ASC = "asc"
const SORT_ORDER_DESC = "desc"

const FullMemberStatistics = props => {
    // console.log( useFestStore.getState().options.form_fields )
    const [ regionId, ] = useState( () => {
        const rsID = useFestStore.getState().options.form_fields.findIndex(ff => ff.type === "RussianRegions") 
        return typeof rsID === "number" ? rsID : -1
    } )
    const [ isTrackOpen,  setTrackOpen  ]   = useState( false ) 
    const [ isRegionOpen, setRegionOpen ]   = useState( false )
    const [ isFieldOpen,  setFieldsOpen ]   = useState( false ) 
    const [ isRatingMaxOpen,  setRatingMaxOpen ]   = useState( false ) 
    const [ trakFilter,   setTrakFilter ]           = useState( useFestStore.getState().tracks.map(track => track.id) || [] )
    const [ regionFilter, setRegionFilter ]         = useState( RussionRegionList() )
    const [ ratingMaxFilter, setRatingMaxFilter ]   = useState( 0 )
    const [isFields, onIsFields]                    = useState( true )
    const [isCriteries, onIsCriteries]              = useState( false )
    const [sotrField, setSotrField]                 = useState( ALPHABET_SORT )
    const [sortOrder, setSortOrder]                 = useState( SORT_ORDER_ASC )
    const [sortNomination, setSortNomination]       = useState( NOMINATION_SORT )
    const onTrackFilter = value =>
    { 
        setTrakFilter( value || [] )
    }
    const onRegionFilter = value =>
    { 
        //console.log( value )
        setRegionFilter( value || [] )
    }
    const onRatingMaxFilter = value =>
    { 
        //console.log( value )
        setRatingMaxFilter( Number(value) )
    }
    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }
    const onChangeField = (field, bool) =>
    {
        switch(field)
        {
            case "onIsFields":
                onIsFields(bool) 
                break
            case "onIsCriteries":
                onIsCriteries(bool) 
                break
        }
    }
    const onSortOrder = () =>
    {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )

    }
    const sortMembers = (a, b ) =>
    {
        switch(sotrField)
        {
            case ALPHABET_SORT: 
                const nameA = a.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case NOMINATION_SORT: 
                const nomA = a.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nomB = b.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nomA > nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nomA < nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1 
                }
                return 0
            case ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.o ) - Number( b.o )
                    :
                    Number( b.o ) - Number( a.o )
            case RATING_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    MemberRatingByCriteries( b ) - MemberRatingByCriteries( a )
                    :
                    MemberRatingByCriteries( a ) - MemberRatingByCriteries( b )
        }
        return 1
    }
    console.log( useFestStore.getState().tracks.length === 0 )
    let memberData = useFestStore.getState().statistics?.members 
        ?    
        useFestStore.getState().statistics?.members
            .filter( 
                member => {
                    if( useFestStore.getState().tracks.length === 0 ) return true
                    if(!member.track || !member.track.id) return true
                    return trakFilter.filter( 
                        filter => {                            
                            return filter === member.track?.id
                        }
                ).length > 0
            })
            .filter(
                member => regionFilter.filter(
                    filter => {
                        if(regionId < 0) return true
                        if( !Array.isArray( member.form_fields  ) || !member.form_fields[ regionId ] )
                        {
                            return regionFilter.length === RussionRegionList().length
                        }
                        if(typeof member.form_fields[ regionId ][ 0 ] === undefined)
                        {
                            return true
                        }
                        if(!member.form_fields[ regionId ][ 0 ]) return true
                        return filter === member.form_fields[ regionId ][ 0 ]
                    }
                ).length > 0
            )
            .filter( member => MemberRatingByCriteries( member ) >= ratingMaxFilter )
        :
        []
    memberData.sort( sortMembers )


    const memberTRs = memberData.length > 0 
        ?
        memberData.map((member, i) => 
        {
            const tutor = useFestStore.getState().statistics?.users?.filter(user => user.id === member.tutor_id )[0]
            const rating = member.actualRating
            const crs = member.criteries.map((critery, ii) => {
                const full_rating_data = critery.full_rating_data[0] || { display_name : "---"} 
                return isCriteries
                    ?
                    <tr key={critery.id} className={`${ critery.average_critery_rating ? "" : " opacity_5"}`}>
                        <td> {ii + 1} </td>
                        <td>
                            {critery.title}   
                        </td>
                        <td>
                            {  
                                critery.full_rating_data.map(cr => {
                                    return <div key={cr.ID} className="my-2 small">  
                                        <div>
                                            <span>{__("Expert")}:</span>
                                            <span className='title ml-2'>{cr.display_name}</span> 
                                        </div>
                                        <div>
                                            <span>{__("Commentary")}:</span>
                                            <span className='title ml-2'>«{cr.description}»</span>  
                                        </div>
                                        <div>
                                            <span>{__("Rating")}:</span>
                                            <span className='title ml-2'>{cr.raiting}</span> 
                                        </div>
                                    </div>   
                                }) 
                            }
                        </td>
                        <td>
                            {critery.average_critery_rating}   
                        </td> 
                    </tr>
                    :
                    null
            })
            return <Fragment key={member.id}>
                <tr className='bg-light-strobe  border-top border-secondary border-2'>
                    <td>
                        {member.o}
                    </td>
                    <td colSpan={2} >
                        <div className='lead title py-2 text-md-left text-center max-width-100'>{member.title}</div> 
                        <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                            <span>
                                {__("Tutor")}:
                            </span> 
                            <span className='title text-md-left text-center'>
                                { tutor?.display_name } 
                            </span>
                            <span className='mx-1'>|</span> 
                            { tutor?.user_email }
                        </div> 
                        <div className='py-2 d-flex align-items-center flex-column flex-md-row'>
                            <span>{__("Track")}:</span>                                
                            <TrackLabel track={member.track} className="title d-inline text-nowrap my-2 ml-2"/> 
                        </div> 
                        <div className='py-2'>
                            <FormFieldMonitor {...member } isFields={isFields} />
                        </div> 
                        {
                            member.nomination
                                ?
                                <div className='d-flex py-2'>
                                    <div className=' '>
                                        {__("Nomination:")}
                                    </div>
                                    <div className='ml-3 title text-danger'>
                                        { member.nomination }
                                    </div>
                                </div>
                                :
                                null
                        }
                    </td>
                    <td > 
                        <div className='py-2 display-6 title  text-md-left text-center'>
                            { rating }
                        </div> 
                    </td>
                </tr>
                {crs}
            </Fragment>
        })
        :
        <div className='alert alert-danger lead text-center mt-5 p-5'>
            {
                __("No elements exists")
            }
        </div> 
    return <>
        <div className='w-100'>
            <div className='mt-1 mb-3 w-100'>
                <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                    <div className='flex-centered'>
                        <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                            {__("Filters")}:
                        </div>
                        <Button intent={Intent.SUCCESS} minimal={ !isTrackOpen } onClick={() => setTrackOpen( !isTrackOpen ) }>
                            {__("Tracks")} | { trakFilter.length }
                        </Button>
                        <Button intent={Intent.SUCCESS} minimal={ !isRegionOpen } onClick={() => setRegionOpen( !isRegionOpen ) }>
                            {__("Regions")} | { regionFilter.length }
                        </Button> 
                        <Button intent={Intent.SUCCESS} minimal={ !isRatingMaxOpen } onClick={() => setRatingMaxOpen( !isRatingMaxOpen ) }>
                            {__("By rating")} | { ratingMaxFilter }
                        </Button> 
                    </div>
                    <div className='d-flex flex-md-row flex-column'>
                        <div className=' flex-centered ml-4 mr-2 descr text-uppercase text-secondary '>
                            {__("Do sort")}:
                        </div>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sotrField !== ORDER_SORT}
                            onClick={() => setSotrField(ORDER_SORT)}
                        >
                            {__("By Member's order")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sotrField !== ALPHABET_SORT}
                            onClick={() => setSotrField(ALPHABET_SORT)}
                        >
                            {__("By alphabet")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sotrField !== RATING_SORT}  
                            onClick={() => setSotrField(RATING_SORT)}
                        >
                            {__("By rating")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal={sotrField !== NOMINATION_SORT}  
                            onClick={() => setSotrField( NOMINATION_SORT )}
                        >
                            {__("By nomination")}
                        </Button>
                        <Button 
                            intent={Intent.SUCCESS} 
                            minimal  
                            onClick={ onSortOrder }
                        >
                            <Icon 
                                icon={ sortOrder === SORT_ORDER_ASC 
                                    ?  
                                    sotrField === RATING_SORT
                                        ?
                                        "sort-numerical-desc"
                                        :
                                        "sort-asc" 
                                    : 
                                    sotrField === RATING_SORT
                                        ?
                                        "sort-numerical"
                                        :
                                        "sort-desc"
                                } 
                                iconSize={20} 
                            />
                        </Button>
                    </div>
                    
                    <div className='flex-centered'>
                        <Button intent={Intent.SUCCESS} minimal={!isFieldOpen} className=' px-3 py-2 ml-3' onClick={() => setFieldsOpen(!isFieldOpen)}>
                            {__("Fields")}
                        </Button>
                    </div> 

                    <div className='flex-centered'>
                        <div className=' display-6 '>{memberTRs.length}</div>
                        <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                            <Icon icon="print" iconSize={21} className="hint hint--top" data-hint={__("Print")} />
                        </Button> 
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-light bg-transparent border-0 px-3 py-2 ml-1"
                            table="members-to-xls"
                            filename="full_member_list"
                            sheet="full_member_list"
                            buttonText={<Icon icon="th" iconSize={21} color="#6c757d" className="hint hint--top" data-hint={__("Download XLS")}/>}
                        />
                    </div>
                     
                </div>
            </div>
            <Collapse isOpen={ isTrackOpen} className=" light-colored ">
                <FieldInput
                    type="tags"
                    visualization="festFormsFields" 
                    multiple
                    vertical
                    editable
                    values={
                        useFestStore.getState().tracks.map(track => {
                            return { _id: track.id, title: track.post_title, intent: "success" }
                        })
                    }
                    value={trakFilter}
                    onChange={onTrackFilter}
                    on={onTrackFilter}
                />
            </Collapse>
            <Collapse isOpen={ isRegionOpen} className=" light-colored ">
                <FieldInput
                    type="RussianRegionTags"
                    visualization="festFormsFields" 
                    className={` w-100 input dark form-control `}
                    multiple
                    vertical
                    editable 
                    value={regionFilter}
                    onChange={onRegionFilter}
                    on={onRegionFilter}
                />
            </Collapse>
            <Collapse isOpen={ isRatingMaxOpen } className=" light-colored ">
                <FieldInput 
                    type="slider"  
                    title={__("Only more than")}
                    commentary={__("Filter all member that raating low this parameter")}
                    className="max-width-100 ml-5"
                    min={0}
                    max={100}
                    step_size={1}
                    label_step_size={5} 
                    editable 
                    value={ratingMaxFilter}
                    onChange={onRatingMaxFilter}
                    on={onRatingMaxFilter}
                />
            </Collapse>
            <Collapse isOpen={ isFieldOpen} className=" light-colored ">
                <div className='p-3'>
                    <TagFilters 
                        isFields={isFields}
                        isCriteries={isCriteries}
                        onChangeField={onChangeField}
                    />    
                </div>
            </Collapse>
        </div>
        <table className='table table-striped table-bordered table-hover1 pe-fest-table-statistics w-100' id="members-to-xls"> 
            {
                isCriteries
                    ?
                    <thead className="thead-dark-33 bg-secondary text-white">
                        <tr>
                            <th scope="col" width="55" >
                                {__("#")}
                            </th>
                            <th scope="col" >
                                {__("Critery")}
                            </th>
                            <th scope="expert">
                                {__("Expert")}
                            </th>
                            <th scope="col" width="100" >
                                {__("Rate")}
                            </th> 
                        </tr>                
                    </thead >
                    :
                    null
            }
            <tbody>
               {memberTRs}
            </tbody> 
        </table>
    </>
}

FullMemberStatistics.propTypes = {}

export default FullMemberStatistics 
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse, Icon, Intent } from '@blueprintjs/core'
import { __ } from 'react-pe-utilities/dist'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import $ from "jquery"
import { useFestStore } from 'modules/pe-fest-module/data/store'
import FieldInput from 'react-pe-scalars/dist'
import __cases from 'states/pe-react-utilities/__cases'

const FullStatisticsGenerator = props => {
    const [isFieldsOpen, setFieldsOpen] = useState( false )  
    const [eENumberingFilter, seteEENumberingFilter] = useState( true )  
    const [fieldsFilter, setFieldsFilter] = useState( [] )   

    let n = 0, lastMember = "", lastField= ""

    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }

    const onFieldsFilter = value =>
    {
        setFieldsFilter( value )
    }
    const onEENumberingFilter = value =>
    {
        seteEENumberingFilter(value)
    }
    const inscrenentN = () =>
    {
        ++n
        return n
    }
    const trsData = useFestStore.getState().statistics.members
    const fieldOrders = useFestStore.getState().options.form_fields
        .map( (ff, i) => ({...ff, i}) )
        .filter(ff => fieldsFilter.filter( fFilter => fFilter === ff.name ).length > 0 )
    //console.log( fieldOrders )


    const getMemberTitleTR = (member) =>
    {
        lastField = ""
        if( lastMember === member.title) return null
        lastMember = member.title
        return <tr className="table-success">
            <td width={75}> </td>
            <td colSpan={20}>
            {__("Member")}: <b>{ member.title }</b> ({member.o})
            </td>
        </tr> 
    }
    const getFieldTitleTD = name =>
    {
        if( lastField === name) return null
        lastField = name
        return name
    }
    const trs = trsData.map(member => {
        return member.form_fields
            .map((data, index) => ({ index, data, formFieldData: useFestStore.getState().options.form_fields[index] }))
            .filter( (data, index) => {
                return fieldOrders.filter(fOrder => fOrder.i === index).length> 0
            })
            .map((data, index) => {
                return <Fragment  key={ index }>
                    { getMemberTitleTR(member) }                    
                    { 
                        Array.isArray(data.data)
                            ?
                            data.data.map((e, i) => {
                                // if(!e)  return null
                                const nn = inscrenentN()
                                return <tr key={ e }>
                                    <td width={75}>
                                        { eENumberingFilter ? nn : "" } 
                                    </td>
                                    <td>
                                        {getFieldTitleTD(data.formFieldData.name)}
                                    </td>
                                    <td>
                                        { e }
                                    </td>
                                </tr>
                            }) 
                            :
                            null
                    } 
                </Fragment>
            })
    })

    return <div className='w-100'>
        <div className='w-100'>
            <div className='mt-1 mb-3 w-100'>
                <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
                    <div className='flex-centered'>
                        <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                            :
                        </div>
                        <Button intent={Intent.SUCCESS} minimal={ !isFieldsOpen } onClick={() => setFieldsOpen( !isFieldsOpen ) }>
                            {__("All Member's extended fields data")} 
                        </Button> 
                    </div> 

                    <div className='flex-centered'>
                        <div className=' display-6 '>{n}</div> 
                        <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                            <Icon icon="print" iconSize={21} className="hint hint--top" data-hint={__("Print")} />
                        </Button> 
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-light bg-transparent border-0 px-3 py-2 ml-1"
                            table="fields-to-xls"
                            filename="full fields list"
                            sheet="full fields list"
                            buttonText={<Icon icon="th" iconSize={21} color="#6c757d" className="hint hint--top" data-hint={__("Download XLS")}/>}
                        />
                    </div>
                    
                </div>
            </div>
            <Collapse isOpen={ isFieldsOpen} className=" light-colored ">
                <FieldInput 
                    type="tags"
                    visualization="festFormsFields"  
                    title={__("Extend fields")}
                    commentary={__("Select one or more extend field for generate data list")}
                    className="max-width-100 ml-5"  
                    editable 
                    multiple
                    default={[]}
                    value={fieldsFilter}
                    values={useFestStore.getState().options.form_fields.map(ff => {
                        return {_id: ff.name, title:`<b>${ff.name}</b> ${ff.count} ${__cases(ff.count, "strikes")}` }
                    })}
                    onChange={onFieldsFilter}
                    on={onFieldsFilter}
                />
                <FieldInput 
                    type="boolean" 
                    title={__("End-to-end numbering")} 
                    className="max-width-100 ml-5"  
                    editable  
                    value={eENumberingFilter} 
                    onChange={onEENumberingFilter}
                    on={onEENumberingFilter}
                />
            </Collapse> 
        </div>
        <table className='table table-striped table-bordered table-hover1 pe-fest-table-statistics w-100' id="fields-to-xls">
            <tbody>
              { trs }
            </tbody>
        </table>
    </div> 
}

FullStatisticsGenerator.propTypes = {}
export default FullStatisticsGenerator
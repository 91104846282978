import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import _fetch from "../views/wpfestREST" 
import GanreForm from "../views/wpfestREST/GanreForm"
import { __ } from "react-pe-utilities"
import WPFestSettings from "../views/wpfestREST/utilities/WPFestSettings"

import icon1 from "../assets/img/test.svg" 
import history from "../assets/img/history.svg" 
import book from "../assets/img/book.svg"
import team from "../assets/img/team.svg"
import development from "../assets/img/development.svg"
import onlineClass from "../../../assets/img/online-class.svg"
import track_moderator from "../assets/img/track_moderator.svg"
import HoneycombsForm from "./header/HoneycombsForm"
import LoginForm from "../views/wpfestREST/core/login/LoginForm"
import TracksForm from "./header/TracksForm"
import ClearFiltersForm from "./header/ClearFiltersForm"
import User from "../views/wpfestREST/utilities/User"
import HelpForm from "./header/HelpForm"
import { compose } from "recompose"
import { withFestStore } from "../data/store"

class WPFestHeader extends Component 
{
  constructor(props)
  {
    super(props)
    this.state = {
      isHideTitle : false
    }
  }
  headerTitle = ( oRoute ) =>
  {
    // console.log( this.props.store )
    return <Link 
      className={`menu-header-22-title pr-3 m-0 ${this.state.isHideTitle ? " hidden2 " : ""}`} 
      to={`${oRoute}`}
    >
      <div className="head-iconn">
        <img src={this.props.store.fests[ this.getFestID() ].logo || onlineClass} alt="" />
      </div>
      {this.props.store.fests[ this.getFestID() ].title}
    </Link>
  }
  render() 
  {
    //console.log( this.props.honeycombs )
    switch (this.props.header_type) 
    {
      case "members-list":
        return this.membersList()
      case "member":
        return this.member()
      case "track_moderator":
        return this.track_moderator()
      case "requests":
        return this.requests()
      case "about":
      case "reglaments":
      case "statistics":
        return this.about()
      case "reqister-user":
      case "insert-progect":
        return this.registerProgect()
      case "insert-honeycombs":
        return this.registerHoneycoms()
      default:
        return this.props.defArea
    }
  }
  getFestID = () =>
  {
    if( this.props.festID )
    {
      return this.props.festID
    }      
    else
    {
      return -1
    }
  }
  getMemberID = () =>
  {
    if( this.props.match && this.props.match.params )
    {
      return this.props.match.params.memberId
    }      
    else
    {
      return -1
    }
  }
  onOpen = bool =>
  {
    console.log(bool) 
    this.setState({ isHideTitle: bool })
  }
  onGanre = ganresArray =>
  {
    // console.log( ganresArray )
  }
  navi = () =>
  {
    const oRoute  = `${this.props.route}`
    const memRoute = `${this.props.route}/member/${this.getMemberID()}`
    return <>
      <NavLink  exact className="indicator classic" activeClassName=" active " to={`${memRoute}`}>
          <div className="n1">
            {__("Rait")}
          </div>
          <div className="iconnn little">
            <img src={history} alt="" />
          </div>
        </NavLink>
        {
          WPFestSettings.is_diaries
            ?
            <NavLink exact className="indicator classic" activeClassName=" active " to={`${memRoute}/diary`}>
              <div className="n1">
                {__("Project Diary")}
              </div>
              <div className="iconnn little">
                <img src={book} alt="" />
              </div>
            </NavLink>
            :
            null
        }   
        {
          WPFestSettings.fmru_evlbl_roles.filter(e => {
            return e == "Project_author" ||
            e == "Tutor" ||
            e == "Project_member"
          }).length > 0
            ?
            <NavLink exact className="indicator classic" activeClassName=" active " to={`${memRoute}/team`}>
              <div className="n1">
                {__("Team")}
              </div>
              <div className="iconnn little">
                <img src={team} alt="" />
              </div>
            </NavLink>
            :
            null
        }
        {
          this.props.is_owner
            ?
            <>
              <NavLink exact className="indicator classic" activeClassName=" active " to={`${memRoute}/dev`}>
                <div className="n1">
                  {__("Development")}
                </div>
                <div className="iconnn little">
                  <img src={development} alt="" />
                </div>
              </NavLink>
            </>
            :
            null
        }  
    </>
  }

  member() { 
    const oRoute  = `${this.props.route}`
    return (
      <div className="menu-header-22 flex-md-end flex-center p-0">
        { this.headerTitle( oRoute ) }
        <div className="flex-grow-100" />
        <HelpForm 
            youtube={[
            { 
              url:    "zz4xKeg9Cr8",
              title:  "Как изменить настройки своего Проекта?", 
              roles:  [ "Tutor", "Project_author" ]
            }, 
            { 
              url:    "KIRomK5n4_4",
              title:  "Оцениваем партнеров по СОТе", 
              roles:  [ "Tutor", "Project_author" ]
            },
            { 
              url:    "-XkrYfWjOwY",
              title:  "Создаем соту", 
              roles:  [ "Track_moderator", "administrator" ]
            },
            { 
              url:    "YsFouKfaEv4/edit",
              title:  "Номинации", 
              roles:  [ "Track_moderator", "administrator" ]
            },
          ]}  
        />
        <Link className="indicator classic" to={`${oRoute}/members`}>
          <div className="n1">
            {__("To list")}
          </div>
          <div className="iconnn">
            <img src={icon1} alt="" />
          </div>
        </Link>
        <LoginForm
          url={this.props.url}
          route={this.props.route}
          festid={this.getFestID()}
        />
      </div>
    )
  }
  track_moderator() { 
    const oRoute  = `${this.props.route}`
    return (
      <div className="menu-header-22 flex-md-end flex-center p-0">
        { this.headerTitle( oRoute ) }
        <div className="flex-grow-100" /> 
        <HelpForm 
          youtube={[
            { 
              url:    "FYXO9wMBxao",
              title:  "Модерация нового участника", 
              roles:  [ "Track_moderator", "administrator" ]
            }, 
            { 
              url:    "ViEomH5ylwE",
              title:  "Скрыть/показать проект", 
              roles:  [ "Track_moderator", "administrator" ]
            }, 
            { 
              url:    "aAhGzzr8gCY",
              title:  "Передаём проект другому Наставнику", 
              roles:  [ "Track_moderator", "administrator" ]
            }, 
            { 
              url:    "P2ikGDy0Yzo",
              title:  "Закрыть регистрацию на трек", 
              roles:  [ "Track_moderator", "administrator" ]
            }, 
          ]}  
        /> 
        <Link className="indicator classic" to={`${oRoute}/members`}>
          <div className="n1">
            {__("To list")}
          </div>
          <div className="iconnn">
            <img src={icon1} alt="" />
          </div>
        </Link>
        <LoginForm
          url={this.props.url}
          route={this.props.route}
          festid={this.getFestID()}
        />
      </div>
    )
  }

  membersList() {
    const { ganres, is_ganres, festival_title, logo, route, url } = this.props
    const gs = ganres?.map((e) => ({ ...e, check: 1 })) 
    const oRoute  = `${route}` 
    return (
      <div className="row">
        <div className="col-12 menu-header-22 flex-center p-0">
          { this.headerTitle( oRoute ) }
          <div className="mr-auto"/> 
          <div className="d-flex flex-no-wrap"> 
            <HelpForm 
              youtube={[
                { 
                  url:    "zz4xKeg9Cr8",
                  title:  "Как изменить настройки своего Проекта?", 
                  roles:  [ "Tutor", "Project_author" ]
                }, 
                { 
                  url:    "YsFouKfaEv4",
                  title:  "Номинации", 
                  roles:  [ "Track_moderator", "administrator" ]
                }, 
              ]}  
            /> 
          {
            User.isRole([ "Track_moderator", "administrator"])
              ?
              <Link className="indicator classic" to={`${oRoute}/track-moderator`}>
                <div className="n1">
                  {__("Moderation")}
                </div>
                <div className="iconnn">
                  <img src={track_moderator} alt="" />
                </div>
              </Link>
              :
              null
            }
            <TracksForm
              tracks={this.props.tracks}
              url={url}
              route={route}
              festid={this.getFestID()}
            />  
            <HoneycombsForm 
              honeycombs={this.props.honeycombs}
              url={url}
              route={route}
              festid={this.getFestID()}
            /> 
            <GanreForm
              is_ganres={is_ganres}
              ganres={gs}
              onGanre={this.onGanre}
              onOpen={this.onOpen}
            />
            <ClearFiltersForm />
            <LoginForm
              url={url}
              route={route}
              festid={this.getFestID()}
            />
          </div>
          
        </div>
      </div>
    )
  }

  about() {
    
    const oRoute  = `${this.props.route}`
    return ( 
      <div className="row">
        <div className="col-12 menu-header-22 flex-md-end flex-center p-0">
          { this.headerTitle( oRoute ) }
          <div className="mr-auto"/>  
          <Link className="indicator classic" to={`${oRoute}/members`}>
            <div className="n1">
              {__("To list")}
            </div>
            <div className="iconnn">
              <img src={icon1} alt="" />
            </div>
          </Link>
          <LoginForm
            url={this.props.url}
            route={this.props.route}
            festid={this.getFestID()}
          />
        </div>
      </div>
    )
  }

  requests() {
    const oRoute  = `${this.props.route}`
    return (
      <div className="row">
        <div className="col-12 menu-header-22 flex-end p-0">
          <div className="menu-header-22-title">
            {__("Requests for add roles")}
          </div>
          {
            User.isRole([ "Track_moderator", "administrator"])
              ?
              <Link className="indicator classic" to={`${oRoute}/track-moderator`}>
                <div className="n1">
                  {__("Moderation")}
                </div>
                <div className="iconnn">
                  <img src={track_moderator} alt="" />
                </div>
              </Link>
              :
              null
          }  
          <Link className="indicator classic" to={`${oRoute}/members`}>
            <div className="n1">
              {__("To list")}
            </div>
            <div className="iconnn">
              <img src={icon1} alt="" />
            </div>
          </Link>
          <LoginForm
            url={this.props.url}
            route={this.props.route}
            festid={this.getFestID()}
          />
        </div>
      </div>
    )
  }

  registerProgect() {
    const oRoute  = `${this.props.route}`
    return (
      <div className="row">
        <div className="col-12 menu-header-22 flex-end p-0">
          { this.headerTitle( oRoute ) }
          <div className="mr-auto"/>
          <HelpForm 
            youtube={[
              { 
                url:    "qU7SssAGHW8",
                title:  "", 
                roles:  []
              }
            ]} 
          />
          <Link className="indicator classic" to={`${oRoute}/members`}>
            <div className="n1">
              {__("To list")}
            </div>
            <div className="iconnn">
              <img src={icon1} alt="" />
            </div>
          </Link>
          <LoginForm
            url={this.props.url}
            route={this.props.route}
            festid={this.getFestID()}
          />
        </div>
      </div>
    )
  }

  registerHoneycoms() {
    const oRoute  = `${this.props.route}`
    return (
      <div className="row">
        <div className="col-12 menu-header-22 flex-end p-0">
          { this.headerTitle( oRoute ) }
          <div className="mr-auto"/> 
          <Link className="indicator classic" to={`${oRoute}/members`}>
            <div className="n1">
              {__("To list")}
            </div>
            <div className="iconnn">
              <img src={icon1} alt="" />
            </div>
          </Link>
          <LoginForm
            url={this.props.url}
            route={this.props.route}
            festid={this.getFestID()}
          />
        </div>
      </div>
    )
  }
}
export default compose(
  withFestStore,
)(WPFestHeader)
